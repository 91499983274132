import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import "../../assets/css/BatchCreationModal.css";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const BatchCreationModal = ({
  isOpen,
  toggle,
  stores,
  products,
  supplies,
  fetchSupplies,
  fetchBatches,
  refreshProducts, // Passed as a prop
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [ingredientData, setIngredientData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const prevIsOpenRef = useRef(false);

  const [newBatch, setNewBatch] = useState({
    store_id: "",
    products: [],
    batch_code: "",
    production_date: "",
    expiry_date: "",
    quantity_produced: "",
    notes: "",
    supplies: [],
  });

  const generateBatchCode = () => {
    // Generate a unique batch code prefixed with "BATCH-"
    // The random part uses numbers and letters to ensure uniqueness
    const uniqueSuffix = Math.random().toString(36).substr(2, 9).toUpperCase();
    const newCode = `BATCH-${uniqueSuffix}`;
    setNewBatch((prev) => ({ ...prev, batch_code: newCode }));
  };

  useEffect(() => {
    if (isOpen && !prevIsOpenRef.current) {
      refreshProducts();
    }
    prevIsOpenRef.current = isOpen;
  }, [isOpen, refreshProducts]);

  const getCsrfToken = () => {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const toggleModal = () => {
    if (!isOpen) {
      fetchSupplies(); // Fetch supplies when opening the modal
    }
    toggle(); // Toggle the modal visibility
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewBatch((prev) => ({ ...prev, [name]: value }));
  };

  const handleStoreChange = (event) => {
    setNewBatch({ ...newBatch, store_id: event.target.value });
  };

  const handleProductChange = (selectedOptions) => {
    const productsWithQuantities = selectedOptions.map((option) => ({
      product_id: option.value,
      name: option.label,
      quantity: "", // Initialize quantity as empty
    }));
    setNewBatch({ ...newBatch, products: productsWithQuantities });
  };

  const fetchIngredientRequirements = async () => {
    try {
      const csrfToken = getCsrfToken(); // Retrieve the CSRF token using the provided function
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-ingredients/store/${newBatch.store_id}/`,
        { products: newBatch.products },
        {
          headers: {
            "X-CSRFToken": csrfToken, // Set the CSRF token in the request header
            "Content-Type": "application/json",
          },
          withCredentials: true, // Ensure cookies are sent with the request
        }
      );

      // Initialize remaining_required directly with required_quantity
      const ingredientsWithRemaining = response.data.ingredients.map(
        (ingredient) => ({
          ...ingredient,
          remaining_required: ingredient.required_quantity, // Set remaining_required to start at required_quantity
          available_inventories: ingredient.available_inventories.map(
            (inventory) => ({
              ...inventory,
              selectedQuantity: 0, // Initialize selectedQuantity as 0
            })
          ),
        })
      );

      setIngredientData(ingredientsWithRemaining);
    } catch (error) {
      console.error("Failed to fetch ingredient requirements:", error);
    }
  };

  const nextStep = async () => {
    if (currentStep === 1) {
      const csrfToken = getCsrfToken();
      const productQuantities = newBatch.products.map((product) => ({
        product_id: product.product_id,
        quantity: parseFloat(product.quantity),
      }));

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/pre-check/`,
          {
            store_id: newBatch.store_id,
            product_quantities: productQuantities,
          },
          {
            headers: {
              "X-CSRFToken": csrfToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          // Inventory check passed, fetch ingredient requirements and move to the next step
          await fetchIngredientRequirements();
          setCurrentStep(currentStep + 1);
        } else {
          // Inventory check failed, display the error message
          const errorMessage = response.data.error || "Inventory check failed.";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error during inventory pre-check:", error);
        if (error.response && error.response.status === 409) {
          // Inventory issues detected, display the alert modal
          const inventoryIssues = error.response.data.inventory_issues;
          let alertMessage = "Inventory issues detected:\n\n";
          inventoryIssues.forEach((issue) => {
            alertMessage += `Ingredient: ${issue.ingredient_name}\n`;
            alertMessage += `Required Packages: ${issue.required_packages}\n`;
            alertMessage += `Available Packages: ${issue.available_packages}\n`;
            alertMessage += `Shortfall Packages: ${issue.shortfall_packages}\n\n`;
          });
          alertMessage +=
            "You need to add the required ingredients to the store inventory before proceeding with batch creation.";
          alert(alertMessage);
          // Do not proceed to the next step until the inventory issues are resolved
          return;
        } else {
          const errorMessage =
            error.response?.data?.error || "An unexpected error occurred.";
          toast.error(errorMessage);
        }
      }
    } else if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleProductQuantityChange = (index, quantity) => {
    const updatedProducts = newBatch.products.map((product, idx) =>
      index === idx ? { ...product, quantity: quantity } : product
    );
    setNewBatch({ ...newBatch, products: updatedProducts });
  };

  const handleSuppliesChange = (selectedOptions) => {
    setNewBatch({
      ...newBatch,
      supplies: selectedOptions.map((option) => ({
        supply_id: option.value,
        name: option.label,
        quantity: "", // Initialize quantity as empty
      })),
    });
  };

  const handleSupplyQuantityChange = (supplyId, quantity) => {
    const updatedSupplies = newBatch.supplies.map((supply) =>
      supply.supply_id === supplyId ? { ...supply, quantity: quantity } : supply
    );
    setNewBatch({ ...newBatch, supplies: updatedSupplies });
  };

  const renderProductsWithQuantities = () => {
    return newBatch.products.map((product, index) => (
      <FormGroup key={index}>
        <Label>{`Quantity for ${product.name}`}</Label>
        <Input
          type="number"
          value={product.quantity}
          onChange={(e) => handleProductQuantityChange(index, e.target.value)}
        />
      </FormGroup>
    ));
  };

  const renderSuppliesWithQuantities = () => {
    return newBatch.supplies.map((supply, index) => (
      <FormGroup key={index}>
        <Label>{`${supply.name} Quantity`}</Label>
        <Input
          type="number"
          value={supply.quantity}
          onChange={(e) =>
            handleSupplyQuantityChange(supply.supply_id, e.target.value)
          }
        />
      </FormGroup>
    ));
  };

  const handleInventorySelection = (
    ingredientId,
    inventoryId,
    selectedQuantity
  ) => {
    const newIngredientData = ingredientData.map((ingredient) => {
      if (ingredient.ingredient_id === ingredientId) {
        const newInventories = ingredient.available_inventories.map(
          (inventory) => {
            if (inventory.inventory_id === inventoryId) {
              return {
                ...inventory,
                selectedQuantity: parseFloat(selectedQuantity) || 0,
              };
            }
            return inventory;
          }
        );

        const totalSelected = newInventories.reduce(
          (total, inv) => total + (inv.selectedQuantity || 0),
          0
        );
        const remainingRequired = Math.max(
          0,
          ingredient.required_quantity - totalSelected
        );

        return {
          ...ingredient,
          available_inventories: newInventories,
          remaining_required: remainingRequired,
        };
      }
      return ingredient;
    });

    setIngredientData(newIngredientData);
  };

  const validateAndCreateBatch = async () => {
    const productQuantities = newBatch.products.map((product) => ({
      product_id: product.product_id,
      quantity: product.quantity,
    }));

    const csrfToken = getCsrfToken(); // Assuming getCsrfToken is defined in your component or imported

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/pre-check/`,
        {
          store_id: newBatch.store_id,
          product_quantities: productQuantities,
        },
        {
          headers: {
            "X-CSRFToken": csrfToken,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (
        response.status === 200 &&
        response.data.message === "No inventory issues detected."
      ) {
        console.log("No inventory issues, proceeding to create batch.");
        createBatch(); // Function to actually create the batch
      } else {
        console.error(
          "Inventory issues detected:",
          response.data.inventory_issues
        );
        // Here you could update state to show these issues in the UI
      }
    } catch (error) {
      console.error("Failed to validate inventory:", error);
    }
  };

  const createBatch = async () => {
    const csrfToken = getCsrfToken();
    const url = `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/create/`;

    const batchIngredientInventories = ingredientData.flatMap((ingredient) =>
      ingredient.available_inventories
        .filter((inv) => inv.selectedQuantity > 0)
        .map((inv) => ({
          ingredient_inventory_id: parseInt(inv.inventory_id, 10),
          quantity_used: parseFloat(inv.selectedQuantity),
        }))
    );

    const payload = {
      store_id: parseInt(newBatch.store_id, 10),
      product_quantities: newBatch.products.map((product) => ({
        product_id: parseInt(product.product_id, 10),
        quantity: parseFloat(product.quantity),
      })),
      batch_supplies: newBatch.supplies.map((supply) => ({
        supply_id: supply.supply_id,
        quantity: supply.quantity,
      })),
      ingredient_inventories: batchIngredientInventories,
      batch_code: newBatch.batch_code,
      production_date: newBatch.production_date,
      expiry_date: newBatch.expiry_date,
      notes: newBatch.notes,
    };

    console.log("Sending payload:", payload);

    try {
      const response = await axios.post(url, payload, {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (response.status === 201) {
        console.log("Batch created successfully:", response.data);
        toast.success("Batch created successfully!");
        fetchBatches(); // Refetch batches on successful creation
        toggleModal(); // Close the modal on successful creation
      } else {
        console.error("Failed to create batch:", response.data);
        const errorMessage =
          response.data.message ||
          "Failed to create batch due to an unknown error.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error creating batch:", error);

      let errorMessage = "An unexpected error occurred.";

      if (error.response && error.response.data.error) {
        const errorDetails = error.response.data.error;
        const ingredientRegex =
          /ingredient (.+)\. Required: (\d+\.\d+), Available: (\d+\.\d+)/;
        const match = errorDetails.match(ingredientRegex);

        if (match) {
          const [, ingredientName, requiredQuantity, availableQuantity] = match;
          const formattedRequired = parseFloat(requiredQuantity).toFixed(2);
          const formattedAvailable = parseFloat(availableQuantity).toFixed(2);
          errorMessage = `Not enough inventory for ingredient ${ingredientName}. Required: ${formattedRequired}, Available: ${formattedAvailable}`;
        } else {
          errorMessage = errorDetails;
        }
      } else if (error.response) {
        errorMessage =
          error.response.data.message || "An unexpected error occurred.";
      } else {
        errorMessage = "Network error or server is unreachable.";
      }

      toast.error(errorMessage);
    }
  };

  const renderStep2 = () => {
    console.log("Rendering Step 2 with ingredient data:", ingredientData);

    // Check if ingredient data is available and if any ingredient has available inventory
    const hasAvailableIngredients = ingredientData.some((ingredient) =>
      ingredient.available_inventories.some(
        (inventory) => inventory.quantity_available > 0
      )
    );

    if (!hasAvailableIngredients) {
      // Display message if no ingredients are available
      return (
        <div>
          <div className="no-ingredients-available">
            No ingredient stock available in the store. Please check the
            inventory or select a different store.
          </div>
          <Button color="secondary" onClick={prevStep}>
            Back
          </Button>
        </div>
      );
    }

    // Product names. Assumes that each product has a 'name' property.
    const productNames = newBatch.products.map((p) => p.name).join(", ");

    return (
      <>
        <h2>
          Step 2: Enter Ingredient Quantities
          <span id="infoIcon" style={{ marginLeft: "5px", cursor: "pointer" }}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="infoIcon"
            toggle={toggleTooltip}
          >
            "Ingredient quantities required for the batch are initially
            calculated based on your recipe requirements. These quantities are
            then converted into the equivalent number of inventory packets. For
            example, if your recipe needs cups of flour, we calculate how many
            packets (such as bags of 100 lbs each) this corresponds to, and
            deduct this amount from your inventory accordingly. To optimize
            shelf-life and minimize waste, we recommend selecting inventory from
            a single lot, following the First Expire First Out (FEFO) approach.
            This method prioritizes the use of ingredients that will expire
            soonest, helping to ensure product quality and reduce losses."
          </Tooltip>
        </h2>
        <h3>{`Products: ${productNames}`}</h3>{" "}
        {/* Displaying the product names */}
        {ingredientData.map((ingredient, index) => (
          <div key={index} className="ingredient-section">
            <div className="ingredient-header">
              {ingredient.ingredient_name}: Needed{" "}
              {ingredient.required_quantity} {ingredient.unit},
              <span className="remaining-quantity">
                {" "}
                Remaining ingredient amount for the batch:{" "}
                {(ingredient.remaining_required || 0).toFixed(2)}
              </span>
            </div>
            {ingredient.available_inventories.map((inventory) => (
              <div key={inventory.inventory_id} className="inventory-selection">
                <label>
                  Use up to{" "}
                  {parseFloat(inventory.quantity_available || 0).toFixed(2)}{" "}
                  packs of {inventory.weight} {inventory.weight_units} available
                  (Serial: {inventory.serial_number}, Exp:{" "}
                  {inventory.expiration_date || "N/A"}):
                </label>
                <input
                  className="inventory-input" // Apply the class here
                  type="number"
                  min="0"
                  max={inventory.quantity_available}
                  value={inventory.selectedQuantity || 0}
                  onChange={(e) =>
                    handleInventorySelection(
                      ingredient.ingredient_id,
                      inventory.inventory_id,
                      e.target.value
                    )
                  }
                />
              </div>
            ))}
          </div>
        ))}
        <Button color="secondary" onClick={prevStep}>
          Back
        </Button>
        <Button color="primary" onClick={validateAndCreateBatch}>
          Create Batch
        </Button>
      </>
    );
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <FormGroup>
              <Label for="storeSelect">Store</Label>
              <Input
                type="select"
                name="store_id"
                id="storeSelect"
                value={newBatch.store_id}
                onChange={handleStoreChange}
              >
                <option value="">Select a store</option>
                {stores.map((store) => (
                  <option key={store.value} value={store.value}>
                    {store.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="productSelect">Products</Label>
              <Select
                isMulti
                name="products"
                id="productSelect"
                value={newBatch.products.map((product) => ({
                  value: product.product_id,
                  label: product.name,
                }))}
                onChange={handleProductChange}
                options={products.map((product) => ({
                  value: product.id,
                  label: product.name,
                }))}
              />
            </FormGroup>
            {renderProductsWithQuantities()}
            <FormGroup>
              <Label for="supplySelect">Supplies</Label>
              <Select
                isMulti
                name="supplies"
                id="supplySelect"
                value={newBatch.supplies.map((supply) => ({
                  value: supply.supply_id,
                  label: supply.name,
                }))}
                onChange={handleSuppliesChange}
                options={supplies}
              />
            </FormGroup>
            {renderSuppliesWithQuantities()}
            <FormGroup>
              <Label for="batchCode">Batch Code (UPC)</Label>
              <Input
                type="text"
                name="batch_code"
                id="batchCode"
                value={newBatch.batch_code}
                onChange={handleInputChange}
                placeholder="Enter or generate UPC code"
              />
            </FormGroup>
            <FormGroup>
              <Button
                color="primary"
                size="sm"
                style={{ width: "70px", marginTop: "5px" }}
                onClick={generateBatchCode}
              >
                Generate
              </Button>
            </FormGroup>

            <FormGroup>
              <Label for="productionDate">Production Date</Label>
              <Input
                type="date"
                name="production_date"
                id="productionDate"
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="expiryDate">Expiry Date</Label>
              <Input
                type="date"
                name="expiry_date"
                id="expiryDate"
                onChange={handleInputChange}
              />
            </FormGroup>
            <Button color="primary" onClick={nextStep}>
              Next
            </Button>
          </>
        );
      case 2:
        return renderStep2();

        return (
          <>
            {/* Final confirmation and submission logic */}
            <Button color="secondary" onClick={prevStep}>
              Back
            </Button>
            <Button
              color="primary"
              onClick={() => console.log("Submitting", newBatch)}
            >
              Submit
            </Button>
          </>
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Create New Batch</ModalHeader>
      <ModalBody>{renderStepContent(currentStep)}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BatchCreationModal;
