import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  Alert,
  Table,
  Label,
  FormGroup, // Import FormGroup
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import axios from "axios";
import "../../assets/css/prodcut_inventroy.css";
import "../../assets/css/batch_list.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidFaStar } from "@fortawesome/free-solid-svg-icons"; // Solid star
import { faStar as regularFaStar } from "@fortawesome/free-regular-svg-icons"; // Regular star
import { faPlus, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import BatchDetailModal from "./BatchDetailModal";

const FavoriteBatch = () => {
  const [batches, setBatches] = useState([]);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [stores, setStores] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [batchDetail, setBatchDetail] = useState({ products: [] });
  const [products, setProducts] = useState([]);
  const [editableProducts, setEditableProducts] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [selectedSupplies, setSelectedSupplies] = useState([]);
  const [selectedStoreForNewBatch, setSelectedStoreForNewBatch] =
    useState(null);

  // Batch Detial modal

  const handleDetailModalSave = () => {
    setIsDetailModalOpen(false);
    fetchBatches();
  };

  // Initialize newBatch.product as an empty array
  const [newBatch, setNewBatch] = useState({
    store_id: "",
    products: [], // Should be an array of objects with `product_id` and `quantity`
    // ... other fields
  });

  useEffect(() => {
    fetchStores();
    fetchBatches();
  }, []);

  // Fetching Prodcut List
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("/core/api/product-list/");
        console.log("products:", response.data);
        setProducts(response.data.results);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Fetching Supplies List
  useEffect(() => {
    const fetchSupplies = async () => {
      try {
        const response = await axios.get("/core/api/supply-list/");
        setSupplies(
          response.data.map((supply) => ({
            label: supply.name,
            value: supply.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching supplies:", error);
      }
    };

    fetchSupplies();
  }, []);

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    if (!isEditMode) {
      // If we're entering edit mode
      initializeEditableProducts();
    }
  };

  const fetchStores = async () => {
    try {
      const csrfToken = getCsrfToken(); // Ensure getCsrfToken function is defined
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
      const storesData = response.data.map((store) => ({
        label: store.name,
        value: store.id,
      }));
      setStores(storesData);
      console.log("Stores fetched:", storesData); // This should log the transformed stores array
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  const fetchBatches = async () => {
    try {
      const csrfToken = getCsrfToken(); // Ensure getCsrfToken function is defined
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batches/favorites/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
      console.log("Favorite batches:", response.data);
      // Adjust here to target the results array within the response
      setBatches(response.data.results);
    } catch (error) {
      console.error("Error fetching favorite batches:", error);
    }
  };

  const handleDetails = async (batchId) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch-detail/${batchId}/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      const enrichedProductQuantities = response.data.product_quantities.map(
        (pq) => ({
          ...pq,
          ...products.find((p) => p.id === pq.product_id),
        })
      );

      console.log("Batch details fetched:", response.data);

      setBatchDetail({
        ...response.data,
        product_quantities: enrichedProductQuantities,
      });

      setIsDetailModalOpen(true);
    } catch (error) {
      console.error("Error fetching batch details:", error);
    }
  };

  const toggleDetailModal = () => {
    console.log(
      "Toggling detail modal from",
      isDetailModalOpen,
      "to",
      !isDetailModalOpen
    );

    setIsDetailModalOpen(!isDetailModalOpen);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    const filtered = batches.filter(
      (batch) =>
        batch.batch_code.toLowerCase().includes(e.target.value.toLowerCase())
      // Add other conditions for filtering if needed
    );
    setFilteredBatches(filtered);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBatch({ ...newBatch, [name]: value });
  };

  const handleSubmit = async () => {
    const csrfToken = getCsrfToken();

    // Prepare payload including supplies
    let payload = {
      store_id: newBatch.store_id,
      products: newBatch.products.map((product) => ({
        product_id: product.product_id,
        quantity: Number(product.quantity),
      })),
      batch_code: newBatch.batch_code,
      production_date: newBatch.production_date,
      expiry_date: newBatch.expiry_date,
      quantity_produced: Number(newBatch.quantity_produced),
      supplies: selectedSupplies.map((supply) => ({
        supply_id: supply.value, // Ensure this matches your supply ID
        quantity_used: Number(supply.quantity), // Ensure this matches the expected field name and format in your backend
      })),
    };

    console.log("Payload handelSubmit:", payload);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/create/`,
        payload,
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken },
        }
      );

      toast.success("Batch created successfully!");
      toggleModal();
      fetchBatches();
    } catch (error) {
      console.error("Error creating batch:", error);
      toast.error("Error creating batch!");
    }
  };

  const saveEditedDetails = async () => {
    try {
      // Save the changes using an API call
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch-detail/${batchDetail.id}`,
        batchDetail,
        {
          withCredentials: true,
          headers: { "X-CSRFToken": getCsrfToken() },
        }
      );
      toast.success("Batch details updated successfully!");
      // Handle the response, refresh data if needed
      toggleEditMode(); // Exit edit mode after saving
    } catch (error) {
      console.error("Error updating batch details:", error);
      toast.error("Error updating batch details!");
      // Handle error appropriately
    }
  };

  const handleCreateSubmit = async () => {
    const csrfToken = getCsrfToken();
    let payload = {
      ...newBatch,
      product_quantities: newBatch.products.map(({ product_id, quantity }) => ({
        product_id,
        quantity: Number(quantity), // Ensure quantity is a number
      })),
      // Add the supplies information to the payload
      batch_supplies: selectedSupplies.map((supply) => ({
        supply_id: supply.value,
        quantity_used: Number(supply.quantity), // Convert to number to ensure correct data type
      })),
    };

    console.log("Payload handleCreateSubmit with supplies:", payload); // Debugging: Check the complete payload
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/create/`,
        payload,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      toast.success("Batch created successfully!");
      toggleCreateModal();
      fetchBatches();
    } catch (error) {
      console.error("Error creating batch with supplies:", error);
      toast.error("Error creating batch!");
    }
  };

  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
    console.log("Create Batch Modal Toggled"); // Debugging
  };

  // Updated handleEditSubmit function to reflect changes in product selection
  const handleEditSubmit = async () => {
    try {
      const csrfToken = getCsrfToken();

      // Construct the payload, now ensuring store_id is included and correctly handled
      const payload = {
        ...batchDetail, // Assumes batchDetail includes all necessary batch information, including store_id
        product_quantities: editableProducts
          .map((product) => ({
            id: product.id, // Include only for existing quantities to update
            product_id: product.value, // Adjusted to match your backend's expectations
            quantity: Number(product.quantity), // Ensure quantity is correctly formatted as a Number
          }))
          .filter((product) => product.quantity > 0), // Optional: Remove any product quantities with 0 value
      };

      const updateUrl = `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/update/${batchDetail.id}/`;

      console.log("Submitting payload:", payload); // Debugging

      const response = await axios.put(updateUrl, payload, {
        headers: {
          "X-CSRFToken": csrfToken,
        },
        withCredentials: true,
      });

      console.log("Update response:", response.data); // Debugging

      toast.success("Batch updated successfully!");
      fetchBatches(); // Refresh batches to reflect changes
      toggleEditMode(); // Assuming this toggles the state back to viewing mode
      toggleDetailModal(); // Closes the detail/edit modal
    } catch (error) {
      console.error("Error updating batch details:", error);
      toast.error("Error updating batch details!");
    }
  };

  // Handle change for product select, including quantities
  const handleProductChange = (selectedOptions) => {
    // Debug: Log the selected options
    console.log("Selected products:", selectedOptions);

    const updatedProducts = selectedOptions.map((option) => {
      // Debug: Log each selected product's details
      console.log(
        "Product Selected - Name:",
        option.label,
        "ID:",
        option.value
      );

      // Find if the product is already in the new batch
      const existingProduct = newBatch.products.find(
        (p) => p.product_id === option.value
      );

      // If the product exists, keep it as is, otherwise add it with default quantity
      return existingProduct
        ? { ...existingProduct }
        : {
            product_id: option.value,
            name: option.label,
            quantity: 1, // Assign a default quantity for new selections
          };
    });

    // Update the new batch state with the updated products
    setNewBatch({ ...newBatch, products: updatedProducts });
  };

  // Handle quantity changes for each product
  const handleQuantityChange = (productId, newQuantity) => {
    if (isEditMode) {
      // Edit mode: Update editableProducts
      const updatedProducts = editableProducts.map((product) =>
        product.value === productId
          ? { ...product, quantity: newQuantity }
          : product
      );
      setEditableProducts(updatedProducts);
    } else {
      // Create mode: Update newBatch.products
      const updatedProducts = newBatch.products.map((product) =>
        product.product_id === productId
          ? { ...product, quantity: newQuantity }
          : product
      );
      setNewBatch({ ...newBatch, products: updatedProducts });
    }
  };

  const renderBatchRows = () => {
    const filteredBatches = batches.filter(batch =>
      batch.batch_code.toLowerCase().includes(searchText.toLowerCase())
    );
  
    if (filteredBatches.length === 0) {
      return (
        <tr>
          <td colSpan="6" className="text-center">
            No Batch Favorited
          </td>
        </tr>
      );
    }
  
    return filteredBatches.map((batch) => (
      <tr key={batch.id}>
        <td>{batch.batch_code}</td>
        <td>{formatDate(batch.date_created)}</td>
        <td>{formatDate(batch.expiry_date)}</td>
        <td>{batch.store.name}</td>
        <td>{displayIsRecalled(batch.is_recalled)}</td>
        <td>
          <Button
            color="primary"
            size="sm"
            title="Details"
            onClick={() => handleDetails(batch.id)}
          >
            <FontAwesomeIcon icon={faCircleInfo} />
          </Button>{" "}
          <Button
            className={`fav-icon-button ${
              batch.is_favorite ? "favorite" : ""
            }`}
            onClick={() => markAsFavorite(batch.id)}
          >
            <FontAwesomeIcon
              icon={batch.is_favorite ? solidFaStar : regularFaStar}
              style={{ color: batch.is_favorite ? "gold" : "#ccc" }}
            />
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => handleDeleteBatch(batch.id)}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </td>
      </tr>
    ));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const displayIsRecalled = (isRecalled) => {
    if (isRecalled) {
      return <span style={{ fontWeight: "bold", color: "red" }}>Yes</span>;
    } else {
      return "No";
    }
  };

  // Delete Batch
  // Delete Batch
  const handleDeleteBatch = async (batchId) => {
    // Show confirmation dialog
    if (window.confirm("Are you sure you want to delete this batch?")) {
      const csrfToken = getCsrfToken(); // Reuse your existing getCsrfToken function

      try {
        await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/delete/${batchId}/`,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": getCsrfToken(),
            },
          }
        );

        toast.warn("Batch deleted successfully!");
        // Refresh the list of batches after successful deletion
        fetchBatches();
      } catch (error) {
        console.error("Error deleting batch:", error);
        toast.error("Error deleting batch!");
      }
    } else {
      // User cancelled the deletion
      console.log("Batch deletion cancelled.");
    }
  };

  // When entering edit mode, initialize editableProducts
  const initializeEditableProducts = () => {
    // Create a new array from batchDetail.product_quantities, including product details and quantities
    const productSelection =
      batchDetail.product_quantities?.map((pq) => {
        const productDetails = products.find((p) => p.id === pq.product_id);
        return {
          value: pq.product_id,
          label: productDetails ? productDetails.name : "Product not found",
          quantity: pq.quantity,
        };
      }) || [];

    setEditableProducts(productSelection);
  };

  // This function will be called when a new set of products is selected
  const handleProductSelection = (selectedOptions) => {
    // For each selected option, check if it already exists in editableProducts
    const updatedProducts = selectedOptions.map((option) => {
      const existingProduct = editableProducts.find(
        (p) => p.value === option.value
      );
      // If it exists, use the existing product, otherwise create a new one with a default quantity
      return (
        existingProduct || {
          value: option.value,
          label: option.label,
          quantity: 1, // Set a default quantity for new product selections
        }
      );
    });

    setEditableProducts(updatedProducts);
  };

  // Handle supply chabges
  const handleSuppliesChange = (selectedOptions) => {
    setSelectedSupplies(
      selectedOptions.map((option) => ({
        ...option,
        quantity: 1, // Default quantity
      }))
    );
  };

  const handleSupplyQuantityChange = (supplyId, newQuantity) => {
    setSelectedSupplies((currentSupplies) =>
      currentSupplies.map((supply) =>
        supply.value === supplyId
          ? { ...supply, quantity: newQuantity }
          : supply
      )
    );
  };

  const markAsFavorite = async (batchId) => {
    const csrfToken = getCsrfToken(); // Retrieve CSRF token using the existing function

    // Find the batch to update
    const batchToUpdate = batches.find((batch) => batch.id === batchId);
    if (!batchToUpdate) {
      console.error("Batch not found");
      toast.error("Batch not found.");
      return;
    }

    // Toggle the favorite status for the optimistic UI update
    const updatedFavoriteStatus = !batchToUpdate.is_favorite;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batches/${batchId}/mark_favorite/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify({ is_favorite: updatedFavoriteStatus }),
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Batch favorite status updated:", data);

        // Update the batches state to reflect the change
        setBatches(
          batches.map((batch) =>
            batch.id === batchId
              ? { ...batch, is_favorite: updatedFavoriteStatus }
              : batch
          )
        );

        toast.success(
          `Batch ${
            updatedFavoriteStatus
              ? "marked as favorite"
              : "unmarked as favorite"
          }.`
        );
        fetchBatches();
      } else {
        console.error("Failed to update batch favorite status");
        toast.error("Failed to update batch favorite status.");
      }
    } catch (error) {
      console.error("Error updating batch favorite status:", error);
      toast.error("Error updating batch favorite status.");
    }
  };

  return (
    <>
      {/* Success Message */}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* Flex Container for Search Input and Button */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                {/* Search Input */}
                <FormGroup
                  className="mb-0"
                  style={{
                    flexGrow: 1,
                    maxWidth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Input
                    type="text"
                    placeholder="Search Batches"
                    value={searchText}
                    onChange={handleSearchChange}
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  />
                </FormGroup>
              </div>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Batch Code</th>
                    <th scope="col">Date Created</th>
                    <th scope="col">Expiry Date</th>
                    <th scope="col">Store</th>
                    <th scope="col">Is Recalled</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {renderBatchRows()}{" "}
                  {/* This function will render all batch rows with the correct buttons */}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>

      <BatchDetailModal
        isOpen={isDetailModalOpen}
        toggle={toggleDetailModal}
        batchDetail={batchDetail}
        onSave={handleDetailModalSave}
      />
    </>
  );
};

export default FavoriteBatch;
