import React from 'react';
import '../../assets/css/NutritionLabel.css';

// Define main nutrients for special styling and their sub-nutrients
const nutrientHierarchy = {
  totalFat: ['saturatedFat', 'transFat'],
  cholesterol: [],
  sodium: [],
  totalCarbohydrates: ['dietaryFiber', 'totalSugars', 'addedSugars'],
  protein: [],
};

// Define the rest of the nutrients that will be displayed after main nutrients
const otherNutrients = ['vitaminD', 'calcium', 'iron', 'potassium'];

const NutritionLabel = ({ data }) => {
  console.log('Debug fdaSuggesteServingSize:', data.suggestedServingSize, data.fda_approved_serving_size);

  // Helper function to capitalize words for display
  const capitalizeWords = (str) =>
    str.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

  // Helper function to render each nutrient row
  const renderNutrient = (name, value, unit, dailyValue, isMainNutrient, isIndented, isDoubleIndented) => {
    let rowClass = isMainNutrient ? 'main-nutrient-row' : '';
    let nutrientClass = isMainNutrient ? 'nutrient-main-nutrient' : 'nutrient-sub';
    let indentClass = isIndented ? 'sub-nutrient' : isDoubleIndented ? 'sub-nutrient-indented' : '';

    return (
      <div key={name} className={`new-vertical-row ${rowClass} ${indentClass}`}>
        <div className={`pull-left ${nutrientClass}`}>
          <span>{name}</span>
          <span className="nutrientcontent">
            {value}
            {unit || 'g'}
          </span>{' '}
          {/* Default unit to 'g' */}
        </div>
        <div className="pull-right">
          <div className="nutrient">{dailyValue}%</div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  };

  console.log('Data provided to NutritionLabel:', data);

  return (
    <div>
      <div id="newnutritionfactstable">
        <div id="newnutritionheading">Nutrition Facts</div>
        <div className="hairlineseparator"></div>
        <div className="servingsize servingsizenew">
          <span className="servings-per-container-div">
            <span className="servings-per-container">{data.servingsPerContainer}</span>
            servings per container
          </span>
          <div className="servingsizenew-bold">
            <div style={{ float: 'left' }}>Serving size</div>
            <div style={{ float: 'right' }}>
              {data.suggestedServingSize} {data.servingSize}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <div className="thickseparator"></div>
        <div className="nutrient">Amount Per Serving</div>
        <div className="hairlineseparator"></div>
        <div className="nutrient-new-calories">
          Calories <span className="calories">{data.calories.amount}</span>
        </div>
        <div className="calories_separator"></div>
        <div className="newdailyvalue">
          % Daily Value<span className="dailyvalue-asterisk">*</span>
        </div>
        <div className="clearfix"></div>

        {/* Render main nutrients and their sub-nutrients */}
        {Object.entries(nutrientHierarchy).map(([mainNutrient, subNutrientsList]) => {
          const mainNutrientData = data[mainNutrient];
          return (
            <React.Fragment key={mainNutrient}>
              {mainNutrientData &&
                renderNutrient(
                  capitalizeWords(mainNutrient.replace(/([A-Z])/g, ' $1').trim()),
                  mainNutrientData.amount,
                  mainNutrientData.unit,
                  mainNutrientData.percentDV,
                  true
                )}

              {/* Render sub-nutrients with an additional indent */}
              {subNutrientsList.map((subNutrient) => {
                const subNutrientData = data[subNutrient];
                return (
                  subNutrientData &&
                  renderNutrient(
                    ' ' + capitalizeWords(subNutrient.replace(/([A-Z])/g, ' $1').trim()), // Add space for indent
                    subNutrientData.amount,
                    subNutrientData.unit,
                    subNutrientData.percentDV,
                    false,
                    true, // isIndented for single tab indent
                    subNutrient === 'addedSugars' // isDoubleIndented for Added Sugars
                  )
                );
              })}

              {/* Insert a separator after the protein nutrient */}
              {mainNutrient === 'protein' && <div key="protein-separator" className="thickseparator"></div>}
            </React.Fragment>
          );
        })}

        {/* Render other nutrients */}
        {otherNutrients.map((nutrient) => {
          const nutrientData = data[nutrient];
          return (
            nutrientData &&
            renderNutrient(
              capitalizeWords(nutrient.replace(/([A-Z])/g, ' $1').trim()),
              nutrientData.amount,
              nutrientData.unit,
              nutrientData.percentDV,
              false
            )
          );
        })}

        <div className="asterisksection-new-vertical">
          <div className="bottom-separator"></div>
          <div className="asterisk">*</div>
          <div className="asterisk_text" style={{ width: '222px' }}>
            The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet.
            2,000 calories a day is used for general nutrition advice.
          </div>
          <div className="clearfix"></div>
        </div>
      </div>

      {/* Not a significant source of section */}
      <div id="new-vertical-insignificant-nutrients-list">
        <div className="out-separator"></div>
        Not a significant source of trans fat, dietary fiber, vitamin D, potassium, or calcium.
      </div>

      {/* Ingredients section */}
      {data.ingredientList && (
        <div className="ingredients-section">
          <div className="ingredients-heading">Ingredients:</div>
          <div className="ingredients-list">
            {data.ingredientList && data.ingredientList.map((ingredient, index) => (
              <div key={index}>{ingredient}</div>
            ))}
          </div>
        </div>
      )}

      {/* Allergens section */}
      {data.allergens && (
        <div className="allergens-section">
          <div className="allergens-heading">Contains:</div>
          <div className="allergens-list">{data.allergens}</div>
        </div>
      )}
    </div>
  );
};

export default NutritionLabel;
