import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios"; // Import Axios for making HTTP requests
import { toast } from "react-toastify";
import classnames from "classnames";
import ProductInventoryDetailModal from "./ProductInventoryDetailModal";
import IngredientInventoryDetailModal from "./IngredientInventoryDetailModal";
import SupplyDetailModal from "./SupplyDetailModal";
import BarcodeGenerator from "./BarcodeGenerator";

import {
  Card,
  Container,
  Row,
  FormGroup,
  Input,
  Table,
  Button,
  Modal,
  Nav,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Label,
} from "reactstrap";

import Header from "../../components/Headers/Header.js";
import "../../assets/css/store_inventroy.css";
import {
  faTrash,
  faCircleInfo,
  faEdit,
  faBarcode,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"; // Import the apple icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from "date-fns";
import LoadingSpinner from "./LoadingSpinner";

const InventoryDashboard = () => {
  const [ingredients, setIngredients] = useState([]);
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [showIngredientModal, setShowIngredientModal] = useState(false);
  const [storeSupplies, setStoreSupplies] = useState([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedIngredientDetail, setSelectedIngredientDetail] =
    useState(null);
  const [supplies, setSupplies] = useState([]);
  const [selectedSupplyDetail, setSelectedSupplyDetail] = useState(null); // Optional, if you want a detail view for supplies
  const [selectedProductDetail, setSelectedProductDetail] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSupplyModal, setShowSupplyModal] = useState(false);
  const [selectedSupplyId, setSelectedSupplyId] = useState(null); // Define selectedSupplyId state
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 20;
  const [selectedDate, setSelectedDate] = useState(null);
  const initialPage = 1;
  const [showRecallSuccess, setShowRecallSuccess] = useState(false);
  const [isBarcodeModalOpen, setIsBarcodeModalOpen] = useState(false);
  const barcodeRef = useRef(null);
  const [currentBarcodeValue, setCurrentBarcodeValue] = useState("");
  const barcodeInputRef = useRef(null);
  const [isScanning, setIsScanning] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [selectedIngredientInventoryId, setSelectedIngredientInventoryId] =
    useState(null);
  const [ingredientInventoryDetail, setIngredientInventoryDetail] =
    useState(null);
  const [recallDetails, setRecallDetails] = useState([]);
  const [showProductInventoryDetailModal, setShowProductInventoryDetailModal] =
    useState(false);
  const [selectedProductInventoryDetail, setSelectedProductInventoryDetail] =
    useState(null);

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleInputChange = (e) => {
    setEditedValues({
      ...editedValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleProductInventoryUpdate = () => {
    // Refetch the product inventory data
    fetchProducts(selectedStore);
  };

  const handleSaveChanges = () => {
    // Here, you can send the editedValues to your API to update the product inventory
    console.log("Edited values:", editedValues);
    setIsEditMode(false);
  };

  // DateComponent for formatting the date
  const DateComponent = ({ date }) => {
    if (!date) {
      return <span>N/A</span>; // Return 'N/A' or some placeholder if the date is undefined or null
    }

    try {
      const formattedDate = format(parseISO(date), "yyyy-MM-dd");
      return <span>{formattedDate}</span>;
    } catch (error) {
      console.error("Error formatting date:", error);
      return <span>Invalid date</span>; // Return 'Invalid date' or some error indication
    }
  };

  // Export Logs to excel on Logs Modal
  const exportToExcel = () => {
    let exportUrl = `${process.env.REACT_APP_API_BASE_URL}/core/api/export-inventory-logs/`;

    if (selectedDate) {
      const formattedDate = selectedDate.toISOString().split("T")[0]; // Formats date as 'YYYY-MM-DD'
      exportUrl += `?date=${formattedDate}`;
    }

    // Direct the browser to the constructed URL to initiate the download
    window.location.href = exportUrl;
  };

  useEffect(() => {
    fetchStores();
  }, []);

  // Load this store by default, if no store on this id loads empty.
  const defaultStoreId = "1";

  const fetchStores = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Fetched Stores:", data);
      setStores(data);

      // Set the first store as selected by default if stores array is not empty
      if (data.length > 0) {
        setSelectedStore(data[0].id.toString()); // Convert to string if your IDs are integers
        // Fetch data related to the first store
        fetchIngredients(data[0].id);
        fetchProducts(data[0].id);
        fetchSupplies(data[0].id);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  useEffect(() => {
    // Call fetchInventoryLogs when the component mounts or selectedDate changes
    fetchInventoryLogs();
  }, [selectedDate]); // Dependency on selectedDate to refetch logs when it changes

  const fetchInventoryLogs = async () => {
    if (loading || !hasMore) return; // Prevents multiple calls
    setLoading(true);

    let queryString = `?page=${page + 1}`; // Adjust for zero-based index if needed. DRF uses 1-based indexing by default.
    if (selectedDate) {
      // Assuming selectedDate is a JavaScript Date object
      const formattedDate = selectedDate.toISOString().split("T")[0]; // Formats date as 'YYYY-MM-DD'
      queryString += `&date=${formattedDate}`;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/inventory-logs/${queryString}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.results.length === 0 || data.results.length < pageSize) {
        setHasMore(false); // No more logs to load
      }

      // Append new logs to the existing logs, accessing the `results` field
      setInventoryLogs((prevLogs) => [...prevLogs, ...data.results]);
      setPage((prevPage) => prevPage + 1); // Increment page for the next potential fetch
      setLoading(false);
    } catch (error) {
      console.error("Error fetching inventory logs:", error);
      setLoading(false);
    }
  };

  // Infinite scroll handler
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    // Check if the user has scrolled to the bottom
    if (scrollHeight - scrollTop <= clientHeight) {
      fetchInventoryLogs(); // Load more logs
    }
  };

  const fetchIngredients = async (storeId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/store/${storeId}/inventory/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Fetched Ingredients:", data); // Log fetched ingredients
      setIngredients(data);
    } catch (error) {
      console.error("Error fetching ingredients:", error);
    }
  };

  const fetchProducts = async (storeId) => {
    try {
      const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/store-inventory/${storeId}/products`,
        {
          method: "GET", // Method is optional here as 'GET' is the default
          credentials: "include", // Include credentials for CORS requests
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken, // Include the CSRF token in the request headers
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Fetched Products:", data);
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleStoreChange = (e) => {
    const storeId = e.target.value;
    setSelectedStore(storeId);
    if (storeId) {
      fetchIngredients(storeId);
      fetchProducts(storeId);
      fetchSupplies(storeId);
    }
  };

  const fetchIngredientDetail = async (ingredientId) => {
    try {
      const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/ingredient/${ingredientId}/`,
        {
          method: "GET", // Method is optional here as 'GET' is the default
          credentials: "include", // Include credentials for CORS requests
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken, // Include the CSRF token in the request headers
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSelectedIngredientDetail(data);
      setShowIngredientModal(true);
    } catch (error) {
      console.error("Error fetching ingredient detail:", error);
    }
  };

  const fetchIngredientInventoryDetail = async (ingredientId) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient-inventory-detail/${ingredientId}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setSelectedIngredientInventoryId(ingredientId);
      setShowIngredientModal(true);
    } catch (error) {
      console.error("Error fetching ingredient inventory detail:", error);
    }
  };

  const fetchProductInventoryDetail = async (productInventoryId) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-inventory-detail/${productInventoryId}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Received data for product inventory:", data);

      setSelectedProductInventoryDetail(data);
      setShowProductInventoryDetailModal(true);
    } catch (error) {
      console.error("Error fetching product inventory detail:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredIngredients = ingredients.filter(
    (ingredient) =>
      ingredient.ingredient_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      (ingredient.barcode &&
        ingredient.barcode.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (ingredient.serial_number &&
        ingredient.serial_number
          .toLowerCase()
          .includes(searchTerm.toLowerCase()))
  );

  const filteredProducts = products.filter(
    (productInventory) =>
      productInventory.product.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      productInventory.barcode
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      productInventory.serial_number
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  // fetch supplies
  const fetchSupplies = async (storeId) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supplies-store-inventory/${storeId}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Fetched Supplies:", data);
      setSupplies(data.results); // Set the supplies state with the 'results' array from the response
    } catch (error) {
      console.error("Error fetching supplies:", error);
    }
  };

  const fetchSupplyDetail = async (supplyId) => {
    try {
      const csrfToken = getCsrfToken();

      // Fetch the specific StoreSupply object for the given ID
      const storeSupplyResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/store-supply/${supplyId}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (!storeSupplyResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const storeSupplyData = await storeSupplyResponse.json();

      if (storeSupplyData) {
        setSelectedSupplyDetail(storeSupplyData);
        setShowSupplyModal(true);
      } else {
        console.error("StoreSupply object not found");
      }
    } catch (error) {
      console.error("Error fetching store supply detail:", error);
    }
  };

  // Search on Supplies Table
  const filteredSupplies = supplies.filter((supply) => {
    return (
      supply.supply_name.toLowerCase().includes(searchTerm) ||
      (supply.barcode && supply.barcode.toLowerCase().includes(searchTerm))
      // You can add more fields to check here if necessary
    );
  });

  // Recall Product
  const recallProductInventory = async (productInventoryId) => {
    if (window.confirm("Are you sure you want to recall this product?")) {
      console.log(
        "Attempting to recall product inventory with ID:",
        productInventoryId
      );

      const csrfToken = getCsrfToken();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/product/recall/${productInventoryId}/`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
            withCredentials: true,
          }
        );

        if (response.data.status === "success") {
          setShowRecallSuccess(true);
          toast.warn("Product inventory successfully recalled!");
          fetchIngredients(selectedStore);
          fetchProducts(selectedStore); // Refetch products after successful recall
        }
      } catch (error) {
        console.error("Error recalling product inventory:", error);
        toast.error(
          "Error recalling product inventory!",
          error.response?.data?.message || error.message
        );
      }
    }
  };

  // Recall Ingredient
  const recallIngredient = async (ingredientId) => {
    if (window.confirm("Are you sure you want to recall this ingredient?")) {
      const csrfToken = getCsrfToken();

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient/recall/${ingredientId}/`,
          {},
          {
            withCredentials: true,
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          }
        );

        if (response.data.status === "success") {
          toast.warn("Ingredient recalled successfully!");
          fetchProducts(selectedStore);
          fetchIngredients(selectedStore); // Refetch ingredients after successful recall
          setTimeout(() => setShowRecallSuccess(false), 3000);
        }
      } catch (error) {
        console.error("Error recalling ingredient:", error);
        toast.error("Error recalling ingredient.");
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const displayIsRecalled = (isRecalled) => {
    if (isRecalled) {
      return <span style={{ fontWeight: "bold", color: "red" }}>Yes</span>;
    } else {
      return "No";
    }
  };

  useEffect(() => {
    console.log("Modal Open State: ", showSupplyModal); // Logs the visibility state of the modal
    console.log("Selected Supply ID: ", selectedSupplyDetail); // Logs the current supply ID
  }, [showSupplyModal, selectedSupplyDetail]);

  //Refetch supplies after update on modal
  const handleSupplyDetailUpdated = () => {
    // Refetch or perform any other actions here
    fetchSupplies(selectedStore);
  };

  // Function to delete an ingredient
  const deleteIngredient = async (ingredientId) => {
    if (window.confirm("Are you sure you want to delete this ingredient?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient-inventory/${ingredientId}/delete/`,
          {
            headers: {
              "X-CSRFToken": getCsrfToken(),
            },
            withCredentials: true,
          }
        );

        if (response.status === 204) {
          // Assuming 204 No Content on successful deletion
          toast.success("Ingredient deleted successfully!");
          // Remove the ingredient from the state
          setIngredients((currentIngredients) =>
            currentIngredients.filter(
              (ingredient) => ingredient.id !== ingredientId
            )
          );
        }
      } catch (error) {
        console.error("Failed to delete ingredient:", error);
        toast.error("Failed to delete ingredient.");
      }
    }
  };

  // Barcodes Generate

  const handleShowBarcodeModal = (barcode) => {
    console.log("Barcode to be set:", barcode);  // Log the barcode value being passed
    setCurrentBarcodeValue(barcode);
    setIsBarcodeModalOpen(true);
};


  // Barcode Reader Barcode input and process
  const startBarcodeScanner = (event) => {
    event.preventDefault(); // Prevent the default button behavior
    setIsScanning(!isScanning); // Toggle scanning mode based on current state
    console.log(`Scanning mode: ${!isScanning ? "OFF" : "ON"}`); // Log the new state

    if (!isScanning) {
      barcodeInputRef.current.focus(); // Focus the invisible input only if scanning is turned on
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault(); // Prevent the default button behavior
  };

  const handleBarcodeDetected = (event) => {
    if (event.key === "Enter") {
      setIsScanning(false);
      const scannedBarcode = event.target.value;
      setSearchTerm(scannedBarcode);
      performSearch(scannedBarcode);
      event.target.value = ""; // Clear the input field
    }
  };

  useEffect(() => {
    if (isBarcodeModalOpen && currentBarcodeValue) {
      if (barcodeRef.current) {
        // Ensures that the modal is open, a barcode value is set, and the ref is linked to a DOM element
        console.log("Barcode modal is open, and ref is attached");
      } else {
        console.log("Waiting for ref to attach");
      }
    } else {
      console.log("Waiting for modal to open or barcode value to be set");
    }
  }, [isBarcodeModalOpen, currentBarcodeValue]);

  const toggleBarcodeModal = () => {
    setIsBarcodeModalOpen(!isBarcodeModalOpen);
    if (!isBarcodeModalOpen) {
      // Clear the barcode value when the modal is closed to prevent stale data issues.
      setCurrentBarcodeValue("");
    }
  };

  const performSearch = (scannedBarcode) => {
    // Trigger the search immediately
    // This might involve calling an API or filtering data already in the state
    // For example, it could refresh the product or ingredient lists based on the scanned barcode
    fetchProducts(scannedBarcode); // Assuming you have a function to fetch products based on search criteria
  };

  // Delete Product
  const deleteProductInventory = async (productId) => {
    if (
      window.confirm("Are you sure you want to delete this product inventory?")
    ) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/product-inventory/${productId}/delete/`,
          {
            headers: {
              "X-CSRFToken": getCsrfToken(), // Ensure you have a function to get CSRF token if you are using CSRF protection
            },
            withCredentials: true,
          }
        );

        if (response.status === 204) {
          toast.success("Product inventory deleted successfully!");
          // Update the state to remove the item from the list without refreshing the page
          setProducts((currentProducts) =>
            currentProducts.filter((product) => product.id !== productId)
          );
        }
      } catch (error) {
        console.error("Failed to delete product inventory:", error);
        toast.error("Failed to delete product inventory. Please try again.");
      }
    }
  };

  // Delete Supply
  const deleteStoreSupply = async (storeSupplyId, setSupplies) => {
    if (window.confirm("Are you sure you want to delete this store supply?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/store-supply-inventory/${storeSupplyId}/delete/`,
          {
            headers: {
              "X-CSRFToken": getCsrfToken(),
            },
            withCredentials: true,
          }
        );

        if (response.status === 204) {
          toast.success("Store supply deleted successfully!");
          setSupplies((currentSupplies) =>
            currentSupplies.filter((supply) => {
              console.log("Checking:", supply.id, "against", storeSupplyId);
              return supply.id !== storeSupplyId;
            })
          );
        }
      } catch (error) {
        console.error("Failed to delete store supply:", error);
        toast.error("Failed to delete store supply. Please try again.");
      }
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* Flex Container for Dropdown and Search Bar */}
              <div className="flex-container">
                {/* Button to open Logs Modal */}
                <Button
                  color="primary"
                  size="sm"
                  title="Open Logs Records"
                  onClick={() => {
                    setShowLogsModal(true);
                    fetchInventoryLogs();
                  }}
                >
                  Logs
                </Button>
                <div>
                  <Button
                    size="sm"
                    color="primary"
                    title="Toggle Barcode Scanner"
                    onClick={startBarcodeScanner}
                    onMouseDown={handleMouseDown}
                    className={classnames("barcode-button", {
                      "blinking-red": isScanning,
                    })}
                    style={{
                      marginLeft: "5px",
                      padding: "5px 8px",
                      fontSize: "12px",
                      backgroundColor: isScanning ? "#dc3545" : "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      outline: "none",
                      transition: "background-color 0.3s",
                    }}
                  >
                    <FontAwesomeIcon icon={faBarcode} />{" "}
                    {isScanning ? "Scanning…" : "Scan"}
                  </Button>
                  {/* Invisible input field for barcode scanner input */}
                  <input
                    type="text"
                    ref={barcodeInputRef}
                    style={{
                      position: "absolute",
                      left: "-1000px",
                      top: "-1000px",
                    }}
                    onKeyPress={handleBarcodeDetected}
                  />
                </div>

                {/* Dropdown for Store Selection */}
                <FormGroup className="store-select-dropdown">
                  <Input
                    type="select"
                    name="storeSelect"
                    id="storeSelect"
                    onChange={handleStoreChange}
                    value={selectedStore}
                  >
                    <option value="">Select Store First</option>
                    {stores.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                {/* Search Bar */}
                <FormGroup className="search-bar-input position-relative">
                  <Input
                    type="text"
                    placeholder="Search Ingredients, Supplies, and Products"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ paddingRight: "30px" }} // Add padding to prevent text from being hidden by the icon
                  />
                  {searchTerm && (
                    <FontAwesomeIcon
                      icon={faTimesCircle} // FontAwesome icon for "X"
                      className="position-absolute"
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        color: "#8898aa",
                        cursor: "pointer",
                      }}
                      onClick={() => setSearchTerm("")} // Clear the search term on click
                    />
                  )}
                </FormGroup>
              </div>

              {/* Ingredients Table */}
              <Card className="shadow mb-5">
                <div className="table-container">
                  {loading ? (
                    <LoadingSpinner />
                  ) : ingredients.length > 0 ? (
                    <Table
                      className="align-items-center table-ingredients"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="first-col">Ingredient</th>
                          <th>Lot-Serial</th>
                          <th>Stock</th>
                          <th>Packg</th>
                          <th>Price</th>
                          <th>Exp. Date</th>
                          <th>Date added</th>
                          <th>Recalled</th>
                          <th className="action-column">Actions</th>{" "}
                          {/* Combined column for all actions */}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredIngredients.map((ingredient) => (
                          <tr key={ingredient.id}>
                            <td className="first-col">
                              {ingredient.ingredient_name}
                            </td>
                            <td>{ingredient.serial_number}</td>
                            <td className="stock-col">
                              {ingredient.quantity.toFixed(2)}{" "}
                            </td>
                            <td className="weight-col">
                              {ingredient.packaging_type || "N/A"} of{" "}
                              {ingredient.weight} {ingredient.weight_units}
                            </td>
                            <td className="price-col">${ingredient.price}</td>
                            <td className="expiration_date-col">
                              {ingredient.expiration_date
                                ? ingredient.expiration_date
                                : "N/A"}
                            </td>
                            <td className="date_added-col">
                              <DateComponent date={ingredient.date_added} />
                            </td>
                            <td>
                              {ingredient.is_recalled ? (
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Recalled
                                </span>
                              ) : (
                                "No"
                              )}
                            </td>
                            <td className="action-column">
                              {/* All buttons in one cell with flexbox */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  size="sm"
                                  title="View Ingredient Details"
                                  className="action-button"
                                  onClick={() =>
                                    fetchIngredientInventoryDetail(
                                      ingredient.id
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faCircleInfo} />
                                </Button>
                                <Button
                                  color="warning"
                                  size="sm"
                                  onClick={() =>
                                    recallIngredient(ingredient.id)
                                  }
                                  disabled={ingredient.is_recalled}
                                  className="action-button"
                                  style={{ marginLeft: "5px" }}
                                >
                                  {ingredient.is_recalled
                                    ? "Recalled"
                                    : "Recall"}
                                </Button>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    handleShowBarcodeModal(ingredient.barcode)
                                  } // Ensure 'ingredient' is the mapped variable
                                  className="action-button"
                                  style={{ marginLeft: "5px" }}
                                >
                                  <FontAwesomeIcon icon={faBarcode} />
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() =>
                                    deleteIngredient(ingredient.id)
                                  }
                                  className="action-button"
                                  style={{ marginLeft: "5px" }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center p-5">
                      <h6>No ingredients available.</h6>
                    </div>
                  )}
                </div>
              </Card>

              {/* Products Table */}
              <Card className="shadow mb-5">
                <div className="table-container">
                  {loading ? (
                    <LoadingSpinner />
                  ) : products.length > 0 ? (
                    <Table
                      className="align-items-center table-products"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="first-col">Product</th>
                          <th className="first-col">Batch S/N</th>
                          <th className="stock-col">Stock</th>
                          <th className="price-col">Price</th>
                          <th className="price-col">Recalled</th>
                          <th className="price-col">Date Added</th>
                          <th className="price-col">Date Updated</th>
                          <th className="action-column">Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredProducts.map((productInventory) => (
                          <tr key={productInventory.product.id}>
                            <td className="first-col">
                              {productInventory.product.name}
                            </td>
                            <td>{productInventory.serial_number}</td>
                            <td>{productInventory.quantity}</td>
                            <td>
                              ${productInventory.product.price.toFixed(2)}
                            </td>
                            <td>
                              {productInventory.is_recalled ? (
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Recalled
                                </span>
                              ) : (
                                "No"
                              )}
                            </td>
                            <td className="date-added-col">
                              <DateComponent
                                date={productInventory.date_added}
                              />
                            </td>
                            <td className="last-updated-col">
                              <DateComponent
                                date={productInventory.last_updated}
                              />
                            </td>
                            <td className="action-column">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  size="sm"
                                  title="View Product Inventory Details"
                                  className="action-button"
                                  onClick={() =>
                                    fetchProductInventoryDetail(
                                      productInventory.id
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faCircleInfo} />
                                </Button>
                                <Button
                                  color="warning"
                                  size="sm"
                                  onClick={() =>
                                    recallProductInventory(productInventory.id)
                                  }
                                  disabled={productInventory.is_recalled}
                                  style={{ marginLeft: "5px" }}
                                >
                                  {productInventory.is_recalled
                                    ? "Recalled"
                                    : "Recall"}
                                </Button>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    handleShowBarcodeModal(
                                      productInventory.barcode
                                    )
                                  } // Use the mapped 'productInventory' variable
                                  className="action-button"
                                  style={{ marginLeft: "5px" }}
                                >
                                  <FontAwesomeIcon icon={faBarcode} />
                                </Button>

                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() =>
                                    deleteProductInventory(productInventory.id)
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center p-5">
                      <h6>No products available.</h6>
                    </div>
                  )}
                </div>
              </Card>
              {/* Supplies Table */}
              <Card className="shadow mb-5">
                <div className="table-container">
                  {loading ? (
                    <LoadingSpinner />
                  ) : filteredSupplies.length > 0 ? (
                    <Table
                      className="align-items-center table-supplies"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="first-col">Supply</th>
                          <th className="stock-col">Stock</th>
                          <th className="price-col">Price</th>
                          <th className="price-col">Total</th>
                          <th className="price-col">Date Added</th>
                          <th className="price-col">Date Updated</th>
                          <th className="action-column">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSupplies.map((supply) => (
                          <tr key={supply.id}>
                            <td className="first-col">{supply.supply_name}</td>
                            <td>{supply.quantity}</td>
                            <td>
                              {supply.supply_price
                                ? `$${parseFloat(supply.supply_price).toFixed(
                                    2
                                  )}`
                                : "N/A"}
                            </td>
                            <td>
                              {supply.supply_price && supply.quantity
                                ? `$${(
                                    parseFloat(supply.supply_price) *
                                    supply.quantity
                                  ).toFixed(2)}`
                                : "N/A"}
                            </td>
                            <td className="price-col">
                              <DateComponent date={supply.date_added} />
                            </td>
                            <td className="price-col">
                              <DateComponent date={supply.last_updated} />
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  size="sm"
                                  title="View Inventory Details"
                                  className="action-button"
                                  onClick={() => fetchSupplyDetail(supply.id)}
                                >
                                  <FontAwesomeIcon icon={faCircleInfo} />
                                </Button>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    handleShowBarcodeModal(supply.barcode)
                                  }
                                  className="action-button"
                                  style={{ marginLeft: "5px" }}
                                >
                                  <FontAwesomeIcon icon={faBarcode} />
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  className="action-button"
                                  onClick={() => {
                                    console.log("Deleting ID:", supply.id);
                                    deleteStoreSupply(supply.id, setSupplies);
                                  }}
                                  style={{ marginLeft: "5px" }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="text-center p-5">
                      <h6>No supplies available.</h6>
                    </div>
                  )}
                </div>
              </Card>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Ingredient Inventory Detail Modal */}
      <IngredientInventoryDetailModal
        isOpen={showIngredientModal}
        toggle={() => setShowIngredientModal(false)}
        ingredientInventoryId={selectedIngredientInventoryId}
      />
      {/* Product Inventory Detail Modal */}
      <ProductInventoryDetailModal
        isOpen={showProductInventoryDetailModal}
        toggle={() =>
          setShowProductInventoryDetailModal(!showProductInventoryDetailModal)
        }
        selectedProductInventoryDetail={selectedProductInventoryDetail}
        activeTab={activeTab}
        toggleTab={toggleTab}
        onUpdateSuccess={handleProductInventoryUpdate}
      />
      {/* Supply Detail Modal */}
      <SupplyDetailModal
        isOpen={showSupplyModal}
        toggle={() => setShowSupplyModal(!showSupplyModal)}
        selectedSupplyDetail={selectedSupplyDetail}
        selectedStore={selectedStore}
        setSelectedSupplyDetail={setSelectedSupplyDetail}
        setShowSupplyModal={setShowSupplyModal}
        onSupplyDetailUpdated={handleSupplyDetailUpdated}
      />
      {/* Inventory Logs Modal */}
      <Modal
        isOpen={showLogsModal}
        toggle={() => setShowLogsModal(!showLogsModal)}
      >
        <ModalHeader toggle={() => setShowLogsModal(!showLogsModal)}>
          Inventory Update Logs
          <Button
            color="success"
            size="sm"
            onClick={exportToExcel}
            style={{ marginLeft: "10px", marginTop: "10px" }} // Adjust styling as needed
          >
            Export to Excel
          </Button>
        </ModalHeader>
        <ModalBody
          onScroll={handleScroll}
          style={{ maxHeight: "600px", overflowY: "auto" }} // Adjust maxHeight and overflowY
        >
          <div className="date-picker-container">
            <ReactDatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              style={{ marginBottom: "30px" }} // Adjust styling as needed
              dateFormat="MMMM d, yyyy"
              className="form-control"
              placeholderText="Select a date to filter logs"
            />
          </div>
          <div className="modal-inventory-log-table-container">
            {" "}
            {/* Container to handle overflow */}
            <Table striped className="modal-inventory-log-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Action</th>
                  <th>Details</th>
                  <th>Serial Number</th>{" "}
                  {/* Add Serial Number column to header */}
                  <th>User</th> {/* Add User column to header */}
                </tr>
              </thead>
              <tbody style={{ maxHeight: "400px", overflowY: "auto" }}>
                {/* Adjust maxHeight and overflowY */}
                {inventoryLogs
                  .filter((log) => {
                    if (!selectedDate) return true; // No filter applied if no date is selected

                    // Create a new Date object from the log date
                    const logDate = new Date(log.date);
                    // Set hours, minutes, seconds, and milliseconds to 0
                    logDate.setHours(0, 0, 0, 0);

                    // Clone selectedDate and reset time to midnight for accurate comparison
                    const selectedDateMidnight = new Date(
                      selectedDate.getTime()
                    );
                    selectedDateMidnight.setHours(0, 0, 0, 0);

                    // Compare the two dates
                    return logDate.getTime() === selectedDateMidnight.getTime();
                  })
                  .map((log) => (
                    <tr key={log.id}>
                      <td>{new Date(log.date).toLocaleString()}</td>
                      <td>{log.action}</td>
                      <td className="details-col">{log.details}</td>
                      <td>{log.serial_number}</td>{" "}
                      {/* Display the serial number */}
                      <td>{log.user_email}</td> {/* Display the user email */}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isBarcodeModalOpen}
        toggle={toggleBarcodeModal}
        className="barcode-modal"
      >
        <ModalHeader toggle={toggleBarcodeModal}>Barcode</ModalHeader>
        <ModalBody className="barcode-modal-body">
          {isBarcodeModalOpen && currentBarcodeValue && (
            <BarcodeGenerator
              value={currentBarcodeValue}
              ref={barcodeRef}
              className="barcode-generator"
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default InventoryDashboard;
