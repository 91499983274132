import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import axios from "axios";
import "../../assets/css/custom_header.css"; // Adjust the path accordingly

const Header = () => {
  const [statistics, setStatistics] = useState({
    totalRecallPendings: 0,
    totalProductsActive: 0,
    totalBatchesActive: 0,
    totalSuppliesActive: 0,

    RecallsPendingDiff: 0,
    ProductsActiveDiff: 0,
    BatchesActiveDiff: 0,
    SuppliesActiveDiff: 0,
  });

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    try {
      const response = await axios.get("/core/api/get_combined_statistics/");
      setStatistics(response.data);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    }
  };

  const renderArrow = (diff) => {
    if (isNaN(diff) || diff === 0) {
      return <i className="fas fa-equals" />;
    } else if (diff > 0) {
      return <i className="fas fa-arrow-up text-success" />;
    } else {
      return <i className="fas fa-arrow-down text-danger" />;
    }
  };

  return (
    <>
      <div className="custom-header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              {/* Total Recalls Pending */}
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Recalls Pending
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {statistics.totalRecallPendings}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-exclamation-triangle" />
                        </div>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>

              {/* Total Products Active */}
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Products Active
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {statistics.totalProductsActive}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i className="ni ni-tag" />
                        </div>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>

              {/* Total Batches Active */}
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Batches Active
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {statistics.totalBatchesActive}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-boxes" />
                        </div>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>

              {/* Total Supplies Active */}
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Supplies Active
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {statistics.totalSuppliesActive}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-tools" />
                        </div>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
