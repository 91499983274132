import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Button,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  Alert,
  Tooltip,

} from "reactstrap";
import LoadingSpinner from "./LoadingSpinner";
import Header from "../../components/Headers/Header.js";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// Import FontAwesome and the trash icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPlus,
  faSortUp,
  faSortDown,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/tags_badges.css";
import axios from "axios";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Tables = () => {
  const [ingredients, setIngredients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const [suppliers, setSuppliers] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [dataTypeFilter, setDataTypeFilter] = useState("foundation_food");
  const [recipe, setRecipe] = useState({
    name: "",
    description: "",
    creation_date: new Date().toISOString().split("T")[0],
    number_of_servings: 1,
    packages_per_batch: 0,
    sugested_serving_size: 0,
    recipe_ingredients: [],
    store: 1,
  });
  const [stores, setStores] = useState([]);
  const [newTagName, setNewTagName] = useState(""); // State for handling new tag name
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [tagMap, setTagMap] = useState({}); // New state to map tag names to their IDs
  const [tagColor, setTagColor] = useState("#007bff"); // Default color
  const [forceUpdateKey, setForceUpdateKey] = useState(0); // New state for forcing update
  const ingredientsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [tagsInput, setTagsInput] = useState(""); // State for the tag input field
  const [selectedTags, setSelectedTags] = useState([]); // State to store selected tags
  const [isLoading, setIsLoading] = useState(false);
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [selectedIngredientDetail, setSelectedIngredientDetail] =
    useState(null);
  useState(false);
  const [showRecipeCreationSuccess, setShowRecipeCreationSuccess] =
    useState(false);

  const toggleTooltip = (index) => {
    setTooltipOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
    // Optionally, immediately fetch or sort ingredients after toggling
    // fetchIngredients();
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchSuppliers = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`, {
      method: "GET",
      credentials: "include", // Include credentials for CORS requests
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setSuppliers(data))
      .catch((error) => console.error("Error fetching suppliers:", error));
  };

  useEffect(() => {
    fetchIngredients();
    fetchSuppliers();
  }, []);

  const toggleTagModal = () => {
    setIsTagModalOpen(!isTagModalOpen);
  };

  const fetchIngredientsDebounced = debounce(() => {
    fetchIngredients();
  }, 300);

  const handleCreateNewTag = () => {
    if (newTagName.trim() !== "") {
      createNewTag(newTagName.trim(), tagColor) // Now passing tagColor as well
        .then(() => {
          // Close modal and clear the new tag name
          setNewTagName("");
          toggleTagModal();

          // Reset the tag color to default after tag creation
          setTagColor("#007bff"); // Assuming #007bff is your default color

          // Fetch tags again after a brief delay to ensure backend processing time
          setTimeout(() => {
            fetchTags("");
          }, 500);
        })
        .catch((error) => {
          console.error("Error in creating new tag:", error);
          toast.error("Error in creating new tag:", error);
        });
    }
  };

  // Update fetchTags to store the tag name to ID mapping
  const fetchTags = (input) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/core/api/tags/?search=${encodeURIComponent(input)}`,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      )
      .then((response) => {
        const tagNames = response.data.map((tag) => tag.name);
        setTagSuggestions(tagNames);

        // Update to store color as well
        const newTagMap = {};
        response.data.forEach((tag) => {
          newTagMap[tag.name] = { id: tag.id, color: tag.color };
        });
        setTagMap(newTagMap);
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
        toast.error("Error fetching tags:", error);
      });
  };

  useEffect(() => {
    // Fetch tags when the component mounts
    fetchTags("");
  }, []);

  // Function to handle tag input changes
  const handleTagsInputChange = (e) => {
    setTagsInput(e.target.value);
  };

  const handleAddTag = () => {
    const newTag = tagInput.trim();

    // Check if there's a new tag to add and if it's not already included in the selected tags
    if (newTag && !selectedTags.includes(newTag)) {
      // Check if the tag already exists in suggestions, which means it's a valid tag from the backend
      const tagExists = tagSuggestions.includes(newTag);

      // If the tag does not exist in the backend, create it
      if (!tagExists) {
        // Pass tagColor along with tagName to the createNewTag function
        createNewTag(newTag, tagColor)
          .then(() => {
            // Add the new tag to the selected tags after it's successfully created
            setSelectedTags((prevTags) => [...prevTags, newTag]);
            setTagInput(""); // Clear the input field
          })
          .catch((error) => {
            console.error("Error in creating new tag:", error);
            toast.error("Error in creating new tag:", error);
          });
      } else {
        // If the tag exists, just add it to selected tags
        setSelectedTags((prevTags) => [...prevTags, newTag]);
        setTagInput(""); // Clear the input field
      }
    }
  };

  // Helper function to create a new tag
  const createNewTag = (tagName, tagColor) => {
    // Ensure tagColor is passed as a parameter or defined within the scope
    return new Promise((resolve, reject) => {
      const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/tags/create/`,
          { name: tagName, color: tagColor }, // Include tagColor in the payload
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken, // Set the CSRF token in the request header
            },
            withCredentials: true, // Include credentials if necessary
          }
        )
        .then((response) => {
          console.log("Tag created:", response.data);
          toast.success("Tag created successfully");
          resolve(response.data);
        })
        .catch((error) => {
          console.error("Error creating tag:", error);
          toast.error("Error creating tag");
          reject(error);
        });
    });
  };

  const handleTagInputChange = async (e) => {
    const input = e.target.value;
    setTagInput(input);

    if (input.trim().length > 0) {
      // Check if there is an actual input
      try {
        // Call the API with the search parameter to get relevant tags
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_BASE_URL
          }/core/api/tags/?search=${encodeURIComponent(input)}`,
          {
            withCredentials: true, // Include credentials if necessary
            headers: {
              "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
            },
          }
        );
        const tagNames = response.data.map((tag) => tag.name);
        setTagSuggestions(tagNames); // Set only the filtered tags based on input
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    } else {
      setTagSuggestions([]); // If input is empty, clear suggestions
    }
  };

  // This function is called when a tag is selected from the dropdown
  const handleTagSelectChange = (e) => {
    const tagName = e.target.value;
    handleSelectTag(tagName); // Call the existing function to handle tag selection
    setTagInput(""); // Clear the tag input field
  };

  // This function is updated to handle displaying tags as badges
  const handleSelectTag = (tagName) => {
    if (tagName && !selectedTags.includes(tagName)) {
      setSelectedTags([...selectedTags, tagName]);
    }
  };

  // Function to handle removing a tag
  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    setSelectedTags(updatedTags);

    // Optional: If you want to delete the tag from the database as well, you can make a DELETE request to your Django API here.
  };

  // Function for fetching ingredients
  const fetchIngredients = () => {
    setIsLoading(true);
    let url = `${
      process.env.REACT_APP_API_BASE_URL
    }/food/api/tenant-ingredients/?search=${encodeURIComponent(searchTerm)}`;
    if (dataTypeFilter) {
      url += `&data_type=${encodeURIComponent(dataTypeFilter)}`;
    }

    axios
      .get(url, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": getCsrfToken(), // Ensure this method is defined to fetch CSRF token
        },
      })
      .then((response) => {
        if (response.data && Array.isArray(response.data.results)) {
          const results = response.data.results;

          // Sort ingredients, handling potential null values in ingredient_description
          const sortedIngredients = results.sort((a, b) => {
            const nameA = (a.ingredient_description || "").toUpperCase(); // Default to empty string if null
            const nameB = (b.ingredient_description || "").toUpperCase(); // Default to empty string if null
            if (sortDirection === "asc") {
              return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            } else {
              return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
            }
          });

          setIngredients(sortedIngredients);
          const totalPages = Math.ceil(
            response.data.count / ingredientsPerPage
          );
          setTotalPages(totalPages);
        } else {
          console.error(
            "Expected an array but got:",
            typeof response.data,
            response.data
          );
          setIngredients([]);
          setTotalPages(0);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Fetching ingredients failed:", error);
        toast.error("Fetching ingredients failed");
        setIsLoading(false);
        setIngredients([]);
        setTotalPages(0);
      });
  };

  // Display modal with ingredeint detail
  const handleDetailClick = (ingredientId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/ingredients-detail/${ingredientId}/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": getCsrfToken(),
          },
        }
      )
      .then((response) => {
        setSelectedIngredientDetail(response.data);
        setIsDetailModalOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching ingredient details:", error);
        toast.error("Error fetching ingredient details", error);
      });
  };

  // Effect for real-time search and reset paga
  useEffect(() => {
    // Reset to page 1 on every new search term
    setCurrentPage(1);
    fetchIngredientsDebounced();
  }, [searchTerm, dataTypeFilter]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDataTypeFilterChange = (e) => {
    setDataTypeFilter(e.target.value);
  };

  const handleRecipeNameChange = (e) => {
    setRecipe({ ...recipe, name: e.target.value });
  };

  const handleIngredientQuantityChange = (index, quantity) => {
    const updatedIngredients = recipe.recipe_ingredients.map((item, idx) => {
      if (idx === index) {
        return { ...item, quantity };
      }
      return item;
    });
    setRecipe({ ...recipe, recipe_ingredients: updatedIngredients });
  };

  // Function to delete an ingredient from the recipe
  const handleDeleteIngredientFromRecipe = (index) => {
    const updatedIngredients = [...recipe.recipe_ingredients];
    updatedIngredients.splice(index, 1);
    setRecipe({ ...recipe, recipe_ingredients: updatedIngredients });
  };

  const handleAddIngredientToRecipe = (ingredient) => {
    setRecipe((prevRecipe) => ({
      ...prevRecipe,
      recipe_ingredients: [
        {
          ingredient: ingredient.id, // The ingredient's id will now be available
          ingredient_description: ingredient.ingredient_description,
          quantity_used: 0,
          units: "", // Default units or empty string
          price: 0.0,
        },
        ...prevRecipe.recipe_ingredients,
      ],
    }));
  };

  const handleIngredientDetailsChange = (index, field, value) => {
    const updatedIngredients = recipe.recipe_ingredients.map((item, idx) => {
      if (idx === index) {
        // Update the field based on input type, convert to number if necessary
        const updatedValue =
          field === "price" || field === "quantity_used"
            ? parseFloat(value)
            : value;
        return { ...item, [field]: updatedValue };
      }
      return item;
    });
    setRecipe({ ...recipe, recipe_ingredients: updatedIngredients });
  };

  // Create Recipe
  const createRecipe = () => {
    const csrfToken = getCsrfToken();
    const tagIds = selectedTags
      .map((tagName) => tagMap[tagName]?.id)
      .filter((id) => id !== undefined);
  
    const requestData = {
      name: recipe.name,
      description: recipe.description,
      creation_date: recipe.creation_date,
      number_of_servings: parseInt(recipe.number_of_servings, 10),
      store: recipe.store,
      recipe_ingredients: recipe.recipe_ingredients.map((ri) => ({
        ingredient_detail: { id: ri.ingredient },
        quantity_used: ri.quantity_used || 0,
        units: ri.units || "gram",
        price: ri.price || 0.0,
      })),
      tags: tagIds,
      units: recipe.units || "gram", // Add the units field with a default value or the value from the recipe object
    };
  
    console.log("Creating recipe with requestData:", requestData);
  
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe/`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        toast.success("Recipe created. Go to recipe tab to enter costs and servings to have nutrition facts labels!", {
          autoClose: 8000,
        });
        // Reset the recipe form
        setRecipe({
          name: "",
          description: "",
          number_of_servings: 1,
          recipe_ingredients: [],
          store: 1,
          creation_date: new Date().toISOString().split("T")[0],
          units: "cups", // Reset the units field to a default value
        });
        setSelectedTags([]);
      })
      .catch((error) => {
        console.error("Error creating recipe:", error);
        if (error.response && error.response.data) {
          const errorMessage = `Error creating recipe: ${JSON.stringify(
            error.response.data
          )}`;
          toast.error(errorMessage);
        } else {
          toast.error("Error creating recipe! Please try again.");
        }
      });
  };

    
  // Function to associate tags with a recipe
  const associateTagsWithRecipe = (recipeId, tagIds) => {
    const csrfToken = getCsrfToken();
    const tagIdsForRequest = selectedTags
      .map((tagName) => tagMap[tagName]?.id)
      .filter((id) => id !== undefined);

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe/${recipeId}/set_tags/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ tags: tagIdsForRequest }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Reset the recipe state to initial values
        setRecipe({
          name: "",
          description: "",
          number_of_servings: 1,
          recipe_ingredients: [],
          store: 1,
          creation_date: new Date().toISOString().split("T")[0],
        });
      })
      .catch((error) => {
        console.error("Error associating tags:", error);
        setShowSuccess(false);
      });
  };

  // Store Dropdown
  useEffect(() => {
    // Fetch stores data from the endpoint
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`, {
        withCredentials: true, // Include credentials if necessary
        headers: {
          "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
        },
      })
      .then((response) => {
        // Set the fetched store data in the state
        setStores(response.data);
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Ingredients state updated:", ingredients);
  }, [ingredients]);

  return (
    <React.Fragment key={forceUpdateKey}>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          {/* Left Column: Ingredient Search and Add */}
          <Col md="6">
            <Card className="shadow">
              <CardHeader className="border-0">
              <h3>
                  Ingredients
                  <FontAwesomeIcon icon={faInfoCircle} data-tip="If you need to add more ingredients to your database go to ingredients tab" style={{ marginLeft: '8px', cursor: 'pointer' }} />
                </h3>
                <Input
                  type="text"
                  placeholder="Search ingredients..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {/* Add Dropdown for data_type Filter */}
                <Input
                  type="select"
                  className="drop-down-foods"
                  value={dataTypeFilter}
                  onChange={handleDataTypeFilterChange}
                >
                  <option value="">All ingredients</option>
                  {/* Populate these options with your available data types */}
                  <option value="sr_legacy_food">Legacy Foods</option>
                  <option value="foundation_food">Foundation Foods</option>
                </Input>
              </CardHeader>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Description</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <LoadingSpinner />
                          <div>Loading ingredients, please wait...</div>
                        </td>
                      </tr>
                    ) : ingredients.length > 0 ? (
                      ingredients
                        .slice(
                          (currentPage - 1) * ingredientsPerPage,
                          currentPage * ingredientsPerPage
                        )
                        .map((ingredient, index) => (
                          <tr key={index}>
                            <td
                              className={`ingredient-description ${
                                ingredient.is_recalled
                                  ? "recalled-ingredient"
                                  : ""
                              }`}
                              onClick={() => handleDetailClick(ingredient.id)}
                              style={{ cursor: "pointer" }}
                            >
                              {ingredient.ingredient_description}
                            </td>
                            <td className="add-button-cell">
                              <Button
                                color="primary"
                                title="Add to Recipe"
                                size="sm"
                                onClick={() =>
                                  handleAddIngredientToRecipe(ingredient)
                                }
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No ingredients found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}

              {/* Pagination Controls */}
              <div className="pagination">
                <Button
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  onClick={() =>
                    handlePageChange(Math.min(totalPages, currentPage + 1))
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            </Card>
          </Col>

          {/* Right Column: Recipe Creation */}
          <Col md="6">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3>Recipe Creation</h3>
              </CardHeader>
              <div className="table-separator">
                <Table
                  className="align-items-center table-flush table-striped"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Ingredient</th>
                      <th>Units</th>
                      <th>Qty</th>
                      <th width="50px">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recipe.recipe_ingredients.map((ingredient, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="ingredient-description-modal">
                            {ingredient.ingredient_description}
                          </td>
                          <td>
                            <select
                              className="form-control"
                              style={{ width: "90px" }}
                              value={ingredient.units || ""}
                              onChange={(e) =>
                                handleIngredientDetailsChange(
                                  index,
                                  "units",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select units</option>
                              <option value="cup">cup</option>
                              <option value="lb">lb</option>
                              <option value="gallon">gallon</option>
                              <option value="gram">gram</option>
                              <option value="oz">oz</option>
                              <option value="kg">kg</option>
                              <option value="tbsp">tbsp</option>
                            </select>
                          </td>
                          <td>
                            <Input
                              type="number"
                              value={ingredient.quantity_used || 0}
                              style={{ width: "75px" }}
                              onChange={(e) =>
                                handleIngredientDetailsChange(
                                  index,
                                  "quantity_used",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() =>
                                handleDeleteIngredientFromRecipe(index)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <div className="d-flex align-items-center">
                              <Label
                                className="mr-2"
                                for={`ingredient-price-${index}`}
                              >
                                Ingredient Cost:
                              </Label>
                              <Input
                                type="number"
                                id={`ingredient-price-${index}`}
                                value={ingredient.price || 0}
                                style={{
                                  width: "100px",
                                  marginTop: "10px",
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                                onChange={(e) =>
                                  handleIngredientDetailsChange(
                                    index,
                                    "price",
                                    parseFloat(e.target.value)
                                  )
                                }
                                placeholder="Price"
                              />
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                id={`Tooltip-${index}`}
                              />
                            </div>
                          </td>
                        </tr>
                        {/* Tooltip for the info icon next to the Ingredient Cost */}
                        <Tooltip
                          placement="right"
                          isOpen={tooltipOpen[index]}
                          target={`Tooltip-${index}`}
                          toggle={() => toggleTooltip(index)}
                          popperClassName="custom-tooltip"
                        >
                          {/* Apply color: white to this div */}
                          <div style={{ color: "white" }}>
                            <strong>Ingredient Costs:</strong> Enter the cost
                            corresponding to the quantity of the ingredient used
                            in this recipe. This should reflect the partial cost
                            derived from the ingredient's usage, not the cost of
                            the ingredient's entire unit or weight. It ensures
                            precise tracking of the recipe's expenses.
                            <br />
                            <br />
                            <strong>Total Grams:</strong> We determine the
                            recipe's total ingredient weight in grams for a
                            consistent cost-per-gram calculation.
                            <br />
                            <br />
                            <strong>Estimated Recipe Cost:</strong> Total and
                            per-gram costs of the recipe are saved, offering
                            insights into cost efficiency and aiding in pricing
                            decisions. You can adjust on RECIPES menu to better
                            reflect your costs.
                          </div>
                        </Tooltip>
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
              <ModalBody>
                <FormGroup>
                  {/* Store Dropdown */}
                  <Label for="store">Store Creating Recipe</Label>
                  <Input
                    type="select"
                    name="store"
                    id="store"
                    value={recipe.store}
                    onChange={(e) =>
                      setRecipe({ ...recipe, store: parseInt(e.target.value) })
                    }
                  >
                    <option value="">Select a store</option>
                    {stores.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="recipeName">Recipe Name</Label>
                  <Input
                    type="text"
                    name="recipeName"
                    id="recipeName"
                    placeholder="Enter recipe name"
                    value={recipe.name}
                    onChange={handleRecipeNameChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder="Enter description"
                    value={recipe.description}
                    onChange={(e) =>
                      setRecipe({ ...recipe, description: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="number_of_servings">Number of Servings</Label>
                  <Input
                    type="number"
                    name="number_of_servings"
                    id="number_of_servings"
                    placeholder="Enter number of servings"
                    value={recipe.number_of_servings}
                    onChange={(e) =>
                      setRecipe({
                        ...recipe,
                        number_of_servings: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  {/* Tags */}
                  <Label for="tags">Tags</Label>
                  <div className="tags-dropdown">
                    <Input
                      type="select"
                      name="tags"
                      id="tags"
                      value={tagInput}
                      onChange={handleTagSelectChange}
                    >
                      <option value="">Select a tag</option>
                      {tagSuggestions.map((tag, index) => (
                        <option key={index} value={tag}>
                          {tag}
                        </option>
                      ))}
                    </Input>
                    <Row>
                      <Col md="auto">
                        {" "}
                        {/* "md" can be adjusted to "sm", "lg", etc., based on your responsive design needs */}
                        <Button
                          color="secondary"
                          onClick={toggleTagModal}
                          className="add-tag-button"
                          style={{
                            maxWidth: "200px",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          Create New Tag
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {/* Display selected tags as badges */}
                  <div className="selected-tags">
                    {selectedTags.map((tag, index) => (
                      <span
                        key={index}
                        className="tag-badge"
                        style={{
                          backgroundColor: tagMap[tag]?.color || "defaultColor",
                        }}
                      >
                        {tag}
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="remove-tag"
                          onClick={() => handleRemoveTag(tag)}
                        />
                      </span>
                    ))}
                  </div>
                </FormGroup>
                {/* Modal for creating a new tag */}
                <Modal isOpen={isTagModalOpen} toggle={toggleTagModal}>
                  <ModalHeader toggle={toggleTagModal}>
                    Create New Tag
                  </ModalHeader>
                  <ModalBody>
                    <Input
                      type="text"
                      placeholder="Enter new tag name"
                      value={newTagName}
                      onChange={(e) => setNewTagName(e.target.value)}
                    />
                    <br />
                    <Label for="tagColor">Select Tag Color</Label>
                    <Input
                      type="color"
                      id="tagColor"
                      value={tagColor}
                      onChange={(e) => setTagColor(e.target.value)}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handleCreateNewTag}>
                      Create Tag
                    </Button>
                    <Button color="secondary" onClick={toggleTagModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                {/* Ingredients Added to Recipe */}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={createRecipe}>
                  Create Recipe
                </Button>
              </ModalFooter>
              {/* Ingredeint Detail Modal */}
              <Modal
                isOpen={isDetailModalOpen}
                toggle={() => setIsDetailModalOpen(false)}
                className="ingredient-detail-modal" // Add this class if you want to apply specific styles to the modal
              >
                <ModalHeader toggle={() => setIsDetailModalOpen(false)}>
                  Ingredient Details
                </ModalHeader>
                <ModalBody>
                  {selectedIngredientDetail && (
                    <div>
                      {/* Ingredient Description */}
                      <h3 className="ingredient-description">
                        {selectedIngredientDetail.ingredient_description}
                      </h3>
                      <div className="equal-height">
                        <div className="equal-height-col"></div>
                      </div>
                      {/* Nutrients List */}
                      <div className="table-separator"></div>{" "}
                      {/* Separates the nutrients section visually */}
                      <strong>Nutrients:</strong>
                      <ul className="ingredient-nutrient-list">
                        {selectedIngredientDetail.nutrients.map((nutrient) => (
                          <li key={nutrient.id} className="nutrient-detail">
                            <span className="nutrient-name">
                              {nutrient.name}:
                            </span>{" "}
                            {nutrient.amount} {nutrient.unit_name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </ModalBody>
              </Modal>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Tables;
