import React, { useEffect, forwardRef, useState } from 'react';
import JsBarcode from 'jsbarcode';

const BarcodeGenerator = forwardRef(({ value }, ref) => {
  useEffect(() => {
    try {
      if (value && ref.current) {
        JsBarcode(ref.current, value, {
          format: "CODE128",
          lineColor: "#000",
          width: 2,
          height: 40,
          displayValue: true
        });
      }
    } catch (error) {
      console.error("Failed to generate barcode:", error);
    }
  }, [value, ref]);
  

  // Function to convert SVG to PNG and trigger download
  const downloadBarcode = () => {
    const svg = ref.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "barcode.png";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
  };

  // Function to print the barcode
  const printBarcode = () => {
    const svg = ref.current;
    const svgData = new XMLSerializer().serializeToString(svg);
    const style = "<style>svg { width: 100%; height: auto; }</style>";
    const win = window.open('', '_blank');
    win.document.open();
    win.document.write('<html><body>' + style + '<svg xmlns="http://www.w3.org/2000/svg">' + svgData + '</svg></body></html>');
    win.document.close();
    win.focus();
    win.print();
    win.close();
  };

  return (
    <div className="barcode-container">
      <svg ref={ref}></svg>
      <div className="barcode-actions">
        <button onClick={downloadBarcode} className="barcode-button">Download</button>
        <button onClick={printBarcode} className="barcode-button">Print</button>
      </div>
    </div>
  );
})

export default BarcodeGenerator;
