import React, { useState, useEffect } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  FormText,
} from "reactstrap";
import CreateSupplierModal from "./CreateSupplierModal"; // Adjust the import path as necessary
import CreateStoreModal from "./CreateStoreModal"; // Adjust the import path as necessary
import "../../assets/css/OnboardingPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequestNotificationPermission from "./RequestNotificationPermission"; // Import your notification permission modal

import {
  faRocket,
  faHeart,
  faLeaf,
  faSeedling,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import TenantIngredientModal from "./TenantIngredientModal"; // Adjust the import path as necessary

function getCsrfToken() {
  const name = "csrftoken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }
  return "";
}

const OnboardingPopup = () => {
  const [onboardingStep, setOnboardingStep] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [ingredientModalOpen, setIngredientModalOpen] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState([]); // Added state for selected ingredients
  const [runTour, setRunTour] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false); // State for notification modal


  const [joyrideSteps, setJoyrideSteps] = useState([
    {
      target: '.nav-link[href="/admin/ingredients"]',
      content: 'Here you can manage all your ingredients. Create custom ones or add from our database. Also add ingredients to store inventroy. You have to add to store inventroy to cook batches.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/tables"]',
      content: 'Create new recipes using your ingredients. These will be the base for your products.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/recipes"]',
      content: 'View and manage your existing recipes. Here you will enter quantities, print nutrition facts labels and costs.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/products"]',
      content: 'All your products are managed here. You can add packing costs and use barcodes.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/supplies"]',
      content: 'Manage your supplies here. Also add to store inventroy to keep track of them.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/store-inventory"]',
      content: 'This is your central hub. Manage inventroy, recall ingredients and products with one click. Each time you cook a batch products and supplies are deducted here automatically.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/batches"]',
      content: 'Track and manage your production batches. All the ingredients and supplies used on a batch are deducted from your store inventroy. Also the quantities of products on the batch will be added to the store inventroy.',
      placement: 'right'
    },
    {
      target: '.nav-link[href="/admin/recall"]',
      content: 'Manage product-batch-ingredient recalls and ensure compliance. All recalls will be tracked here. You have the full trace from ingredients-products-batches. Print reports and donwload the data on excel.',
      placement: 'right'
    }
    // Add more steps as needed
  ]);


  // Joyride callback
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    console.log(`Joyride status: ${status}`); // Debugging statement
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setNotificationModalOpen(true); // Trigger the notification modal
    }
  };



  const [stores, setStores] = useState([]);
  const [profile, setProfile] = useState({
    search_keywords: "",
    interest_description: "",
    onboarding: false,
  });

  const entityStyle = {
    padding: "10px",
    margin: "5px",
    display: "inline-block",
    backgroundColor: "#f0f0f0", // Light grey background, adjust as needed
    borderRadius: "8px",
    color: "#fff", // Text color, adjust as needed
  };

  const baseStyle = {
    padding: "10px",
    margin: "5px",
    display: "inline-block",
    borderRadius: "8px",
    color: "#fff", // White text color for better contrast
  };

  const ingredientsStyle = { ...baseStyle, backgroundColor: "red" };
  const recipesStyle = { ...baseStyle, backgroundColor: "blue" };
  const productsStyle = {
    ...baseStyle,
    backgroundColor: "yellow",
    color: "#333",
  }; // Adjusted text color for visibility on yellow
  const batchesStyle = { ...baseStyle, backgroundColor: "green" };

  const iconStyle = {
    marginRight: "5px",
  };


  useEffect(() => {
    if (!ingredientModalOpen && onboardingStep >= 5) {
      setTimeout(() => {
        setRunTour(true);
      }, 500); // Adjust delay as needed, 500ms is just an example
    }
  }, [ingredientModalOpen, onboardingStep]);
  


  useEffect(() => {
    fetchSuppliers(); // Fetch suppliers on component mount
  }, []);

  const fetchSuppliers = async () => {
    // Define your API call here to fetch suppliers and update state
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      setSuppliers(response.data); // Assuming the response data is the list of suppliers
    } catch (error) {
      console.error("Error fetching suppliers:", error);
    }
  };

  // Include fetchSuppliers as a dependency to re-fetch suppliers when needed
  useEffect(() => {
    if (!supplierModalOpen) {
      fetchSuppliers(); // Re-fetch suppliers when the supplier modal is closed
    }
  }, [supplierModalOpen]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/users/api/your-profile-endpoint`,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCsrfToken(),
            },
          }
        );
        setProfile(response.data);
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
      }
    };

    fetchProfile();
  }, []);

  // Effect to toggle modal based on onboarding completion status
  useEffect(() => {
    // Ensure profile is not null and then check on_boarding_completed
    if (profile !== null) {
      setIsOpen(!profile.on_boarding_completed);
    }
  }, [profile]); // Depend on profile

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const saveOnboardingInfo = async () => {
    const csrfToken = getCsrfToken();
    const payload = {
      search_keywords: profile.search_keywords,
      interest_description: profile.interest_description,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/saveOnboardingInfo`,
        payload,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
      setOnboardingStep(onboardingStep + 1); // Proceed to next step after saving
    } catch (error) {
      console.error("Error saving onboarding information:", error);
    }
  };

  const handleNextStep = () => {
    switch (onboardingStep) {
      case 2:
        // After creating suppliers, ensure they are fetched before moving to store creation
        fetchSuppliers().then(() => {
          setOnboardingStep(3); // Move to the store creation step
        });
        break;

      case 4:
        // Call saveOnboardingInfo when "Next" is pressed on step 4
        saveOnboardingInfo()
          .then(() => {
            // After saving onboarding info, close the onboarding modal and open the ingredient selection modal
            setIsOpen(false); // Close the onboarding modal
            setIngredientModalOpen(true); // Open the ingredient selection modal
          })
          .catch((error) => {
            console.error("Error saving onboarding information:", error);
            // Handle error, possibly re-enable the Next button or show a message
          });
        break;

      default:
        setOnboardingStep((prevStep) => prevStep + 1); // Default behavior to increment step
        break;
    }
  };

  const refreshStores = () => {
    // Implement the logic to fetch stores or update state here
    // For example:
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/stores`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
      })
      .then((response) => {
        // Assuming you have a state variable to hold stores
        setStores(response.data);
      })
      .catch((error) => console.error("Error fetching stores:", error));
  };

  const renderStepContent = () => {
    switch (onboardingStep) {
      case 1:
        return (
          <>
            <p>
              <strong>
                Let's get started! Complete the following background information
                to set up your business. We will use this information to better
                customize notification for FDA and autorithy recalls. Start with
                keywords separated by space and then write a description of your
                business.
              </strong>
            </p>
            <p>
              <strong>
                For example: "cupcakes" or "apple pie" on keywords. The
                description of your business will also be used to infer relevant
                information to match recalls by the FDA.
              </strong>
            </p>
            <FormGroup>
              <label htmlFor="search_keywords">Keywords</label>
              <FormText>Enter search keywords.</FormText>
              <Input
                type="text"
                name="search_keywords"
                id="search_keywords"
                value={profile.search_keywords}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="interest_description">
                Describe your business
              </label>
              <FormText>Enter more keywords</FormText>
              <Input
                type="textarea"
                name="interest_description"
                id="interest_description"
                value={profile.interest_description}
                onChange={handleInputChange}
              />
            </FormGroup>
            <Button color="primary" onClick={saveOnboardingInfo}>
              Save and Continue
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <p>
              Let's start the initial setup creating suppliers, you can create
              one for now or as many as you want. You can always create more
              suppliers on "Settings" at the sidebar.
            </p>
            <Button color="primary" onClick={() => setSupplierModalOpen(true)}>
              Create Supplier
            </Button>
            <Button
              color="secondary"
              className="ml-2"
              onClick={() => setOnboardingStep(onboardingStep - 1)}
            >
              Previous
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <p>
              Now we will create a store, you can create as many as you want,
              also select the supplier created on the previous step. You can
              always create more stores on "Settings" at the sidebar.
            </p>
            <Button color="primary" onClick={() => setStoreModalOpen(true)}>
              Create Store
            </Button>
            <Button
              color="secondary"
              className="ml-2"
              onClick={() => setOnboardingStep(onboardingStep - 1)}
            >
              Previous
            </Button>
          </>
        );
      case 4:
        return (
          <>
            <p>
              Welcome to Food Recall Tracker! Here are some general instructions
              to get you started.
            </p>
            <p>Food Recall Tracker works around this flow:</p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <div style={ingredientsStyle}>
                <FontAwesomeIcon icon={faLeaf} style={iconStyle} />
                Ingredients
              </div>
              <div style={recipesStyle}>
                <FontAwesomeIcon icon={faHeart} style={iconStyle} />
                Recipes
              </div>
              <div style={productsStyle}>
                <FontAwesomeIcon icon={faRocket} style={iconStyle} />
                Products
              </div>
              <div style={batchesStyle}>
                <FontAwesomeIcon icon={faSeedling} style={iconStyle} />
                Batches
              </div>
            </div>
            <p>
              Use ingredients to create recipes, use recipes to create products,
              use the products to create batches. With that, you now have access
              to the full chain of production. Recall an ingredient and all
              related products and batches are automatically recalled. Yes, just
              like that!
            </p>
            <p>
              You can always create suppliers, stores, and employees in the
              settings. The next step is to select your ingredients which is
              crucial for setting up your inventory.
            </p>
            <p style={{ fontWeight: "bold" }}>
              Next, you can select ingredients from our database to add to your
              list. You can always go to the INGREDIENTS tab to select more or
              create custom ones. Next steps add ingredients and supplies to
              your store, then create recipes and after that batches. You are
              done!!
            </p>
            <Button
              color="secondary"
              onClick={() => setOnboardingStep(onboardingStep - 1)}
            >
              Previous
            </Button>
            <Button color="primary" onClick={handleNextStep}>
              Next
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        className="onboarding-modal"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader>Welcome to Food Recall Tracker!</ModalHeader>
        <ModalBody>
          {renderStepContent()}
          {onboardingStep < 4 && (
            <Button
              color="secondary"
              onClick={() => setOnboardingStep(onboardingStep + 1)}
            >
              Next
            </Button>
          )}
        </ModalBody>
      </Modal>
  
      <CreateSupplierModal
        isOpen={supplierModalOpen}
        toggle={() => setSupplierModalOpen(!supplierModalOpen)}
        refreshSuppliers={fetchSuppliers}
      />
  
      <CreateStoreModal
        isOpen={storeModalOpen}
        toggle={() => setStoreModalOpen(!storeModalOpen)}
        refreshStores={refreshStores}
      />
  
      <TenantIngredientModal
        isOpen={ingredientModalOpen}
        toggle={() => setIngredientModalOpen(!ingredientModalOpen)}
        onIngredientsSubmitted={() => {
          // Actions after ingredients are successfully submitted
          setOnboardingStep(5); // Move to next step after submission
        }}
      />
  
  
      {/* Place the Joyride component here */}
      <Joyride
        continuous={true}
        run={runTour}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={joyrideSteps}
        styles={{ options: { zIndex: 10000 } }}
        callback={handleJoyrideCallback}
      />
      {notificationModalOpen && (
        <Modal isOpen={true} toggle={() => setNotificationModalOpen(false)}>
          <ModalHeader>Notification Permission</ModalHeader>
          <ModalBody>
            <RequestNotificationPermission onClose={() => setNotificationModalOpen(false)} />
          </ModalBody>
        </Modal>
)}

    </>
  );
};

export default OnboardingPopup;
