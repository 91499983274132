import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const CreateSupplierModal = ({
  isOpen,
  toggle,
  refreshSuppliers,
  supplierToEdit,
  organizationId,
}) => {
  const isEditMode = !!supplierToEdit; // Determine if in edit mode based on supplierToEdit prop
  const [isSubmitting, setIsSubmitting] = useState(false);

  const countryOptions = [
    { value: "", label: "Select a country" },
    { value: "United States", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "Mexico", label: "Mexico" },
    { value: "Argentina", label: "Argentina" },
    { value: "Brazil", label: "Brazil" },
    { value: "Colombia", label: "Colombia" },
    { value: "Peru", label: "Peru" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Chile", label: "Chile" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Guyana", label: "Guyana" },
    { value: "Suriname", label: "Suriname" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Barbados", label: "Barbados" },
    { value: "Haiti", label: "Haiti" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
    },
    { value: "Grenada", label: "Grenada" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Dominica", label: "Dominica" },
    {
      value: "Saint Vincent and the Grenadines",
      label: "Saint Vincent and the Grenadines",
    },
    { value: "Belize", label: "Belize" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Honduras", label: "Honduras" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Panama", label: "Panama" },
    { value: "Cuba", label: "Cuba" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Martinique", label: "Martinique" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Aruba", label: "Aruba" },
    { value: "Curacao", label: "Curacao" },
    { value: "Bonaire", label: "Bonaire" },
    { value: "Sint Eustatius", label: "Sint Eustatius" },
    { value: "Saba", label: "Saba" },
    { value: "Saint Barthelemy", label: "Saint Barthelemy" },
    { value: "Saint Martin", label: "Saint Martin" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "British Virgin Islands", label: "British Virgin Islands" },
    {
      value: "United States Virgin Islands",
      label: "United States Virgin Islands",
    },
    { value: "Greenland", label: "Greenland" },
    { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Iceland", label: "Iceland" },
    { value: "Norway", label: "Norway" },
    { value: "Sweden", label: "Sweden" },
    { value: "Finland", label: "Finland" },
    { value: "Denmark", label: "Denmark" },
    { value: "Estonia", label: "Estonia" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Belarus", label: "Belarus" },
    { value: "Russia", label: "Russia" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "Moldova", label: "Moldova" },
    { value: "Poland", label: "Poland" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Hungary", label: "Hungary" },
    { value: "Romania", label: "Romania" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Macedonia", label: "Macedonia" },
    { value: "Greece", label: "Greece" },
    { value: "Albania", label: "Albania" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Kosovo", label: "Kosovo" },
    { value: "Serbia", label: "Serbia" },
    { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { value: "Croatia", label: "Croatia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Italy", label: "Italy" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Austria", label: "Austria" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Germany", label: "Germany" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Belgium", label: "Belgium" },
    { value: "France", label: "France" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Ireland", label: "Ireland" },
    { value: "Spain", label: "Spain" },
    { value: "Portugal", label: "Portugal" },
    { value: "Andorra", label: "Andorra" },
    { value: "Monaco", label: "Monaco" },
    { value: "Vatican City", label: "Vatican City" },
  ];

  const stateOptions = [
    { value: "", label: "Select a state" },
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
  ];

  const [formData, setFormData] = useState({
    name: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone: "",
    email: "",
    notes: "",
    organization: organizationId,
  });

  useEffect(() => {
    if (isOpen) {
      const data =
        isEditMode && supplierToEdit
          ? supplierToEdit
          : {
              name: "",
              street_address: "",
              city: "",
              state: "",
              zip_code: "",
              country: "",
              phone: "",
              email: "",
              notes: "",
              organization: organizationId,
            };
      setFormData(data);
    }
  }, [isOpen, supplierToEdit, organizationId, isEditMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";").map((cookie) => cookie.trim());
    const token = ca.find((cookie) => cookie.startsWith(name));
    return token ? token.substring(name.length) : "";
  }

  const handleSubmit = async () => {
    if (isSubmitting) return; // Prevent duplicate submissions

    setIsSubmitting(true); // Indicate submission is in progress

    const csrfToken = getCsrfToken(); // Your existing CSRF token logic
    const url = supplierToEdit
      ? `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/${supplierToEdit.id}/edit/`
      : `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers-create/`;
    const method = supplierToEdit ? "put" : "post";
    const dataToSend = { ...formData };

    try {
      await axios({
        method,
        url,
        data: dataToSend,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      });
      toast.success(
        `Supplier ${supplierToEdit ? "updated" : "created"} successfully!`
      );
      if (refreshSuppliers) {
        refreshSuppliers(); // Call refreshSuppliers to update the list
      }
      toggle(); // Close the modal
    } catch (error) {
      console.error(
        `Error ${supplierToEdit ? "updating" : "creating"} supplier:`,
        error
      );
      toast.error(
        `Failed to ${supplierToEdit ? "update" : "create"} supplier. ${
          error.message
        }`
      );
    } finally {
      setIsSubmitting(false); // Reset submission status regardless of outcome
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {isEditMode ? "Edit Supplier" : "Create Supplier"}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="street_address">Street Address</Label>
          <Input
            type="text"
            name="street_address"
            id="street_address"
            value={formData.street_address}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="city">City</Label>
          <Input
            type="text"
            name="city"
            id="city"
            value={formData.city}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="state">State</Label>
          <Select
            id="state"
            name="state"
            value={{ value: formData.state, label: formData.state }}
            onChange={(selectedOption) =>
              handleChange({
                target: { name: "state", value: selectedOption.value },
              })
            }
            options={stateOptions}
          />
        </FormGroup>

        <FormGroup>
          <Label for="zip_code">Zip Code</Label>
          <Input
            type="text"
            name="zip_code"
            id="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="country">Country</Label>
          <Select
            id="country"
            name="country"
            value={{ value: formData.country, label: formData.country }}
            onChange={(selectedOption) =>
              handleChange({
                target: { name: "country", value: selectedOption.value },
              })
            }
            options={countryOptions}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="notes">Notes</Label>
          <Input
            type="textarea"
            name="notes"
            id="notes"
            value={formData.notes}
            onChange={handleChange}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Processing..." : isEditMode ? "Update" : "Create"}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateSupplierModal;
