import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalFooter, // Add this for ModalFooter
  ModalBody,
  Label,
  FormGroup,
  Input,
  Button,
  Alert,
  Table,
  Tooltip,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import "../../assets/css/prodcut_inventroy.css";
import "../../assets/css/recipe_list.css";
import Select from "react-select";
import { toast } from "react-toastify";
import BarcodeGenerator from "./BarcodeGenerator";
import { faBarcode, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import axios from "axios"; // Import Axios for making HTTP requests
import { faPlus, faWarehouse, faEdit } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "./LoadingSpinner"; // Adjust the path as necessary

const ProductInventory = () => {
  const [activeTab, setActiveTab] = useState("generalInfo");
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddToInventoryModalOpen, setIsAddToInventoryModalOpen] =
    useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const productsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [selectedProductDetail, setSelectedProductDetail] = useState({});
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showRecallSuccess, setShowRecallSuccess] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [recipes, setRecipes] = useState([]); // State to hold recipes
  const [selectedRecipe, setSelectedRecipe] = useState(null); // State to hold selected recipe
  const [selectedRecipeId, setSelectedRecipeId] = useState("");
  const [barcodeModal, setBarcodeModal] = useState(false);
  const [barcodeBase64, setBarcodeBase64] = useState("");
  const [barcodeGenerated, setBarcodeGenerated] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [selectedRecipeDetails, setSelectedRecipeDetails] = useState(null);
  const [estimatedTotalCost, setEstimatedTotalCost] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const [newProduct, setNewProduct] = useState({
    store: "",
    product: { id: "" },
    date_created: "",
    recipe: "",
    name: "",
    date_updated: "",
    price: "",
    product_packaging_cost: "",
    weight: "",
    is_active: true,
    recall_date: "",
    is_flagged: "",
    clients: "",
    end_date: "",
    notes: "",
    is_recalled: false,
    recall_date: "",
    updated_by: "",
    created_by: "",
  });

  useEffect(() => {
    fetchSuppliers();
    fetchStores();
    // Other fetch calls...
  }, []);

  // Handler for when a new recipe is selected
  const handleRecipeSelectionChange = (e) => {
    setSelectedRecipeId(e.target.value);
    // Assuming you want to update the selectedProductDetail object as well
    setSelectedProductDetail({
      ...selectedProductDetail,
      recipe: e.target.value,
    });
  };

  // Hasndle checkbox changes.
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    // Assuming selectedProductDetail is the state variable holding product info
    setSelectedProductDetail((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const renderTabContent = () => {
    // Assuming relatedStores is defined outside of this function, similar to your original setup

    const handleStoreChange = (selectedOptions) => {
      setSelectedStores(selectedOptions); // Update your state as needed
    };

    switch (activeTab) {
      case "generalInfo":
        return (
          <>
            {isEditMode ? (
              <>
                {/* Example for editable name field */}
                <FormGroup>
                  <Label for="recipeSelect">Recipe</Label>
                  <Input
                    type="select"
                    name="recipe"
                    id="recipeSelect"
                    value={selectedProductDetail.recipe || ""}
                    onChange={(e) => {
                      handleEditInputChange(e);
                      // Fetch recipe details when a new recipe is selected
                      fetchRecipeDetails(e.target.value);
                    }}
                  >
                    <option value="">Select a Recipe</option>
                    {recipes.map((recipe) => (
                      <option key={recipe.id} value={recipe.id}>
                        {recipe.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="productName">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="productName"
                    value={selectedProductDetail.name}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="productName">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="productdescription"
                    value={selectedProductDetail.description}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="productName">Internal Code</Label>
                  <Input
                    type="text"
                    name="internal_code"
                    id="productinternal_code"
                    value={selectedProductDetail.internal_code}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="productPrice">Price</Label>
                  <Input
                    type="number"
                    name="price"
                    id="productPrice"
                    value={selectedProductDetail.price}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="productPackingCost">Packing Cost</Label>
                  <Input
                    type="number"
                    name="product_packaging_cost" // Ensure this matches the state key
                    id="productPackingCost"
                    value={selectedProductDetail.product_packaging_cost} // Use the correct state value
                    onChange={(e) => handleEditInputChange(e)} // Ensure this updates the state correctly
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="productWeight">Weight</Label>
                  <Input
                    type="number"
                    name="weight"
                    id="productWeight"
                    value={selectedProductDetail.weight}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
                <FormGroup style={{ display: "flex", alignItems: "center" }}>
                  <Label for="isRecalled" style={{ marginRight: "10px" }}>
                    Is Recalled
                  </Label>
                  <Input
                    type="checkbox"
                    name="is_recalled"
                    id="isRecalled"
                    checked={selectedProductDetail.is_recalled}
                    onChange={handleCheckboxChange}
                  />
                </FormGroup>
                <FormGroup style={{ display: "flex", alignItems: "center" }}>
                  <Label for="isActive" style={{ marginRight: "10px" }}>
                    Is Active
                  </Label>
                  <Input
                    type="checkbox"
                    name="is_active"
                    id="isActive"
                    checked={selectedProductDetail.is_active}
                    onChange={handleCheckboxChange}
                  />
                </FormGroup>
                <FormGroup style={{ display: "flex", alignItems: "center" }}>
                  <Label for="isFlagged" style={{ marginRight: "10px" }}>
                    Is Flagged
                  </Label>
                  <Input
                    type="checkbox"
                    name="is_flagged"
                    id="isFlagged"
                    checked={selectedProductDetail.is_flagged}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
                {/* Recall Date */}
                <Label for="recallDate">Recall Date</Label>
                <Input
                  type="date"
                  name="recall_date"
                  id="recallDate"
                  onChange={handleInputChange}
                  value={selectedProductDetail.recall_date}
                />
                {/* Include other editable fields as necessary */}
              </>
            ) : (
              <>
                <p>
                  <strong>Recipe:</strong>{" "}
                  {recipes.find(
                    (recipe) => recipe.id === selectedProductDetail.recipe
                  )?.name || "N/A"}
                </p>
                <p>
                  <strong>Description:</strong>{" "}
                  {selectedProductDetail.description}
                </p>
                <p>
                  <strong>Internal Code:</strong>{" "}
                  {selectedProductDetail.internal_code || "N/A"}
                </p>
                <p>
                  <strong>Estimated Production Cost:</strong>{" "}
                  {`$${estimatedTotalCost}`}
                </p>
                <p>
                  <strong>Price:</strong> ${selectedProductDetail.price}
                </p>
                <p>
                  <strong>Packing Cost:</strong> $
                  {selectedProductDetail.product_packaging_cost}
                </p>
                <p>
                  <strong>Weight:</strong> {selectedProductDetail.weight} kg
                </p>
                <p>
                  <strong>Is Active:</strong>{" "}
                  {selectedProductDetail.is_active ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Is Recalled:</strong>{" "}
                  {selectedProductDetail.is_recalled ? "Yes" : "No"}
                </p>
                <p>
                  <strong>Is Flagged:</strong>{" "}
                  {selectedProductDetail.recall_flagged ? "Yes" : "No"}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    id="FlagInfo" // This ID is used as the target for the Tooltip
                    className="ms-2"
                    style={{ cursor: "pointer" }}
                  />
                </p>
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target="FlagInfo"
                  toggle={toggleTooltip}
                >
                  Being flagged indicates a product that was part of a batch
                  marked as recalled. It does not mean that the product needs
                  recalling, but rather that it may need further inspection.
                </Tooltip>

                <p>
                  <strong>Recall date:</strong>{" "}
                  {selectedProductDetail.recall_date}
                </p>
                {/* Display fields in read-only mode */}
              </>
            )}
          </>
        );
      case "relatedInfo":
        return (
          <>
            {isEditMode ? (
              <>
                {/* Store multi-select input for edit mode */}
                <FormGroup>
                  <Label for="storeSelect">Stores</Label>
                  <Select
                    id="storeSelect"
                    name="stores"
                    options={stores.map((store) => ({
                      value: store.id,
                      label: store.name,
                    }))}
                    value={selectedStores}
                    onChange={handleStoreChange}
                    placeholder="Select Store"
                    classNamePrefix="select"
                    isMulti // Add this if you want to allow multiple selections
                  />
                </FormGroup>

                {/* Notes input field for edit mode */}
                <FormGroup>
                  <Label>Created By</Label>
                  <Input
                    type="text"
                    value={
                      selectedProductDetail.created_by_name || "Not available"
                    }
                    disabled
                  />
                </FormGroup>

                {/* Updated By */}
                <FormGroup>
                  <Label>Updated By</Label>
                  <Input
                    type="text"
                    value={
                      selectedProductDetail.updated_by_name || "Not available"
                    }
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="notes">Notes</Label>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    value={selectedProductDetail.notes}
                    onChange={(e) => handleEditInputChange(e)}
                  />
                </FormGroup>
              </>
            ) : (
              <>
                {/* Related Stores */}
                <h5>Related Stores:</h5>
                {selectedProductDetail.store.map((storeId) => {
                  const store = stores.find((s) => s.id === storeId);
                  return (
                    <p key={storeId}>{store ? store.name : "Unknown Store"}</p>
                  );
                })}

                {/* Notes */}
                <h5>Notes:</h5>
                <p>{selectedProductDetail.notes || "No notes provided."}</p>

                {/* Created By */}
                {selectedProductDetail.created_by_name && (
                  <p>
                    <strong>Created By:</strong>{" "}
                    {selectedProductDetail.created_by_name}
                  </p>
                )}

                {/* Updated By */}
                {selectedProductDetail.updated_by_name && (
                  <p>
                    <strong>Updated By:</strong>{" "}
                    {selectedProductDetail.updated_by_name}
                  </p>
                )}
              </>
            )}
          </>
        );

      // Include other cases as needed
      case "statusAndDates":
        return (
          <>
            {isEditMode ? (
              <>
                <FormGroup>
                  <Label for="dateCreated">Date Created</Label>
                  <Input
                    type="date"
                    id="dateCreated"
                    value={formatDate(selectedProductDetail.date_created)}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="dateUpdated">Date Updated</Label>
                  <Input
                    type="date"
                    id="dateUpdated"
                    value={formatDate(selectedProductDetail.date_updated)}
                    readOnly
                  />
                </FormGroup>
              </>
            ) : (
              <>
                <p>
                  <strong>Date Created:</strong>{" "}
                  {formatDate(selectedProductDetail.date_created)}
                </p>
                <p>
                  <strong>Date Updated:</strong>{" "}
                  {formatDate(selectedProductDetail.date_updated)}
                </p>
              </>
            )}
          </>
        );
      default:
        return <div>No content available</div>;
    }
  };

  const fetchStores = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      const storesData = response.data;
      setStores(storesData);

      if (storesData.length > 0) {
        setSelectedStore(storesData[0].id.toString()); // Set the default store to the first one
        fetchProducts(storesData[0].id.toString()); // Fetch products for the default store
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
      let errorMessage = "Error fetching stores.";

      if (error.response && error.response.data) {
        errorMessage += ` Details: ${
          error.response.data.error ||
          error.response.data.message ||
          JSON.stringify(error.response.data)
        }`;
      } else if (error.message) {
        errorMessage += ` Additional info: ${error.message}`;
      }

      toast.error(errorMessage);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Fetch recipes
  const fetchRecipes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipes-list/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      setRecipes(response.data);
    } catch (error) {
      console.error("Error fetching recipes:", error);
      let errorMessage = "Error fetching recipes.";

      if (error.response && error.response.data) {
        // Extract error message
        errorMessage += ` Details: ${
          error.response.data.error ||
          error.response.data.message ||
          JSON.stringify(error.response.data)
        }`;
      } else if (error.message) {
        // Use a generic error message
        errorMessage += ` Additional info: ${error.message}`;
      }

      toast.error(errorMessage);
    }
  };

  // Function to fetch and set selected recipe details
  const fetchRecipeDetails = async (recipeId) => {
    try {
      const csrfToken = getCsrfToken(); // Get the CSRF token
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipes-detail/${recipeId}/`,
        {
          withCredentials: true, // Include credentials for cookies
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken, // Include the CSRF token in the request
          },
        }
      );

      const recipeDetails = response.data;
      setSelectedRecipeDetails(recipeDetails); // Store the fetched recipe details in state

      // Calculate the estimated total cost
      const recipeCosts =
        parseFloat(recipeDetails.labor_cost) +
        parseFloat(recipeDetails.overhead_cost) +
        parseFloat(recipeDetails.total_cost);
      const totalCost =
        recipeCosts + parseFloat(newProduct.product_packaging_cost || 0);
      setEstimatedTotalCost(totalCost.toFixed(2)); // Update the estimated total cost, rounded to two decimal places
    } catch (error) {
      console.error("Error fetching recipe details:", error);
      // Handle the error, for example, by showing an error message to the user
    }
  };

  // Effect to update estimated total cost when selected recipe details or packing cost changes
  useEffect(() => {
    if (selectedRecipeDetails) {
      // Calculate the total cost from the recipe excluding the packing cost
      const recipeCosts =
        parseFloat(selectedRecipeDetails.labor_cost) +
        parseFloat(selectedRecipeDetails.overhead_cost) +
        parseFloat(selectedRecipeDetails.total_cost);
      // Use only the packing cost from the product
      const totalCost =
        recipeCosts + parseFloat(newProduct.product_packaging_cost || 0);
      setEstimatedTotalCost(totalCost.toFixed(2)); // Update the estimated total cost
    }
  }, [selectedRecipeDetails, newProduct.product_packaging_cost]);

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    fetchRecipes();
    // ... other fetch calls
  }, []);

  const handleRecipeChange = async (selectedOption) => {
    setSelectedRecipe(selectedOption);
    setNewProduct({ ...newProduct, recipe: selectedOption.value });

    // Fetch the recipe details for the selected recipe
    fetchRecipeDetails(selectedOption.value);
  };

  const handleStoreSelectChange = (selectedOption) => {
    setSelectedStore(selectedOption); // Keeping this for UI purposes
    setNewProduct((prevState) => ({
      ...prevState,
      store: selectedOption ? Number(selectedOption.value) : null, // Ensuring it's a number
    }));
  };

  const fetchSuppliers = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`, {
      credentials: "include", // Include credentials for cookies, especially for CSRF token
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => setSuppliers(data))
      .catch((error) => console.error("Error fetching suppliers:", error));
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const toggleModal = () => {
    if (!isModalOpen) {
      // Reset the state only when opening the modal
      setNewProduct({
        store: stores.length > 0 ? stores[0].id : "",
        product: { id: "" },
        date_created: "",
        recipe: "",
        name: "",
        date_updated: "",
        price: "",
        product_packaging_cost: "",
        weight: "",
        is_active: true, // Ensure this is set to true when opening the modal
        recall_date: "",
        is_flagged: false, // Set other checkboxes to their default values if needed
        clients: "",
        end_date: "",
        notes: "",
        is_recalled: false,
        updated_by: "",
        created_by: "",
      });
    }
    setIsModalOpen(!isModalOpen); // Toggle the modal visibility
  };

  const toggleAddToInventoryModal = () => {
    setIsAddToInventoryModalOpen(!isAddToInventoryModalOpen);
  };

  // useEffect hook to handle side effects when the Add to Inventory modal closes
  useEffect(() => {
    if (!isAddToInventoryModalOpen) {
      // Reset selected products when closing the Add to Inventory modal
      setSelectedProducts([]);
    }
  }, [isAddToInventoryModalOpen]); // Depend on isAddToInventoryModalOpen state

  // useEffect hook to handle side effects when the Create Ingredient modal closes
  useEffect(() => {
    if (!isModalOpen) {
      setIsSuccess(false);
      setNewProduct({
        store: { id: "" },
        product: { id: "" },
        // Reset to initial state or whatever you see fit
      });
    }
  }, [isModalOpen]); // Depend on isModalOpen state

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewProduct((prevState) => {
      // Update the state based on the input change.
      let updatedState = {
        ...prevState,
        [name]: value, // Initially, just set the value directly
      };

      // Specific handling for numeric values
      if (name === "product_packaging_cost") {
        // Parse as float for product_packaging_cost, ensuring a fallback to 0 if parsing fails
        updatedState[name] = parseFloat(value) || 0;
      } else if (name === "store") {
        // Parse as integer for store, ensuring a fallback to an empty string if parsing fails
        updatedState[name] = parseInt(value, 10) || "";
      }

      // Form validation logic (adjusted to apply universally and check product_packaging_cost correctly)
      const isFormNowValid =
        updatedState.name.trim() !== "" &&
        updatedState.price.trim() !== "" &&
        !isNaN(updatedState.product_packaging_cost); // Validate product_packaging_cost

      setIsFormValid(isFormNowValid);

      // Recalculate estimated total cost if applicable
      if (name === "product_packaging_cost" && selectedRecipeDetails) {
        const { labor_cost, overhead_cost, total_cost } = selectedRecipeDetails;
        const recipeCosts =
          parseFloat(labor_cost) +
          parseFloat(overhead_cost) +
          parseFloat(total_cost);
        const totalCost = recipeCosts + updatedState.product_packaging_cost; // Use updated packing cost
        setEstimatedTotalCost(totalCost.toFixed(2)); // Update estimated total cost
      }

      return updatedState;
    });
  };

  // Search Filter hook
  useEffect(() => {
    const filtered = products.filter((product) => {
      const descriptionMatch = product.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const internalCodeMatch =
        product.date_created &&
        product.date_created.toLowerCase().includes(searchText.toLowerCase());
      return descriptionMatch || internalCodeMatch;
    });
    setFilteredProducts(filtered);
  }, [products, searchText]);

  // Create Product
  const handleSubmit = () => {
    console.log("Submitting product data:", newProduct);

    // Check if all required fields are filled
    const requiredFields = ["name", "price", "weight"];
    const isAllFieldsFilled = requiredFields.every(
      (field) => newProduct[field]
    );

    if (!isAllFieldsFilled) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (!selectedStore) {
      toast.error("Please select a store.");
      return;
    }

    console.log("New Product State before submission:", newProduct);
    let preparedData = prepareProductData(newProduct, selectedStore);

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product/`,
        preparedData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      )
      .then((response) => {
        toast.success("Product successfully created!");
        toggleModal(); // Close the modal upon successful submission
        fetchProducts(); // Optionally refresh the product list if needed
        console.log("Product creation response:", response.data);
      })
      .catch((error) => {
        console.error("Error creating product:", error);
        let errorMessage = "Error creating product!"; // Default error message
        if (error.response && error.response.data) {
          // Construct a detailed error message including field names
          errorMessage = Object.entries(error.response.data)
            .map(([field, errors]) => `${field}: ${errors.join(", ")}`)
            .join("; ");
        }
        toast.error(errorMessage);
      });
  };

  // Adjusted prepareProductData function
  function prepareProductData(data, selectedStore) {
    let payload = {
      ...data,
      store: Number(data.store), // Convert store to a Number
      // Remove the product key as it seems to be not used/required for creation
      recipe: data.recipe ? Number(data.recipe) : null, // Ensure recipe is a number if present
      clients:
        typeof data.clients === "string" && data.clients !== ""
          ? [data.clients]
          : [], // Ensure clients is an array
      barcode: data.barcode || uuidv4(), // Use existing barcode or generate a new one
    };

    // Conditionally include recall_date if it's provided and valid
    if (data.recall_date && isValidDate(data.recall_date)) {
      payload.recall_date = formatDateForBackend(data.recall_date);
    } else {
      delete payload.recall_date; // Exclude recall_date if not valid or provided
    }

    delete payload.product; // Assuming product.id is not needed for new product creation

    console.log("Prepared product data for submission:", payload);
    return payload;
  }

  // Helper function to check if a date string is valid (simple YYYY-MM-DD format validation)
  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  }

  // Helper function to format dates for the backend
  function formatDateForBackend(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`, {
      credentials: "include", // Include credentials for cookies, especially for CSRF token
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(),
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setStores(json);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
        setIsLoading(false);
      });
    fetchProducts();
  }, []);

  const handleProductRemove = (productId) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((id) => id !== productId)
    );
  };

  const SelectedProductsList = ({ selectedProducts, products, onRemove }) => {
    return (
      <div className="selected-products">
        <h5>Selected Products:</h5>
        <div className="selected-products-grid">
          {selectedProducts.map((productId) => {
            const product = products.find((ing) => ing.id === productId);
            return (
              <div className="product-card" key={productId}>
                <div className="product-description">
                  {product ? product.name : "Unknown Ingredient"}
                </div>
                <button
                  className="remove-button"
                  onClick={() => onRemove(productId)}
                >
                  <i className="fas fa-trash-alt"></i>{" "}
                  {/* Font Awesome bin icon */}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const fetchProducts = (storeId = "") => {
    setIsLoading(true); // Start loading

    const storeIdParam = storeId.toString();
    const url = storeIdParam
      ? `${
          process.env.REACT_APP_API_BASE_URL
        }/core/api/product-list/?store_id=${encodeURIComponent(storeIdParam)}`
      : `${process.env.REACT_APP_API_BASE_URL}/core/api/product-list/`;

    console.log("Fetching products for Store ID:", storeIdParam);

    axios
      .get(url, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
      })
      .then((response) => {
        const products = response.data.results;
        setProducts(products);
        setTotalPages(Math.ceil(products.length / productsPerPage));
        setIsLoading(false); // End loading
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products.");
        setIsLoading(false); // End loading
      });
  };

  const storeOptions = [
    { value: "", label: "Show All" },
    ...stores.map((store) => ({
      value: store.id.toString(),
      label: store.name,
    })),
  ];

  // Filter Prodcuts by Store
  const handleStoreFilterChange = (e) => {
    const selectedStoreId = e.target.value;
    setSelectedStore(selectedStoreId);
    fetchProducts(selectedStoreId);
  };

  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    setSelectedStore(selectedStoreId);
    console.log("Selected Store ID:", selectedStoreId);
    fetchProducts(selectedStoreId);
  };

  const handleProductSelect = (e) => {
    const productId = parseInt(e.target.value, 10);
    setSelectedProducts((prev) =>
      e.target.checked
        ? [...prev, productId]
        : prev.filter((id) => id !== productId)
    );
  };

  const addSelectedProductsToInventory = () => {
    const csrfToken = getCsrfToken();

    // Iterate over each selected product and add them to inventory
    selectedProducts.forEach((productId) => {
      // Retrieve product details such as name and description
      const productDetails = getProductDetails(productId);
      console.log(`Product details for ID ${productId}:`, productDetails);

      const quantity = document.querySelector(
        `input[name='quantity-${productId}']`
      ).value;

      // Construct the data object for the POST request
      const inventoryData = {
        store: selectedStore, // Assuming this is the selected store ID
        product: productId,
        quantity: quantity,
        name: productDetails.name, // Include name from product details
        description: productDetails.description, // Include description from product details
      };

      console.log("Submitting new product to inventory:", inventoryData); // Logging the data being sent

      // POST request to the API endpoint
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-inventory/create/`,
        {
          method: "POST",
          credentials: "include", // Include credentials for cookies, especially for CSRF token
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(inventoryData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          toast.success("Product successfully added to inventory!");
          // Additional success handling
        })
        .catch((error) => {
          console.error("Error adding product to inventory:", error);
          toast.error("Error adding product to inventory");
        });
    });

    // Reset selected products and close modal after processing
    setSelectedProducts([]);
    toggleAddToInventoryModal(false);
  };

  const getProductDetails = (productId) => {
    const product = findIngredientById(productId); // Using the existing function
    if (!product) {
      console.error(`Product not found for ID: ${productId}`);
      toast.error("Product not found");
      return { name: "Unknown", description: "No description available" };
    }
    return product;
  };

  const findIngredientById = (id) => {
    return products.find((product) => product.id === id);
  };

  const handleDetailClick = async (productId) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/core/api/product-detail/${productId}/`;

    try {
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
      });

      const productDetails = response.data;
      setSelectedProductDetail(productDetails);
      setIsDetailModalOpen(true);

      // Set the selected recipe based on the product details
      const selectedRecipeOption = recipes.find(
        (recipe) => recipe.id === productDetails.recipe
      );
      setSelectedRecipe(
        selectedRecipeOption
          ? { value: selectedRecipeOption.id, label: selectedRecipeOption.name }
          : null
      );

      // Fetch the recipe details to calculate the estimated cost
      if (productDetails.recipe) {
        await fetchRecipeDetails(productDetails.recipe);
      }

      // Set the selected stores based on the product details
      const selectedStoreOptions = productDetails.store.map((storeId) => {
        const store = stores.find((store) => store.id === storeId);
        return { value: store.id, label: store.name };
      });
      setSelectedStores(selectedStoreOptions);
    } catch (error) {
      console.error("Error fetching product details:", error);
      toast.error("Error fetching product details");
    }
  };

  // Recall Product
  const recallProduct = async (productId) => {
    const csrfToken = getCsrfToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recall/product/${productId}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.data.status === "success") {
        setShowRecallSuccess(true);
        toast.success("Product successfully recalled!");
        // Optimistically update the product's is_recalled status in the state
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === productId
              ? { ...product, is_recalled: true }
              : product
          )
        );
      }
    } catch (error) {
      console.error("Error recalling product:", error);
      toast.error("Error recalling product!", error);
      // You may want to handle and display this error in the UI
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const displayIsRecalled = (isRecalled) => {
    if (isRecalled) {
      return <span style={{ fontWeight: "bold", color: "red" }}>Yes</span>;
    } else {
      return "No";
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);

    if (!isEditMode) {
      // If entering edit mode
      // Initialize `selectedStores` based on `selectedProductDetail`
      const initialSelectedStores = selectedProductDetail.stores.map(
        (storeId) => {
          const store = stores.find((store) => store.id === storeId);
          return { value: store.id, label: store.name };
        }
      );

      setSelectedStores(initialSelectedStores);
    }
  };

  // Detail Modal view/ Togle
  const toggleDetailModal = () => {
    setIsDetailModalOpen(!isDetailModalOpen);
    setIsEditMode(false); // Reset edit mode when toggling the modal

    // Reset selectedStores if necessary
    if (!isDetailModalOpen) {
      setSelectedStores([]); // This resets selectedStores when closing the modal
    }
  };

  const renderModalContent = () => {
    // Use selectedProductDetail from your component's state
    const relatedStores =
      selectedProductDetail.store?.map((storeId) =>
        stores.find((store) => store.id === storeId)
      ) || [];

    return (
      <div>
        <ul className="nav nav-tabs">
          <li className="nav-item" onClick={() => setActiveTab("generalInfo")}>
            <a
              className={`nav-link ${
                activeTab === "generalInfo" ? "active" : ""
              }`}
            >
              General Info
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => setActiveTab("statusAndDates")}
          >
            <a
              className={`nav-link ${
                activeTab === "statusAndDates" ? "active" : ""
              }`}
            >
              Status & Dates
            </a>
          </li>
          <li className="nav-item" onClick={() => setActiveTab("relatedInfo")}>
            <a
              className={`nav-link ${
                activeTab === "relatedInfo" ? "active" : ""
              }`}
            >
              Related Info
            </a>
          </li>
        </ul>
        <div className="tab-content mt-4">
          {renderTabContent(
            activeTab,
            selectedProductDetail,
            relatedStores,
            isEditMode
          )}
        </div>
      </div>
    );
  };

  // Update Prodcut
  const handleUpdate = async () => {
    const csrfToken = getCsrfToken();
    const storeIds = selectedStores.map((store) => store.value);

    let payload = {
      ...selectedProductDetail,
      store: storeIds,
      // Include other necessary fields from the form
    };

    if (selectedStores.length === 0) {
      toast.error("Please select at least one store.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product/${selectedProductDetail.id}/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        toast.success("Product updated successfully!");
        // Refresh the product list here
        fetchProducts(); // Assuming fetchProducts is your function to fetch the product list
        setIsDetailModalOpen(false); // Close the modal
      } else {
        // Handle non-successful responses
        toast.error("Failed to update product.");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Error updating product.");
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProductDetail({ ...selectedProductDetail, [name]: value });
  };

  // Create a function to handle multi-select changes
  const handleProductSelectChange = (selectedOptions) => {
    const selectedProductIds = selectedOptions.map((option) => option.value);
    setSelectedProducts(selectedProductIds);
  };

  // Create an options array for react-select from your products
  const productOptions = products.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  // Delete Product
  const handleDelete = (productId) => {
    // Confirmation dialog
    if (window.confirm("Are you sure you want to delete this product?")) {
      const csrfToken = getCsrfToken();

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product/${productId}/delete/`,
        {
          method: "DELETE",
          credentials: "include", // Include credentials for cookies, especially for CSRF token
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          // Remove the product from the state
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== productId)
          );
          toast.success("Product deleted successfully!");
        })
        .catch((error) => {
          console.error("Error deleting product:", error);
        });
    } else {
      // User cancelled the deletion
      console.log("Product deletion cancelled.");
    }
  };

  const handleGenerateBarcodeClick = (productId) => {
    // Find the product by its ID
    const product = products.find((product) => product.id === productId);
    if (product && product.barcode) {
      setBarcodeValue(product.barcode); // Set the barcode value to the product's barcode ID
      setBarcodeModal(true); // Open the modal to display the barcode
    } else {
      toast.error("Barcode not available for this product.");
    }
  };

  // Ref for the content you want to print or download as PDF
  const printRef = useRef();

  return (
    <>
      {/* Conditional rendering of LoadingSpinner */}
      {/* Success Message */}
      <div className="mt-3">{/* Success Message */}</div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* Search and Buttons Container */}
              <div
                className="d-flex align-items-center"
                style={{ padding: "10px" }}
              >
                {/* Buttons */}
                <div>
                  <Button
                    className="custom-button me-2"
                    onClick={toggleModal}
                    title="Create Product"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>

                {/* Store Filter Dropdown */}
                <FormGroup
                  className="me-2"
                  style={{ minWidth: "150px", margin: "0 20px" }}
                >
                  <Input
                    type="select"
                    name="storeFilter"
                    id="storeFilter"
                    onChange={handleStoreFilterChange}
                    value={selectedStore}
                  >
                    {stores.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                {/* Search Input */}
                <FormGroup className="flex-grow-1 mb-0">
                  <Input
                    type="text"
                    name="searchText"
                    id="searchText"
                    placeholder="Search by product name or code"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{
                      height: "40px",
                    }}
                  />
                </FormGroup>
              </div>

              {/* Selected Products List */}
              {selectedProducts.length > 0 && (
                <SelectedProductsList
                  selectedProducts={selectedProducts}
                  products={products}
                  onRemove={handleProductRemove}
                />
              )}

              {/* Product Table */}
              {isLoading ? (
                <LoadingSpinner /> // Shows spinner when loading
              ) : (
                <>
                  {filteredProducts.length > 0 ? (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Select</th>
                          <th scope="col" className="wide-column">
                            Description
                          </th>
                          <th>Is Recalled</th>
                          <th scope="col">Date Created</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProducts
                          .slice(
                            (currentPage - 1) * productsPerPage,
                            currentPage * productsPerPage
                          )
                          .map((product) => (
                            <tr key={product.id}>
                              <td>
                                <Input
                                  type="checkbox"
                                  value={product.id}
                                  onChange={handleProductSelect}
                                  checked={selectedProducts.includes(
                                    product.id
                                  )}
                                />
                              </td>
                              <td className="wide-column">{product.name}</td>
                              <td>{displayIsRecalled(product.is_recalled)}</td>
                              <td>{formatDate(product.date_created)}</td>
                              <td className="actions-cell">
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => handleDetailClick(product.id)}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    handleGenerateBarcodeClick(product.id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faBarcode} />
                                </Button>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => handleDelete(product.id)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    !isLoading && (
                      <div className="text-center p-5">
                        <h6></h6>
                      </div>
                    )
                  )}
                </>
              )}

              {/* Pagination Controls */}
              <div className="pagination">
                <Button
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  onClick={() =>
                    handlePageChange(Math.min(totalPages, currentPage + 1))
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
      {/* Modal for Product Details */}
      <Modal isOpen={isDetailModalOpen} toggle={toggleDetailModal}>
        <ModalHeader toggle={toggleDetailModal}>Product Details</ModalHeader>
        <ModalBody>{renderModalContent()}</ModalBody>
        <ModalFooter>
          {isEditMode && (
            <Button
              color="primary"
              onClick={handleUpdate} // Assuming this is the correct method to save changes
            >
              Save Changes
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => setIsEditMode(!isEditMode)}
            className="float-right"
            style={{ marginLeft: "20px" }}
          >
            {isEditMode ? "Cancel Edit" : "Edit"}
          </Button>
          <Button color="secondary" onClick={toggleDetailModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal for Creating/Viewing Product Details */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Product Details</ModalHeader>
        <ModalBody>
          {/* Recipe Selection */}
          <Label for="recipe">Recipe</Label>
          <Select
            name="recipe"
            id="recipe"
            options={recipes.map((recipe) => ({
              value: recipe.id,
              label: recipe.name,
            }))}
            value={selectedRecipe}
            onChange={handleRecipeChange}
            placeholder="Select Recipe"
            styles={{
              container: (base) => ({ ...base, marginBottom: "10px" }),
            }}
          />

          {/* Store Input */}
          <Label for="store">Store Creating Product</Label>
          <Select
            name="store"
            id="store"
            options={stores.map((store) => ({
              value: store.id,
              label: store.name,
            }))}
            value={selectedStore} // Directly use selectedStore here
            onChange={handleStoreSelectChange}
            placeholder="Select Store"
            styles={{
              container: (base) => ({ ...base, marginBottom: "10px" }),
            }}
          />

          {/* Product Input */}
          <FormGroup>
            {/* Name */}
            <Label for="productName" style={{ marginTop: "5px" }}>
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="name"
              id="productName"
              placeholder="Enter product name"
              onChange={handleInputChange}
              value={newProduct.name}
            />
            {/* Estimated Production Cost Display */}
            <FormGroup>
              <Label for="estimatedTotalCost">
                Estimated Production Cost
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  id="EstimatedCostInfo"
                  className="ms-2"
                  style={{
                    cursor: "pointer",
                    marginLeft: "8px",
                    marginTop: "15px",
                  }}
                />
              </Label>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen}
                target="EstimatedCostInfo"
                toggle={toggleTooltip}
              >
                Estimated production cost is calculated based on the sum of
                labor cost, overhead cost, total cost from the recipe, and the
                packing cost entered.
              </Tooltip>{" "}
              <Input
                type="text"
                name="estimatedTotalCost"
                id="estimatedTotalCost"
                value={`$${estimatedTotalCost}`} // Display the calculated total cost
                disabled // Make the field read-only
              />
            </FormGroup>
            {/* Packing Cost */}
            <Label for="productPackingCost" style={{ marginTop: "10px" }}>
              Packing Cost
            </Label>
            <input
              name="product_packaging_cost" // This should match your state object's key if it's meant to update `product_packaging_cost`.
              id="productPackingCost"
              type="number"
              className="form-control"
              value={newProduct.product_packaging_cost || ""}
              onChange={handleInputChange} // Ensure this handler is implemented.
            />
            {/* Price */}
            <Label for="productPrice" style={{ marginTop: "5px" }}>
              Price
            </Label>
            <Input
              type="number"
              name="price"
              id="productPrice"
              onChange={handleInputChange}
              value={newProduct.price}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label
                for="productWeight"
                style={{ marginTop: "5px", marginBottom: "0" }}
              >
                Weight
              </Label>
              <FontAwesomeIcon
                icon={faInfoCircle}
                id="ProdcutWeight"
                className="ms-2"
                style={{ cursor: "pointer", marginLeft: "8px" }}
              />
              <Tooltip
                placement="right"
                isOpen={tooltipOpen}
                target="ProdcutWeight"
                toggle={toggleTooltip}
              >
                Use on the units you calculate the weight in. No specific unit
                is required and no conversion is done.
              </Tooltip>
            </div>
            <Input
              type="number"
              name="weight"
              id="productWeight"
              onChange={handleInputChange}
              value={newProduct.weight}
            />

            {/* Description */}
            <Label for="productDescription" style={{ marginTop: "5px" }}>
              Description
            </Label>
            <Input
              type="textarea"
              name="description"
              id="productDescription"
              onChange={handleInputChange}
              value={newProduct.description}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={isEditMode ? handleUpdate : handleSubmit}
          >
            Save Product
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal for Adding Products to Inventory */}
      <Modal
        isOpen={isAddToInventoryModalOpen}
        toggle={toggleAddToInventoryModal}
      >
        <ModalHeader toggle={toggleAddToInventoryModal}>
          Add Selected Products to Store
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="selectStore">Select Store</Label>
            <Input
              type="select"
              name="selectStore"
              id="selectStore"
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option value="">Select Store</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          <Table bordered>
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((productId) => {
                const product = findIngredientById(productId);
                return (
                  <tr key={productId}>
                    <td>{product ? product.name : "Unknown Ingredient"}</td>
                    <td>
                      <Input
                        type="number"
                        name={`quantity-${productId}`}
                        placeholder="Quantity"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addSelectedProductsToInventory}>
            Add Products to Store Inventory
          </Button>
          <Button color="secondary" onClick={toggleAddToInventoryModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={barcodeModal} toggle={() => setBarcodeModal(false)}>
        <ModalHeader toggle={() => setBarcodeModal(false)}>Barcode</ModalHeader>
        <ModalBody>
          {barcodeValue && (
            <div className="barcode-container">
              <BarcodeGenerator ref={printRef} value={barcodeValue} />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handlePrint}>
            Print
          </Button>
          <Button color="secondary" onClick={() => setBarcodeModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProductInventory;
