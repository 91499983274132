import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "../../assets/css/TenantmodalStyles.css";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import axios from "axios";
import {
  Table,
  Button,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const TenantIngredientModal = ({ isOpen, toggle, onIngredientsSubmitted }) => {
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [dataTypeFilter, setDataTypeFilter] = useState("");

  useEffect(() => {
    if (isOpen) {
      fetchIngredients();
    }
  }, [isOpen, currentPage, debouncedSearchTerm, dataTypeFilter]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1); // Reset to first page on new search
      setHasMore(true); // Assume there may be more results for the new search
    }, 300);
  
    debouncedSearch();
  
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);
  

  const getCsrfToken = () => {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };


  const fetchIngredients = async () => {
  if (!hasMore) return;
  setIsLoading(true);

  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/food/api/add-ingredients/?search=${encodeURIComponent(
      debouncedSearchTerm
    )}&page=${currentPage}&data_type=${dataTypeFilter}`;
    const response = await fetch(url, {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRFToken": getCsrfToken(),
      },
    });
    if (!response.ok) throw new Error("Network response was not ok");
    const json = await response.json();

    // Replace the existing ingredients state with new results
    setIngredients(json.results);
    setHasMore(json.next !== null);
  } catch (error) {
    console.error("Fetching ingredients failed:", error);
  } finally {
    setIsLoading(false);
  }
};


  const handleCheckboxChange = (ingredientId) => {
    setSelectedIngredients((prev) =>
      prev.includes(ingredientId)
        ? prev.filter((id) => id !== ingredientId)
        : [...prev, ingredientId]
    );
  };

  const submitIngredients = async () => {
    const csrfToken = getCsrfToken();
    const url = `${process.env.REACT_APP_API_BASE_URL}/food/api/tenant/ingredients/search-add/`;
    try {
      const ingredientData = selectedIngredients.map((ingredientId) => ({
        ingredient_id: ingredientId,
      }));
      await Promise.all(
        ingredientData.map((data) =>
          axios.post(url, data, {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          })
        )
      );
      toast.success("Ingredients added successfully");
      setSelectedIngredients([]);
      setCurrentPage(1);
      setHasMore(true);
      toggle();
      if (onIngredientsSubmitted) {
        onIngredientsSubmitted();
      }
    } catch (error) {
      console.error("Error submitting ingredients:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggle}
      className="modal-custom"
      contentLabel="Add Ingredients"
    >
      <ModalHeader toggle={toggle} className="modal-header-custom">
        Add Ingredients
      </ModalHeader>
      <ModalBody className="modal-body-custom">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Input
              type="text"
              placeholder="Search ingredients..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <Input
              type="select"
              className="drop-down-foods"
              value={dataTypeFilter}
              onChange={(e) => setDataTypeFilter(e.target.value)}
            >
              <option value="">All Ingredients</option>
              <option value="sr_legacy_food">Legacy Foods</option>
              <option value="foundation_food">Foundation Foods</option>
            </Input>
            <div className="table-wrapper">
              <Table striped className="table-custom-striped">
                <tbody>
                  {ingredients.map((ingredient, index) => (
                    <tr
                      key={ingredient.id}
                      style={{
                        backgroundColor: index % 2 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td className="description-cell">
                        {ingredient.ingredient_description}
                      </td>
                      <td className="checkbox-cell">
                        <Input
                          type="checkbox"
                          id={`ingredient-${ingredient.id}`}
                          checked={selectedIngredients.includes(ingredient.id)}
                          onChange={() => handleCheckboxChange(ingredient.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="modal-footer-custom">
        <Button color="primary" onClick={submitIngredients}>
          Submit
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TenantIngredientModal;