import React, { useState, useEffect } from "react";
import "../../assets/css/recall_list.css";
import Header from "../../components/Headers/Header.js";
import LoadingSpinner from "./LoadingSpinner";
import classnames from "classnames";
import { jsPDF } from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faFilePdf,
  faEdit,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  Card,
  Container,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
  TabContent,
  TabPane,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { toast } from "react-toastify";

const RecallList = () => {
  const [originalRecallData, setOriginalRecallData] = useState({
    ingredients: [],
    products: [],
    batches: [],
  });
  const [recallData, setRecallData] = useState({
    ingredients: [],
    products: [],
    batches: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const csrfToken = getCsrfToken();
  const [activeTab, setActiveTab] = useState("1");
  const [filterDate, setFilterDate] = useState("");
  const [userFullName, setUserFullName] = useState("");

  useEffect(() => {
    fetchUserInfo();
  }, []);

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchUserInfo = async () => {
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-user-info/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      const { first_name, last_name } = response.data;
      const fullName = `${first_name} ${last_name}`;
      setUserFullName(fullName);
    } catch (error) {
      console.error("Error fetching user info:", error);
      setUserFullName("");
    }
  };

  const fetchProductDetails = async (productId) => {
    const csrfToken = getCsrfToken();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-detail/${productId}/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      console.log("Fetched product details:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching product details:", error);
      throw error;
    }
  };

  const fetchIngredientDetails = async (ingredientId) => {
    const csrfToken = getCsrfToken();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/ingredients-detail/${ingredientId}/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      console.log("Fetched ingredient details:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching ingredient details:", error);
      throw error;
    }
  };

  const fetchBatchDetails = async (batchId) => {
    const csrfToken = getCsrfToken();

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch-detail/${batchId}/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      console.log("Fetched batch details:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching batch details:", error);
      throw error;
    }
  };

  const fetchRecallData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recalls/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );

      const recalls = response.data.results;
      let ingredientsTemp = {};
      let productsTemp = {};
      let batchesTemp = {};

      for (let recall of recalls) {
        console.log(
          "Processing recall:",
          recall.id,
          "of type:",
          recall.recall_type
        );
        const key = recall.id;

        if (
          recall.recall_type === "Ingredient" &&
          recall.affected_ingredient_details
        ) {
          console.log(
            `Fetching related products for ingredient recall ${recall.id}`
          );
          const relatedProducts = await fetchRelatedProducts(
            recall.affected_ingredient_details.id
          );
          console.log(
            `Related products for recall ${recall.id}:`,
            relatedProducts
          );
          ingredientsTemp[key] = { recall, relatedProducts };
        } else if (
          recall.recall_type === "Product" &&
          recall.affected_product_details
        ) {
          console.log(
            `Fetching related batches for product recall ${recall.id}`
          );
          const relatedBatches = await fetchRelatedBatches(
            recall.affected_product_details.id
          );
          console.log(
            `Related batches for recall ${recall.id}:`,
            relatedBatches
          );
          productsTemp[key] = { recall, relatedBatches };
        } else if (
          recall.recall_type === "Batch" &&
          recall.affected_batch_details
        ) {
          console.log(
            `Fetching related products for batch recall ${recall.id}`
          );
          const relatedProducts = await fetchRelatedProductsForBatch(
            recall.affected_batch_details.id
          );
          console.log(
            `Related products for batch recall ${recall.id}:`,
            relatedProducts
          );
          batchesTemp[key] = { recall, relatedProducts };
        }
      }

      const structuredData = {
        ingredients: Object.values(ingredientsTemp),
        products: Object.values(productsTemp),
        batches: Object.values(batchesTemp),
      };
      console.log("Structured Recall Data:", structuredData);
      setRecallData(structuredData);
      setOriginalRecallData(structuredData);
    } catch (error) {
      console.error("Error fetching recall data:", error);
      toast.error("Error fetching recall data");
    }
    setIsLoading(false);
  };

  const generatePDF = async () => {
    // Ensure selectedItem has the necessary recall ID and recall type
    const recallId = selectedItem?.data?.id;
    const recallType = selectedItem?.data?.recall_type; // Assuming the recall type is stored in this property

    if (!recallId || !recallType) {
      console.error("No recall ID or recall type available for PDF generation");
      toast.error("No recall ID or recall type available for PDF generation");
      return;
    }

    try {
      // Include the recall type as a query parameter in the request URL
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recalls/${recallId}/report/?type=${recallType}`,
        {
          responseType: "blob", // Important for handling the binary data of the PDF file
          headers: {
            "X-CSRFToken": csrfToken, // Assuming you have CSRF token handling
          },
        }
      );

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `RecallReport_${recallId}.pdf`); // Customize the file name as needed
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // Clean up
    } catch (error) {
      console.error("Error generating PDF report:", error);
      toast.error("Error generating PDF report");
    }
  };

  const formatFieldName = (fieldName) => {
    return fieldName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    fetchRecallData();
  }, []);

  const handleDetailClick = (recall, isEdit = false) => {
    const csrfToken = getCsrfToken();
  
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/recalls/${recall.id}/`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      })
      .then((response) => {
        setSelectedItem({ data: response.data, type: "recall" });
        setModal(true);
        setIsEditMode(isEdit);
      })
      .catch((error) => {
        console.error("Error fetching recall details:", error);
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const filterRecalls = (data, term) => {
    return data.filter((item) => {
      const recall = item.recall || {};
      const isDateMatch = filterDate
        ? formatDateForComparison(recall.date_reported) === filterDate
        : true;

      const hasMatchingProduct =
        Array.isArray(item.relatedProducts) &&
        item.relatedProducts.some((product) =>
          product.name.toLowerCase().includes(term.toLowerCase())
        );

      const hasMatchingBatch =
        Array.isArray(item.relatedBatches) &&
        item.relatedBatches.some((batch) =>
          batch.batch_code.toLowerCase().includes(term.toLowerCase())
        );

      return (
        isDateMatch &&
        (recall.description?.toLowerCase().includes(term.toLowerCase()) ||
          formatDateForComparison(recall.date_reported)?.includes(term) ||
          recall.status?.toLowerCase().includes(term.toLowerCase()) ||
          hasMatchingProduct ||
          hasMatchingBatch)
      );
    });
  };

  const formatDateForComparison = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const filteredIngredients = filterRecalls(
      originalRecallData.ingredients,
      searchTerm
    );
    const filteredProducts = filterRecalls(
      originalRecallData.products,
      searchTerm
    );
    const filteredBatches = filterRecalls(
      originalRecallData.batches,
      searchTerm
    );
    setRecallData({
      ingredients: filteredIngredients,
      products: filteredProducts,
      batches: filteredBatches,
    });
  }, [searchTerm, filterDate, originalRecallData]);

  useEffect(() => {
    if (searchTerm) {
      const filteredIngredients = filterRecalls(
        originalRecallData.ingredients,
        searchTerm
      );
      const filteredProducts = filterRecalls(
        originalRecallData.products,
        searchTerm
      );
      const filteredBatches = filterRecalls(
        originalRecallData.batches,
        searchTerm
      );
      setRecallData({
        ingredients: filteredIngredients,
        products: filteredProducts,
        batches: filteredBatches,
      });
    } else {
      setRecallData(originalRecallData);
    }
  }, [searchTerm, originalRecallData]);

  const downloadExcel = async () => {
    try {
      const response = await axios.get("/core/api/export/recalls/", {
        responseType: "blob",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Recalls.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }

    return date.toISOString().split("T")[0];
  };

  const renderRelatedEntities = () => {
    const { data } = selectedItem;

    if (!data) return <p>No related entities found.</p>;

    let relatedEntitiesDisplay = null;

    if (
      data.recall_type === "Ingredient" &&
      data.affected_ingredient_details
    ) {
      relatedEntitiesDisplay = (
        <div>
          <p>Ingredient ID: {data.affected_ingredient_details.id}</p>
          <p>
            Description: {data.affected_ingredient_details.ingredient_description}
          </p>
          <p>Supplier: {data.affected_ingredient_details.supplier_name}</p>
          <p>Serial Number: {data.affected_ingredient_details.serial_number}</p>
          <p>Weight: {data.affected_ingredient_details.weight}</p>
          <p>Weight Units: {data.affected_ingredient_details.weight_units}</p>
        </div>
      );
    } else if (
      data.recall_type === "Product" &&
      data.affected_product_details
    ) {
      relatedEntitiesDisplay = (
        <div>
          <p>Product ID: {data.affected_product_details.id}</p>
          <p>Name: {data.affected_product_details.product_name}</p>
          <p>Quantity: {data.affected_product_details.quantity}</p>
          <p>Price: {data.affected_product_details.price}</p>
          <p>Expiry Date: {formatDate(data.affected_product_details.expiry_date)}</p>
          <p>Recipe ID: {data.affected_product_details.recipe.id}</p>
          <p>Recipe Name: {data.affected_product_details.recipe.name}</p>
          {data.affected_product_details.recipe.ingredients &&
            data.affected_product_details.recipe.ingredients.map(
              (ingredient, index) => (
                <p key={index}>
                  Ingredient {index + 1}: {ingredient.ingredient_description}
                </p>
              )
            )}
        </div>
      );
    } else if (data.recall_type === "Batch" && data.affected_batch_details) {
      relatedEntitiesDisplay = (
        <div>
          <p>Batch ID: {data.affected_batch_details.id}</p>
          <p>Batch Code: {data.affected_batch_details.batch_code}</p>
          <p>Date Created: {formatDate(data.affected_batch_details.date_created)}</p>
          <p>Production Date: {formatDate(data.affected_batch_details.production_date)}</p>
          <p>Expiry Date: {formatDate(data.affected_batch_details.expiry_date)}</p>
          <p>Is Recalled: {data.affected_batch_details.is_recalled ? "Yes" : "No"}</p>
          <p>Is Favorite: {data.affected_batch_details.is_favorite ? "Yes" : "No"}</p>
          <p>Recall Date: {formatDate(data.affected_batch_details.recall_date)}</p>
        </div>
      );
    }

    return relatedEntitiesDisplay;
  };

  const renderModalContent = () => {
    const { data } = selectedItem;

    const handleInputChange = (e, field) => {
      setSelectedItem({
        ...selectedItem,
        data: { ...selectedItem.data, [field]: e.target.value },
      });
    };

    if (!data) return <div>No details available</div>;

    return (
      <div>
        {isEditMode ? (
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => toggleTab("1")}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => toggleTab("2")}
                >
                  Affected Products
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="modal-input-container">
                  <label className="modal-label">Recall Type:</label>
                  <select
                    className="modal-input"
                    value={data.recall_type}
                    onChange={(e) => handleInputChange(e, "recall_type")}
                  >
                    <option value="Ingredient">Ingredient</option>
                    <option value="Product">Product</option>
                    <option value="Recipe">Recipe</option>
                  </select>
                </div>
                <div className="modal-input-container">
                  <label className="modal-label">Recall Class:</label>
                  <select
                    className="modal-input"
                    value={data.recall_class}
                    onChange={(e) => handleInputChange(e, "recall_class")}
                  >
                    <option value="I">I</option>
                    <option value="II">II</option>
                    <option value="III">III</option>
                  </select>
                </div>

                <div className="modal-input-container">
                  <label className="modal-label">Description:</label>
                  <textarea
                    className="modal-input"
                    value={data.description}
                    onChange={(e) => handleInputChange(e, "description")}
                  />
                </div>

                <div className="modal-input-container">
                  <label className="modal-label">Date Reported:</label>
                  <input
                    className="modal-input"
                    type="date"
                    value={formatDateForInput(data.date_reported)}
                    onChange={(e) => handleInputChange(e, "date_reported")}
                  />
                </div>

                <div className="modal-input-container">
                  <label className="modal-label">Status:</label>
                  <select
                    className="modal-input"
                    value={data.status}
                    onChange={(e) => handleInputChange(e, "status")}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Closed">Closed</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="modal-input-container">
            <label className="modal-label">Notes:</label>
            <textarea
              className="modal-input"
              value={data.notes || ""}
              onChange={(e) => handleInputChange(e, "notes")}
            />
          </div>

                <div className="modal-input-container">
                  <label className="modal-label">Initiator:</label>
                  <select
                    className="modal-input"
                    value={data.initiator}
                    onChange={(e) => handleInputChange(e, "initiator")}
                  >
                    <option value="FDA">FDA</option>
                    <option value="Inspection">Inspection</option>
                    <option value="Client">Client</option>
                    <option value="Supplier">Supplier</option>
                    <option value="Manager">Manager</option>
                    <option value="Other">Other</option>
                    <option value="System">System</option>
                  </select>
                </div>

                <div className="modal-input-container">
                  <label className="modal-label">More Info Link:</label>
                  <input
                    className="modal-input"
                    type="text"
                    value={data.more_info_link}
                    onChange={(e) => handleInputChange(e, "more_info_link")}
                  />
                </div>
              </TabPane>
              <TabPane tabId="2">
                <h5>Related Entities</h5>
                {renderRelatedEntities()}
              </TabPane>
            </TabContent>
          </>
        ) : (
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => toggleTab("1")}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => toggleTab("2")}
                >
                  Affected Products
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p>
                  <strong>Recall Type:</strong> {data.recall_type}
                </p>
                <p>
                  <strong>Description:</strong> {data.description}
                </p>
                <p>
                  <strong>Date Reported:</strong> {formatDate(data.date_reported)}
                </p>
                <p>
                  <strong>Status:</strong> {data.status}
                </p>
                <p>
                  <strong>Notes:</strong> {data.notes}
                </p>
                <p>
                  <strong>Initiator:</strong> {data.initiator}
                </p>
                <p>
                  <strong>More Info Link:</strong>
                  <a href={data.more_info_link} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                </p>
              </TabPane>
              <TabPane tabId="2">
                <h5>Related Entities</h5>
                {renderRelatedEntities()}
              </TabPane>
            </TabContent>
          </>
        )}
      </div>
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No date provided";

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const fetchRelatedProducts = async (ingredientId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient-related/${ingredientId}/related-products`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );

      console.log("Fetched related products for ingredient:", response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Ingredient not found:", error.response.data.error);
      } else {
        console.error("Error fetching related products for ingredient:", error);
      }
      return [];
    }
  };

  const fetchRelatedBatches = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-related/${productId}/related-batches`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      console.log("Fetched related batches for product:", response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Product not found:", error.response.data.error);
      } else {
        console.error("Error fetching related batches for product:", error);
      }
      return [];
    }
  };

  const fetchRelatedProductsForBatch = async (batchId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch-related/${batchId}/related-products`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      console.log("Fetched related products for batch:", response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Batch not found:", error.response.data.error);
      } else {
        console.error("Error fetching related products for batch:", error);
      }
      return [];
    }
  };

  const handleChange = (e, field) => {
    setSelectedItem({
      ...selectedItem,
      data: { ...selectedItem.data, [field]: e.target.value },
    });
  };

  const handleUpdate = () => {
    const csrfToken = getCsrfToken();
  
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recalls/update/${selectedItem.data.id}/`,
        selectedItem.data,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      )
      .then((response) => {
        setIsEditMode(false);
        fetchRecallData();
        toast.success("Recall updated successfully");
  
        // Fetch the updated recall data and update the selectedItem state
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/recalls/${selectedItem.data.id}/`, {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          })
          .then((response) => {
            setSelectedItem({ data: response.data, type: "recall" });
          })
          .catch((error) => {
            console.error("Error fetching updated recall details:", error);
          });
      })
      .catch((error) => {
        console.error("Error updating recall:", error);
        toast.error("Error updating recall");
      });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleDelete = async (recallId) => {
    if (window.confirm("Are you sure you want to delete this recall?")) {
      const csrfToken = getCsrfToken();
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/recalls/delete/${recallId}/`,
          {
            headers: {
              "X-CSRFToken": csrfToken,
            },
            withCredentials: true,
          }
        );
        toast.warn("Recall deleted successfully!");
        fetchRecallData();
      } catch (error) {
        console.error("Error deleting recall:", error);
        toast.error("Error deleting recall.");
      }
    } else {
      console.log("Recall deletion cancelled.");
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <div className="filter-search-container">
                <Button
                  className="custom-button me-2"
                  title="Excel Report"
                  onClick={downloadExcel}
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                </Button>

                <div className="filter-container">
                  <Input
                    type="date"
                    name="filterDate"
                    id="filterDate"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e.target.value)}
                  />
                </div>
                <div className="search-bar-container">
                  <Input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                  />
                  {searchTerm && (
                    <button
                      className="clear-search-btn"
                      onClick={() => setSearchTerm("")}
                    >
                      X
                    </button>
                  )}
                </div>
              </div>

              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <Card className="shadow mb-5">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="section-header">
                      <tr>
                        <th colSpan="6">Ingredients</th>
                      </tr>
                    </thead>
                    <thead className="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>Description</th>
                        <th>Date Reported</th>
                        <th>Status</th>
                        <th>Class</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recallData.ingredients.length > 0 ? (
                        recallData.ingredients.map((ingredient, index) => (
                          <tr
                            key={index}
                            className="hover-row"
                            onClick={() => handleDetailClick(ingredient.recall)}
                          >
                            <td>{ingredient.recall.id}</td>
                            <td>{ingredient.recall.description}</td>
                            <td>
                              {formatDate(ingredient.recall.date_reported)}
                            </td>
                            <td>{ingredient.recall.status}</td>
                            <td>{ingredient.recall.recall_class}</td>

                            <td className="actions-cell">
                              <Button
                                color="primary"
                                size="sm"
                                title="Edit"
                                onClick={() =>
                                  handleDetailClick(ingredient.recall, true)
                                }
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() =>
                                  handleDelete(ingredient.recall.id)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No ingredients found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              )}

              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <Card className="shadow mb-5">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="section-header">
                      <tr>
                        <th colSpan="6">Products</th>
                      </tr>
                    </thead>
                    <thead className="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>Description</th>
                        <th>Date Reported</th>
                        <th>Status</th>
                        <th>Class</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recallData.products.length > 0 ? (
                        recallData.products.map((product, index) => (
                          <tr
                            key={index}
                            className="hover-row"
                            onClick={() => handleDetailClick(product.recall)}
                          >
                            <td>{product.recall.id}</td>
                            <td>{product.recall.description}</td>
                            <td>{formatDate(product.recall.date_reported)}</td>
                            <td>{product.recall.status}</td>
                            <td>{product.recall.recall_class}</td>

                            <td className="actions-cell">
                              <Button
                                color="primary"
                                size="sm"
                                title="Edit"
                                onClick={() =>
                                  handleDetailClick(product.recall, true)
                                }
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>

                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => handleDelete(product.recall.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No products found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              )}

              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <Card className="shadow mb-5">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="section-header">
                      <tr>
                        <th colSpan="6">Batches</th>
                      </tr>
                    </thead>
                    <thead className="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>Description</th>
                        <th>Date Reported</th>
                        <th>Status</th>
                        <th>Class</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recallData.batches.length > 0 ? (
                        recallData.batches.map((batch, index) => (
                          <tr
                            key={index}
                            className="hover-row"
                            onClick={() => handleDetailClick(batch.recall)}
                          >
                            <td>{batch.recall.id}</td>
                            <td>{batch.recall.description}</td>
                            <td>{formatDate(batch.recall.date_reported)}</td>
                            <td>{batch.recall.status}</td>
                            <td>{batch.recall.recall_class}</td>
                            <td className="actions-cell">
                              <Button
                                color="primary"
                                size="sm"
                                title="Edit"
                                onClick={() =>
                                  handleDetailClick(batch.recall, true)
                                }
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>

                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => handleDelete(batch.recall.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No batches found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
          setIsEditMode(false);
          setSelectedItem({});
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
            setIsEditMode(false);
            setSelectedItem({});
          }}
        >
          Detail View
        </ModalHeader>
        <ModalBody>{renderModalContent()}</ModalBody>
        <ModalFooter>
          {isEditMode && (
            <Button color="primary" onClick={handleUpdate}>
              Save Changes
            </Button>
          )}
          <Button
            color="info"
            onClick={() => setIsEditMode(!isEditMode)}
            className="float-right edit-cancel-button"
          >
            {isEditMode ? "Cancel Edit" : "Edit"}
          </Button>
          <Button color="primary" onClick={generatePDF}>
            Generate PDF
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setModal(false);
              setIsEditMode(false);
              setSelectedItem({});
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RecallList;
