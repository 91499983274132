import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [csrfToken, setCsrfToken] = useState("");
  const [secondPassword, setSecondPassword] = useState("");

  useEffect(() => {
    // Fetch CSRF token when the component mounts
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/get-csrf-token`, {
      method: "GET",
      credentials: "include", // Include credentials for CORS 
      headers: {
        "Content-Type": "application/json",
        // Any other headers that your server requires
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => setCsrfToken(data.csrfToken))
      .catch((error) => console.error("Error fetching CSRF token:", error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formElement = document.getElementById("registration-form");
    const formData = new FormData(formElement);
  
    const firstPassword = formData.get("password");
  
    // Password validation checks
    if (firstPassword.length < 8) {
      toast.error("Password must be at least 8 characters long!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    // Add any other security checks as needed
    if (!/[A-Z]/.test(firstPassword) ||
        !/[a-z]/.test(firstPassword) ||
        !/[0-9]/.test(firstPassword) ||
        !/[^A-Za-z0-9]/.test(firstPassword)) {
      toast.error("Password must include upper and lower case letters, numbers, and special characters!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    if (firstPassword !== secondPassword) {
      toast.error("Passwords don't match!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    const urlEncodedData = new URLSearchParams();
    for (const pair of formData) {
      urlEncodedData.append(pair[0], pair[1]);
    }
  
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/register/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-CSRFToken": csrfToken,
        },
        body: urlEncodedData.toString(),
        credentials: "include",
      }
    );
  
    if (response.status === 201) {
      toast.success("Registration successful! Go to your email and confirm on the link before login...", {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => navigate("/auth/login"), 2000);
    } else {
      const errorData = await response.json();
      let errorMessages = '';
      // Check if the error field contains an object with messages
      if (typeof errorData.error === 'object' && errorData.error !== null) {
        errorMessages = Object.entries(errorData.error)
          .map(([field, messages]) => {
            // Format each field error
            const formattedMessages = Array.isArray(messages) ? messages.join(", ") : messages;
            return `${field}: ${formattedMessages}`;
          })
          .join("\n");
      } else if (typeof errorData.error === 'string') {
        // If error is a plain string, use it directly
        errorMessages = errorData.error;
      } else {
        // Fallback error message
        errorMessages = 'An unexpected error occurred.';
      }
  
      toast.error(errorMessages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  

  return (
    <>
      <Col lg="6" md="8">
        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-4">
              <small>Sign up with</small>
            </div>
          
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small></small>
            </div>
            <Form role="form" id="registration-form" onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name"
                    type="text"
                    name="first_name"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Last Name" type="text" name="last_name" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Organization"
                    type="text"
                    name="organization_name"
                  />{" "}
                  {/* Updated name attribute */}
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm Password"
                    type="password"
                    value={secondPassword}
                    onChange={(e) => setSecondPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
