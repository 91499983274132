import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Header from "../../components/Headers/Header.js";
import axios from "axios";
import "../../assets/css/calendar_css.css";
import "@fullcalendar/core/locales/es";
import { Calendar } from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { toast } from "react-toastify";

const Maps = () => {
  const [audits, setAudits] = useState([]);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dateClicked, setDateClicked] = useState("");
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [calendarKey, setCalendarKey] = useState(Date.now());
  const [modalMode, setModalMode] = useState("view");
  const [modalAction, setModalAction] = useState("");

  useEffect(() => {
    fetchStores();
  }, []); // Call fetchStores when the component mounts

  const fetchStores = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      setStores(response.data); // Assuming the response data is an array of stores
    } catch (error) {
      console.error("Error fetching stores:", error);
    }
  };

  const [newAudit, setNewAudit] = useState({
    title: "",
    authority: "FDA", // Default value, change as necessary
    inspector_name: "",
    audit_date: "",
    first_document: "",
    // Add other fields as necessary
  });

  // Function to handle date click
  const handleDateClick = (arg) => {
    setModalAction("create");
    setShowCreateModal(true); // Use showModal for both creating and viewing/editing/deleting
    // Reset newAudit state to default values for new audit creation
    setNewAudit({
      title: "",
      authority: "FDA",
      inspector_name: "",
      audit_date: arg.dateStr, // Pre-fill with clicked date
      first_document: "",
    });
  };

  // Instead of mapping to a simplified version for the calendar,
  // store the full audits array directly as fetched.
  useEffect(() => {
    setIsLoading(true);
    axios(`${process.env.REACT_APP_API_BASE_URL}/core/api/audits/`, {
      headers: {
        "X-CSRFToken": getCsrfToken(),
      },
      withCredentials: true,
    })
      .then((response) => {
        console.log("Fetched audits:", response.data);
        setAudits(response.data); // Store the full details here
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the audits:", error);
        setIsLoading(false);
      });
  }, []);

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleEventClick = (clickInfo) => {
    const auditId = clickInfo.event.id;
    const audit = audits.find((audit) => audit.id.toString() === auditId);
    if (audit) {
      const storeName =
        stores.find((store) => store.id === audit.store)?.name ||
        "Unknown Store";
      setSelectedAudit({ ...audit, storeName });
      setModalAction("view"); // Open the modal in view mode
      setShowCreateModal(true); // This now refers to the unified modal for all actions
    } else {
      console.error("Audit not found.");
    }
  };

  const closeModal = () => {
    setShowCreateModal(false); // Correctly control modal visibility
    setModalAction(""); // Reset modal action
    setSelectedAudit(null); // Clear selected audit
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (modalAction === "edit") {
      setSelectedAudit((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewAudit((prev) => ({ ...prev, [name]: value }));
    }
  };

  const fetchAudits = async () => {
    setIsLoading(true);
    try {
      const response = await axios(`${process.env.REACT_APP_API_BASE_URL}/core/api/audits/`, {
        headers: {
          "X-CSRFToken": getCsrfToken(),
        },
        withCredentials: true,
      });
      setAudits(response.data); // Assuming response.data is the updated list of audits
      setIsLoading(false);
      setCalendarKey((prevKey) => prevKey + 1); // Update key to force calendar refresh
    } catch (error) {
      console.error("There was an error fetching the audits:", error);
      setIsLoading(false);
    }
  };

  const submitNewAudit = async () => {
    const auditData = {
      ...newAudit,
      store: selectedStore,
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/core/api/audits/`, auditData, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
        withCredentials: true,
      });
      toast.success("Audit created successfully!");
      setShowCreateModal(false);
      fetchAudits(); // Fetch updated audits after adding a new one
    } catch (error) {
      console.error("There was an error creating the audit:", error);
      toast.error("Error creating audit.");
    }
  };

  // Function to open the modal in different modes
  const openModal = (action, audit = null) => {
    setModalAction(action);
    setSelectedAudit(audit); // Ensure audit contains the data for editing
    setShowCreateModal(true);
  };

  // Adjust the submit function to handle both create and update
  const handleSubmit = async () => {
    if (modalMode === "create") {
      // Create audit logic
    } else if (modalMode === "edit") {
      // Update audit logic
    }
  };

  // Function to handle delete// Example update logic
  const updateAudit = async (auditData) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/core/api/audits/${auditData.id}/`, auditData, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
        withCredentials: true,
      });
      fetchAudits();
      setShowCreateModal(false);
      toast.success("Audit updated successfully!");
    } catch (error) {
      console.error("Error updating audit:", error);
      toast.error("Failed to update audit.");
    }
  };

  // Function to handle the transition to edit mode from view mode
  const handleEdit = () => {
    setModalAction("edit"); // Change the modal action to 'edit'

    // Convert selectedAudit.audit_date to the format expected by input type="datetime-local"
    const formattedDate = new Date(selectedAudit.audit_date)
      .toISOString()
      .slice(0, 16);

    // Pre-populate the form with selectedAudit data
    setNewAudit({
      title: selectedAudit.title,
      authority: selectedAudit.authority,
      inspector_name: selectedAudit.inspector_name,
      audit_date: formattedDate,
      store: selectedAudit.store,
      first_document: selectedAudit.first_document,
      // Add other fields as necessary
    });
  };

  const handleDelete = async (auditId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/core/api/audits/${auditId}/`, {
        headers: {
          "X-CSRFToken": getCsrfToken(),
        },
        withCredentials: true,
      });
      toast.success("Audit successfully deleted");
      fetchAudits(); // Refresh the audits list
      setShowCreateModal(false); // Close the modal
    } catch (error) {
      console.error("Error deleting the audit:", error);
      toast.error("Failed to delete audit");
    }
  };

  const handleSaveChanges = async () => {
    if (modalAction === "edit") {
      await updateAudit(selectedAudit);
    }
    fetchAudits();
    setShowCreateModal(false);
    setModalAction("");
  };

  const handleSaveEdit = async () => {
    if (!selectedAudit) {
      console.error("No audit selected for editing.");
      return;
    }

    const updatedAuditData = {
      ...selectedAudit,
      // Assuming you have state variables or form fields for the audit details
      // For example, you could have a state variable for each field like this:
      title: newAudit.title,
      authority: newAudit.authority,
      inspector_name: newAudit.inspector_name,
      audit_date: newAudit.audit_date,
      first_document: newAudit.first_document,
      store: selectedStore, // Assuming this matches the backend's expected format
      // Add other fields as needed
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/audits/${selectedAudit.id}/`,
        updatedAuditData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
          withCredentials: true,
        }
      );
      toast.success("Audit updated successfully!");
      setShowCreateModal(false); // Close the modal
      fetchAudits(); // Fetch updated audits to refresh the list
    } catch (error) {
      console.error("There was an error updating the audit:", error);
      toast.error("Error updating audit.");
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <FullCalendar
                key={calendarKey} // Use key here
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                locale="en"
                events={audits.map((audit) => ({
                  id: audit.id.toString(),
                  title: audit.title,
                  start: audit.audit_date, // Use audit_date here
                }))}
                dateClick={handleDateClick}
                eventClick={handleEventClick} // This should correctly invoke your handler
              />
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={showCreateModal} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>
          {modalAction === "create"
            ? "Create New Audit"
            : modalAction === "edit"
            ? "Edit Audit"
            : selectedAudit
            ? selectedAudit.title
            : ""}
        </ModalHeader>
        <ModalBody>
          {modalAction === "view" && selectedAudit && (
            <>
              <div className="form-group">
                <label>Authority</label>
                <p>{selectedAudit.authority}</p>
              </div>
              <p>Inspector Name: {selectedAudit.inspector_name}</p>
              <p>
                Audit Date:{" "}
                {new Date(selectedAudit.audit_date).toLocaleString()}
              </p>
              <p>Store: {selectedAudit.storeName}</p>
              <p>Notes: {selectedAudit.notes}</p>
              {selectedAudit.first_document && (
                <p>
                  First Document:{" "}
                  <a
                    href={selectedAudit.first_document}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                </p>
              )}
              <Button
                color="primary"
                onClick={() => openModal("edit", selectedAudit)}
              >
                Edit
              </Button>
              <Button
                color="danger"
                onClick={() => handleDelete(selectedAudit.id)}
              >
                Delete
              </Button>
            </>
          )}
          {(modalAction === "create" || modalAction === "edit") && (
            <form>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={
                    modalAction === "edit"
                      ? selectedAudit.title
                      : newAudit.title
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Authority</label>
                <select
                  className="form-control"
                  name="authority"
                  value={
                    modalAction === "edit"
                      ? selectedAudit.authority
                      : newAudit.authority
                  }
                  onChange={handleInputChange}
                >
                  <option value="FDA">FDA</option>
                  <option value="Internal">Internal</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label>Inspector Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="inspector_name"
                  value={
                    modalAction === "edit"
                      ? selectedAudit.inspector_name
                      : newAudit.inspector_name
                  }
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Audit Date</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="audit_date"
                  value={newAudit.audit_date} // Always use newAudit.audit_date here
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>First Document URL</label>
                <input
                  type="url"
                  className="form-control"
                  name="first_document"
                  value={
                    modalAction === "edit"
                      ? selectedAudit.first_document
                      : newAudit.first_document
                  }
                  onChange={handleInputChange}
                />
              </div>
              {/* Example of additional fields */}
              <div className="form-group">
                <label>Notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  value={
                    modalAction === "edit"
                      ? selectedAudit.notes
                      : newAudit.notes
                  }
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Store</label>
                <select
                  className="form-control"
                  name="store"
                  value={
                    modalAction === "edit" ? selectedAudit.store : selectedStore
                  }
                  onChange={(e) => setSelectedStore(e.target.value)}
                >
                  <option value="">Select a Store</option>
                  {stores.map((store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          )}
        </ModalBody>

        <ModalFooter>
          {modalAction === "edit" && (
            <>
              <Button color="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
              <Button color="secondary" onClick={() => setModalAction("view")}>
                Cancel
              </Button>
            </>
          )}
          {modalAction === "create" && (
            <>
              <Button color="primary" onClick={submitNewAudit}>
                Submit
              </Button>
              <Button color="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Maps;
