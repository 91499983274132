// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAwvypGbOVZDtxf-EKJIY7Usvy0ulzQoWU",
  authDomain: "food-recall-tracker.firebaseapp.com",
  projectId: "food-recall-tracker",
  storageBucket: "food-recall-tracker.appspot.com",
  messagingSenderId: "404089073265",
  appId: "1:404089073265:web:c58534bfa50e59f41b35d0",
  measurementId: "G-80K8Y0T8X8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export { app, messaging };