import React, { useState } from "react";
import axios from "axios";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  ListGroup,
  ListGroupItem,
  Col,
} from "reactstrap";
import classnames from "classnames";

const BatchDetailModal = ({ isOpen, toggle, batchDetail, onSave }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [editMode, setEditMode] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleSave = async () => {
    const csrfToken = getCsrfToken(); // Retrieve CSRF token
    const authToken = localStorage.getItem("token"); // Retrieve auth token
    const updatedData = {
      batch_code: document.getElementById("batchCode")?.value,
      date_created: document.getElementById("dateCreated")?.value,
      production_date: document.getElementById("productionDate")?.value,
      expiry_date: document.getElementById("expiryDate")?.value,
      is_favorite: batchDetail.is_favorite,
      notes: document.getElementById("notes")?.value,
      is_recalled: document.getElementById("isRecalled")?.checked ?? false,
      recall_date: document.getElementById("recallDate")?.value || null,
      store: batchDetail.store, // Assuming this is static or coming from a dropdown
    };

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/update/${batchDetail.id}/`,
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
          withCredentials: true, // Include credentials
        }
      );
      console.log("Save successful", response.data);
      toggleEditMode(); // Turn off edit mode after saving
      onSave();
    } catch (error) {
      console.error("Failed to save batch details", error.response);
      // Handle errors here, such as displaying a notification to the user
    }
  };

  // Helper function to format the date
  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Helper function for Date and Time format
  function formatDateTime(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Batch Details</ModalHeader>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            Batch Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            Product Quantities & Supplies
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggleTab("3");
            }}
          >
            Ingredients
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => {
              toggleTab("4");
            }}
          >
            Recipe Details
          </NavLink>
        </NavItem>
      </Nav>
      <ModalBody>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {editMode ? (
              <Form>
                <FormGroup>
                  <Label for="batchCode">Batch Code</Label>
                  <Input
                    type="text"
                    id="batchCode"
                    defaultValue={batchDetail.batch_code}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="dateCreated">Date Created</Label>
                  <Input
                    type="datetime-local"
                    id="dateCreated"
                    defaultValue={new Date(batchDetail.date_created)
                      .toISOString()
                      .slice(0, 16)} // Formats date to YYYY-MM-DDTHH:mm
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="productionDate">Production Date</Label>
                  <Input
                    type="date"
                    id="productionDate"
                    defaultValue={batchDetail.production_date}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="expiryDate">Expiry Date</Label>
                  <Input
                    type="date"
                    id="expiryDate"
                    defaultValue={batchDetail.expiry_date}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="notes">Notes</Label>
                  <Input
                    type="textarea"
                    id="notes"
                    defaultValue={batchDetail.notes}
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="isRecalled"
                      checked={batchDetail.is_recalled}
                    />{" "}
                    Recalled
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label for="recallDate">Recall Date</Label>
                  <Input
                    type="date"
                    id="recallDate"
                    defaultValue={
                      batchDetail.recall_date &&
                      batchDetail.recall_date.split("T")[0]
                    }
                  />
                </FormGroup>
              </Form>
            ) : (
              <div>
                <p>Batch Code: {batchDetail.batch_code}</p>
                <p>Date Created: {formatDateTime(batchDetail.date_created)}</p>
                <p>Production Date: {batchDetail.production_date}</p>
                <p>Expiry Date: {batchDetail.expiry_date}</p>
                <p>Notes: {batchDetail.notes}</p>
                <p>Recalled: {batchDetail.is_recalled ? "Yes" : "No"}</p>
                <p>Recall Date: {batchDetail.recall_date}</p>
              </div>
            )}
          </TabPane>
          <TabPane tabId="2">
            {/* Product Quantities Table */}
            <p>
              Details of products and supplies required for the current batch:
            </p>
            <Table bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Weight</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {batchDetail.product_quantities?.map((pq, index) => (
                  <tr key={`product-${index}`}>
                    <td>{pq.name}</td>
                    <td>${pq.price.toFixed(2)}</td>
                    <td>{pq.weight}g</td>
                    <td>{pq.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Supplies Table */}
            <Table bordered>
              <thead>
                <tr>
                  <th>Supply Name</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {batchDetail.batch_supplies?.map((supply, index) => (
                  <tr key={`supply-${index}`}>
                    <td>{supply.name}</td>
                    <td>{supply.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TabPane>

          <TabPane tabId="3">
            <p>Details of ingredients being used for the current batch:</p>

            <Table bordered>
              <tbody>
                {batchDetail.ingredient_inventory?.map((ingredient, index) => (
                  <React.Fragment key={ingredient.id}>
                    <tr>
                      <td>
                        <strong>Ingredient</strong>
                        <br />
                        {ingredient.ingredient_name}
                      </td>
                      <td>
                        <strong>Serial Number</strong>
                        <br />
                        {ingredient.serial_number}
                      </td>
                      <td>
                        <strong>Expiration Date</strong>
                        <br />
                        {ingredient.expiration_date}
                      </td>
                      <td>
                        <strong>Weight</strong>
                        <br />
                        {`${ingredient.weight} ${ingredient.weight_units}`}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          Ingredient Quantities Needed on the Batch
                        </strong>
                        <br />
                        {
                          batchDetail.ingredient_details[
                            ingredient.ingredient_name
                          ]?.quantity
                        }{" "}
                        {
                          batchDetail.ingredient_details[
                            ingredient.ingredient_name
                          ]?.units
                        }{" "}
                        used for product:
                        {batchDetail.product_quantities.map((pq) => (
                          <div key={pq.id}>
                            {pq.quantity} {pq.name}
                          </div>
                        ))}
                      </td>
                      <td>
                        <strong>Price</strong>
                        <br />
                        {`$${ingredient.price}`}
                      </td>
                      <td>
                        <strong>Packaging Type</strong>
                        <br />
                        {ingredient.packaging_type}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <strong>Notes</strong>
                        <br />
                        {ingredient.notes}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </TabPane>
          <TabPane tabId="4">
            {batchDetail.product_quantities?.map((pq, index) => (
              <div key={`recipe-${index}`}>
                <h5>{pq.name}</h5>
                <p style={{ whiteSpace: "pre-line", marginBottom: "1rem" }}>
                  {pq.recipe_description}
                </p>
              </div>
            ))}
          </TabPane>
        </TabContent>
        <div className="text-right">
          {editMode && (
            <Button
              color="success"
              onClick={handleSave}
              style={{ marginRight: "5px" }}
            >
              Save
            </Button>
          )}
          <Button
            color="primary"
            onClick={toggleEditMode}
            style={{ marginRight: "5px" }}
          >
            {editMode ? "Cancel" : "Edit"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BatchDetailModal;
