import React, { useEffect, useState } from "react";
import axios from "axios";
import classnames from "classnames";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader.js";
import { toast } from "react-toastify";
import CancelSubscriptionModal from "./CancelSubscriptionModal"; // Import the modal component

function getCsrfToken() {
  const name = "csrftoken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function resizeImage(file, maxWidth, maxHeight, callback) {
  const reader = new FileReader();
  reader.onload = (readerEvent) => {
    const image = new Image();
    image.onload = (imageEvent) => {
      const canvas = document.createElement("canvas");
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(image, 0, 0, width, height);
      canvas.toBlob(
        (blob) => {
          callback(blob);
        },
        "image/jpeg",
        0.7
      );
    };
    image.src = readerEvent.target.result;
  };
  reader.readAsDataURL(file);
}

const Profile = () => {
  const [profile, setProfile] = useState({
    email: "",
    first_name: "",
    last_name: "",
    picture: null,
    search_keywords: "",
    interest_description: "",
    trial_end: "",
    plan: false,
    product_name: "",
    stripe_customer_id: "",
    stripe_subscription_id: "",
    is_on_discount: false,
    date_joined: "",
    date_updated: "",
    phone: "",
    organization: {
      id: null,
      name: "",
      website: "",
      email: "",
      address: "",
      phone: "",
    },
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderKeywordsBadges = () => {
    const keywordsArray = Array.isArray(profile.search_keywords)
      ? profile.search_keywords
      : profile.search_keywords.split(/[, ]+/);
    return keywordsArray.map((keyword, index) => (
      <span key={index} className="badge badge-primary mr-1">
        {keyword}
        <span
          style={{ cursor: "pointer", marginLeft: "5px" }}
          onClick={() => deleteKeyword(index)}
        >
          &times;
        </span>
      </span>
    ));
  };

  const deleteKeyword = (indexToDelete) => {
    const keywordsArray = Array.isArray(profile.search_keywords)
      ? profile.search_keywords
      : profile.search_keywords.split(/[, ]+/);
    const updatedKeywords = keywordsArray.filter(
      (keyword, index) => index !== indexToDelete
    );
    setProfile({
      ...profile,
      search_keywords: updatedKeywords.join(" "),
    });
  };

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/api/your-profile-endpoint`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      setProfile(response.data);
    } catch (error) {
      console.error(error);
      toast.error("There was an error displaying your profile data.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "search_keywords") {
      const keywordsArray = value.split(/[, ]+/);
      setProfile({ ...profile, search_keywords: keywordsArray.join(" ") });
    } else {
      setProfile({ ...profile, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      // Resize the image
      resizeImage(file, 400, 400, (resizedImage) => {
        // Truncate the file name if it's too long
        let fileName = file.name;
        const maxFilenameLength = 100;
        const fileExtension = fileName.slice(
          ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
        );
        if (fileName.length > maxFilenameLength) {
          // Calculate the maximum length we can use for the name excluding the extension
          const nameLength = maxFilenameLength - (fileExtension.length + 1); // +1 for the dot
          fileName = fileName.substring(0, nameLength) + "." + fileExtension;
        }

        // Create a new File object from the Blob returned by resizeImage
        const newFile = new File([resizedImage], fileName, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });

        // Update the state with the resized image
        setProfile({ ...profile, picture: newFile });
      });
    } else {
      setProfile({ ...profile, picture: null });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const csrfToken = getCookie("csrftoken"); // Ensure getCookie function is defined and works correctly
    const formData = new FormData();

    Object.entries(profile).forEach(([key, value]) => {
      // Consider adding specific handling for fields that should not be sent if empty
      if (key === "picture") {
        if (value && value instanceof File) {
          formData.append(key, value, value.name);
        }
      } else if (value !== null && value !== undefined && value !== "") {
        // Skip null, undefined, or empty string values
        formData.append(key, value);
      }
    });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/users/api/profile-update/`,
        formData,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            // 'Content-Type': 'multipart/form-data' is not needed, axios sets it automatically
          },
        }
      );
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error(error);
      if (error.response) {
        // Display server-side error messages if any
        console.error("Server responded with:", error.response.data);
        toast.error(
          `Update failed: ${error.response.data.detail || "Server error"}`
        );
      } else {
        toast.error("There was an error updating the profile.");
      }
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/cancel-subscription/`,
        {},
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      toast.success("Subscription canceled successfully!");
      setProfile({ ...profile, plan: false, stripe_subscription_id: "" });
    } catch (error) {
      console.error(error);
      toast.error("There was an error canceling your subscription.");
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col className="order-xl-1" xl="8" lg="10" md="10" sm="12" xs="12">
            <Card className="bg-secondary shadow mx-auto">
              <CardHeader className="bg-white border-0">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Personal Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Subscription
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Keywords & Description
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      Organization
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label htmlFor="email">Email</label>
                            <Input
                              name="email"
                              value={profile.email}
                              type="email"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label htmlFor="picture">Profile Picture</label>
                            <Input
                              type="file"
                              name="picture"
                              onChange={handleFileChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label htmlFor="first_name">First Name</label>
                            <Input
                              name="first_name"
                              value={profile.first_name}
                              onChange={handleInputChange}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label htmlFor="last_name">Last Name</label>
                            <Input
                              name="last_name"
                              value={profile.last_name}
                              onChange={handleInputChange}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Phone</label>
                            <p>{profile.phone}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Date Joined</label>
                            <p>
                              {new Date(profile.date_joined).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Date Updated</label>
                            <p>
                              {new Date(
                                profile.date_updated
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Profile Picture</label>
                            {profile.picture ? (
                              <img
                                src={profile.picture}
                                alt="Profile"
                                className="img-thumbnail"
                              />
                            ) : (
                              <p>No picture available</p>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Product Name</label>
                            <p>{profile.product_name}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Plan</label>
                            <p>
                              {profile.plan
                                ? "Subscribed"
                                : "No active subscription"}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Trial Ends</label>
                            <p>
                              {profile.trial_end
                                ? new Date(
                                    profile.trial_end
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })
                                : "No trial period"}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                      {profile.plan &&
                        profile.stripe_customer_id &&
                        profile.stripe_subscription_id && (
                          <div
                            style={{
                              position: "absolute",
                              right: "15px",
                              bottom: "2px",
                            }}
                          >
                            <p
                              style={{ color: "grey", cursor: "pointer", fontSize: "13px" }}
                              onClick={toggleModal}
                            >
                              Cancel Subscription
                            </p>
                          </div>
                        )}
                    </TabPane>

                    <TabPane tabId="3">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label htmlFor="search_keywords">
                              Recall Alerts Keywords
                            </label>
                            <Input
                              name="search_keywords"
                              placeholder="Enter keywords separated by space. This will help customize your FDA-USDA recall alerts."
                              value={profile.search_keywords}
                              onChange={handleInputChange}
                              type="text"
                            />
                            <div className="mt-2">{renderKeywordsBadges()}</div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label htmlFor="interest_description">
                              Describe your business
                            </label>
                            <Input
                              name="interest_description"
                              placeholder="Describe placeholder ..."
                              value={profile.interest_description}
                              onChange={(e) =>
                                setProfile({
                                  ...profile,
                                  interest_description: e.target.value,
                                })
                              }
                              rows="4"
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Name</label>
                            <p>{profile.organization.name}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Website</label>
                            <p>
                              <a
                                href={profile.organization.website}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {profile.organization.website}
                              </a>
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Email</label>
                            <p>{profile.organization.email}</p>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label>Address</label>
                            <p>{profile.organization.address}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label>Phone</label>
                            <p>{profile.organization.phone}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <Row>
                    <Col lg="6">
                      <Button type="submit" color="primary">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {successMessage && (
                  <Alert color="success">{successMessage}</Alert>
                )}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <CancelSubscriptionModal
        isOpen={modalOpen}
        toggle={toggleModal}
        handleCancelSubscription={handleCancelSubscription}
        isOnDiscount={profile.is_on_discount}
      />
    </>
  );
};

export default Profile;
