import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";

const DetailModal = ({
  isDetailModalOpen,
  setIsDetailModalOpen,
  selectedIngredientDetail,
  refreshIngredients,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [editMode, setEditMode] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const nutrientOptions = [
    "Carbohydrate, by difference",
    "Protein",
    "Energy",
    "Water",
    "Sugar",
    "Total Fat",
  ];

  // Initialize `editedIngredient` with nested structure.
  const [editedIngredient, setEditedIngredient] = useState({
    ...selectedIngredientDetail,
    ingredient_details: {
      ...selectedIngredientDetail.ingredient_details,
      nutrients: selectedIngredientDetail.ingredient_details?.nutrients || [],
    },
  });

  useEffect(() => {
    if (isDetailModalOpen) {
      setEditedIngredient({
        ...selectedIngredientDetail,
        nutrients: selectedIngredientDetail.nutrients || [],
      });
    }
  }, [selectedIngredientDetail, isDetailModalOpen]);



  useEffect(() => {
    console.log(`Edit Mode: ${editMode}`);
  }, [editMode]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedIngredient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const updateIngredient = async () => {
    if (selectedIngredientDetail.is_custom) {
      const csrfToken = getCsrfToken();
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/food/api/ingredients/update/${editedIngredient.ingredient_id}/`,
          editedIngredient,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          }
        );
  
        if (response.status === 200) {
          refreshIngredients();
          setIsDetailModalOpen(false);
        } else {
          console.error("Failed to update ingredient:", response.statusText);
        }
      } catch (error) {
        console.error("Error updating ingredient:", error.message);
      }
    } else {
      console.error("Only custom ingredients can be updated.");
    }
  };


  const handleEditButtonClick = () => {
    if (selectedIngredientDetail.is_custom) {
      console.log("Toggling Edit Mode");
      setEditMode(!editMode);
    } else {
      console.error("Only custom ingredients can be edited.");
    }
  };

  const handleNutrientChange = (e, index) => {
    const { name, value } = e.target;
    const updatedNutrients = editedIngredient.nutrients.map((nutrient, idx) => {
        if (idx === index) {
            return { ...nutrient, [name]: value };
        }
        return nutrient;
    });

    setEditedIngredient((prev) => ({
        ...prev,
        nutrients: updatedNutrients,
    }));
};


const addNutrientField = () => {
  const newNutrient = { name: "", amount: "", unit_name: "", fdc_id: null }; // Initialize with null or appropriate default
  setEditedIngredient((prev) => ({
      ...prev,
      nutrients: [...prev.nutrients, newNutrient],
  }));
};


  const removeNutrientField = (index) => {
    const filteredNutrients = editedIngredient.nutrients.filter(
      (_, idx) => idx !== index
    );
    setEditedIngredient((prev) => ({
      ...prev,
      nutrients: filteredNutrients,
    }));
  };

  const fetchSuppliers = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": getCsrfToken(),
        },
      })
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers:", error);
      });
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return (
    <Modal
      isOpen={isDetailModalOpen}
      toggle={() => setIsDetailModalOpen(false)}
    >
      <ModalHeader toggle={() => setIsDetailModalOpen(false)}>
        Ingredient Details
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
            >
              General Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
            >
              Nutrients
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
              {editMode && editedIngredient.is_custom ? (
  <>
    <FormGroup>
      <Label for="supplierName">Supplier Name</Label>
      <Input
        type="select"
        name="supplier_name"
        id="supplierName"
        value={editedIngredient.supplier_name || ""}
        onChange={handleEditChange}
      >
        <option value="">Select Supplier</option>
        {suppliers.map((supplier) => (
          <option key={supplier.id} value={supplier.id}>
            {supplier.name}
          </option>
        ))}
      </Input>
    </FormGroup>

    <FormGroup>
      <Label for="ingredientDescription">Ingredient Description</Label>
      <Input
        type="textarea"
        name="ingredient_description"
        id="ingredientDescription"
        value={editedIngredient.ingredient_description || ""}
        onChange={handleEditChange}
      />
    </FormGroup>

    <FormGroup>
      <Label for="dateCreated">Date Created</Label>
      <Input
        type="text"
        name="date_created"
        id="dateCreated"
        value={editedIngredient.date_created || ""}
        onChange={handleEditChange}
        disabled
      />
    </FormGroup>

    <FormGroup>
      <Label for="dateUpdated">Date Updated</Label>
      <Input
        type="text"
        name="date_updated"
        id="dateUpdated"
        value={editedIngredient.date_updated || ""}
        onChange={handleEditChange}
        disabled
      />
    </FormGroup>
                  </>
                ) : (
                  <>
                    <p>
                      <strong>Supplier Name:</strong>{" "}
                      {selectedIngredientDetail.ingredient_details
                        ?.supplier_name || "N/A"}
                    </p>
                    <p>
                      <strong>Ingredient Description:</strong>{" "}
                      {selectedIngredientDetail.ingredient_description || "N/A"}
                    </p>

                    <p>
                      <strong>Date Created:</strong>{" "}
                      {selectedIngredientDetail.ingredient_details?.date_created
                        ? formatDate(
                            selectedIngredientDetail.ingredient_details
                              .date_created
                          )
                        : "N/A"}
                    </p>
                    <p>
                      <strong>Date Updated:</strong>{" "}
                      {selectedIngredientDetail.ingredient_details?.date_updated
                        ? formatDate(
                            selectedIngredientDetail.ingredient_details
                              .date_updated
                          )
                        : "N/A"}
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2">
            {!editMode && (
              <>
                {selectedIngredientDetail.nutrients?.map((nutrient, index) => (
                  <Row key={`nutrient-${index}`} className="mb-2">
                    <Col sm="12">
                      <p>
                        <strong>Nutrient Name:</strong> {nutrient.name}
                      </p>
                      <p>
                        <strong>Amount:</strong> {nutrient.amount}
                      </p>
                      <p>
                        <strong>Unit:</strong> {nutrient.unit_name}
                      </p>
                    </Col>
                  </Row>
                ))}
              </>
            )}

            {editMode &&
              editedIngredient.nutrients.map((nutrient, index) => (
                <Row key={`nutrient-${index}`} className="mb-2">
                  <Col sm="4">
                    <FormGroup>
                      <Label for={`nutrientName_${index}`}>Nutrient Name</Label>
                      <Input
                        type="select"
                        name={`name`}
                        id={`nutrientName_${index}`}
                        value={nutrient.name || ""}
                        onChange={(e) => handleNutrientChange(e, index)}
                      >
                        <option value="">Select Nutrient</option>
                        {nutrientOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      <Label for={`nutrientAmount_${index}`}>Amount</Label>
                      <Input
                        type="number"
                        name={`amount`}
                        id={`nutrientAmount_${index}`}
                        value={nutrient.amount || ""}
                        onChange={(e) => handleNutrientChange(e, index)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for={`nutrientUnit_${index}`}>Unit</Label>
                      <Input
                        type="select"
                        name={`unit_name`}
                        id={`nutrientUnit_${index}`}
                        value={nutrient.unit_name || ""}
                        onChange={(e) => handleNutrientChange(e, index)}
                      >
                        <option value="">Select Unit</option>
                        <option value="G" selected={nutrient.unit_name === "G"}>
                          Grams (G)
                        </option>
                        <option
                          value="MG"
                          selected={nutrient.unit_name === "MG"}
                        >
                          Milligrams (MG)
                        </option>
                        <option
                          value="KG"
                          selected={nutrient.unit_name === "KG"}
                        >
                          Kilograms (KG)
                        </option>
                        <option
                          value="KCAL"
                          selected={nutrient.unit_name === "KCAL"}
                        >
                          Calories (KCAL)
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <Button
                      color="danger"
                      onClick={() => removeNutrientField(index)}
                      className="mb-2"
                    >
                      Remove Nutrient
                    </Button>
                  </Col>
                </Row>
              ))}

            {editMode && (
              <Button
                color="primary"
                onClick={addNutrientField}
                className="mt-2"
              >
                Add Nutrient
              </Button>
            )}
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
      {selectedIngredientDetail.is_custom && !editMode ? (
  <Button color="primary" onClick={handleEditButtonClick}>
    Edit
  </Button>
) : null}

        {editMode ? (
          <>
            <Button color="success" onClick={updateIngredient}>
              Save
            </Button>
            <Button color="secondary" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
          </>
        ) : (
          <Button color="secondary" onClick={() => setIsDetailModalOpen(false)}>
            Close
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

function getCsrfToken() {
  const name = "csrftoken=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default DetailModal;
