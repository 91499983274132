import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from "reactstrap";
import { toast } from 'react-toastify';
import axios from "axios";

const CancelSubscriptionModal = ({ isOpen, toggle, handleCancelSubscription, isOnDiscount }) => {
  const [step, setStep] = useState(1);
  const [survey, setSurvey] = useState({
    customer_service_rating: null,
    customer_service_feedback: '',
    app_features_rating: null,
    app_features_feedback: '',
    referral_source: '',
    cancellation_reason: '',
    other_feedback: ''
  });
  const [applyingDiscount, setApplyingDiscount] = useState(false);

  useEffect(() => {
    if (isOnDiscount) {
      setStep(3);  // Skip the discount step if already on discount
    }
  }, [isOnDiscount]);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSurvey({ ...survey, [name]: value });
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleApplyDiscount = async () => {
    setApplyingDiscount(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/apply-discount/`, {}, {
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      });
      toast.success("Discount applied successfully!");
      setApplyingDiscount(false);
      handleNext();
    } catch (error) {
      console.error(error);
      toast.error("There was an error applying the discount.");
      setApplyingDiscount(false);
    }
  };

  const handleSubmit = async () => {
    // Save the survey data
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/api/survey/`, survey, {
        withCredentials: true,
        headers: {
          'X-CSRFToken': getCsrfToken(),
        },
      });
      handleCancelSubscription();
      toast.success("Subscription canceled successfully!");
      toggle();
    } catch (error) {
      console.error(error);
      toast.error("There was an error submitting your feedback.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Cancel Subscription</ModalHeader>
      <ModalBody>
        {step === 1 && (
          <div>
            <h5>We are sorry to see you go. Please let us know what happened:</h5>
            <FormGroup>
              <Label for="customer_service_rating">Customer Service Rating</Label>
              <Input type="select" name="customer_service_rating" id="customer_service_rating" onChange={handleChange}>
                <option value="">Select Rating</option>
                {[1, 2, 3, 4, 5].map(rating => (
                  <option key={rating} value={rating}>{rating}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="customer_service_feedback">Customer Service Feedback</Label>
              <Input type="textarea" name="customer_service_feedback" id="customer_service_feedback" onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="app_features_rating">App Features Rating</Label>
              <Input type="select" name="app_features_rating" id="app_features_rating" onChange={handleChange}>
                <option value="">Select Rating</option>
                {[1, 2, 3, 4, 5].map(rating => (
                  <option key={rating} value={rating}>{rating}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="app_features_feedback">App Features Feedback</Label>
              <Input type="textarea" name="app_features_feedback" id="app_features_feedback" onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="referral_source">How did you hear about us?</Label>
              <Input type="select" name="referral_source" id="referral_source" onChange={handleChange}>
                <option value="">Select Source</option>
                <option value="search">Search Engine</option>
                <option value="social">Social Media</option>
                <option value="ad">Online Advertisement</option>
                <option value="friend">Friend or Family</option>
                <option value="event">Event or Conference</option>
                <option value="other">Other</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="cancellation_reason">Why are you canceling?</Label>
              <Input type="select" name="cancellation_reason" id="cancellation_reason" onChange={handleChange}>
                <option value="">Select Reason</option>
                <option value="price">Too Expensive</option>
                <option value="features">Missing Features</option>
                <option value="usability">Difficult to Use</option>
                <option value="alternatives">Found Better Alternatives</option>
                <option value="support">Poor Customer Support</option>
                <option value="other">Other</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="other_feedback">Other Feedback</Label>
              <Input type="textarea" name="other_feedback" id="other_feedback" onChange={handleChange} />
            </FormGroup>
          </div>
        )}
        {step === 2 && !isOnDiscount && (
          <div>
            <h5>We value you as a customer! Would you like a 20% discount to stay?</h5>
            <p>Use the code: <strong>SAVE20</strong> at checkout to get 20% off your next billing cycle.</p>
            <Button color="primary" onClick={handleApplyDiscount} disabled={applyingDiscount}>
              {applyingDiscount ? 'Applying...' : 'Apply Discount'}
            </Button>
          </div>
        )}
        {step === 3 && (
          <div>
            <h5>Are you sure you want to cancel your subscription?</h5>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {step > 1 && <Button color="secondary" onClick={handlePrevious}>Previous</Button>}
        {step < 3 && <Button color="primary" onClick={handleNext}>Next</Button>}
        {step === 3 && <Button color="danger" onClick={handleSubmit}>Confirm Cancellation</Button>}
        <Button color="secondary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelSubscriptionModal;
