import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { toast } from 'react-toastify';

const IngredientInventoryDetailModal = ({
  isOpen,
  toggle,
  ingredientInventoryId,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [ingredientInventoryDetail, setIngredientInventoryDetail] =
    useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [storeDetails, setStoreDetails] = useState(null);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setEditedValues({
      ...editedValues,
      [e.target.name]: value,
    });
  };

  const getCsrfToken = () => {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
  };

  const fetchStoreDetails = async (storeId) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const storeDetails = data.find((store) => store.id === storeId);
      setStoreDetails(storeDetails);
    } catch (error) {
      console.error("Error fetching store details:", error);
    }
  };

  const fetchIngredientInventoryDetail = async () => {
    if (ingredientInventoryId) {
      try {
        const csrfToken = getCsrfToken();
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient-inventory-detail/${ingredientInventoryId}/`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setIngredientInventoryDetail(data);
        setEditedValues(data);
      } catch (error) {
        console.error("Error fetching ingredient inventory detail:", error);
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      const csrfToken = getCsrfToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient-inventory-detail/${ingredientInventoryId}/`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(editedValues),
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      console.log("Ingredient inventory updated successfully");
      setIsEditMode(false);
      toast.success('Ingredient inventory updated successfully');
      fetchIngredientInventoryDetail();
    } catch (error) {
      console.error("Error updating ingredient inventory:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchIngredientInventoryDetail();
    }
  }, [isOpen, ingredientInventoryId]);

  useEffect(() => {
    if (ingredientInventoryDetail) {
      fetchStoreDetails(ingredientInventoryDetail.store);
    }
  }, [ingredientInventoryDetail]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Ingredient Inventory Details</ModalHeader>
      <ModalBody>
        {ingredientInventoryDetail ? (
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  Basic Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Additional Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggleTab("3");
                  }}
                >
                  Recall Details
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="store">Store</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="store"
                          id="store"
                          value={editedValues.store || ""}
                          onChange={handleInputChange}
                        />
                      ) : storeDetails ? (
                        <p>{storeDetails.name}</p>
                      ) : (
                        <p>{ingredientInventoryDetail.store}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="ingredient">Ingredient</Label>
                      <p>{ingredientInventoryDetail.ingredient_name}</p>
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight">Weight</Label>
                      {isEditMode ? (
                        <Input
                          type="number"
                          name="weight"
                          id="weight"
                          value={editedValues.weight || 0}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.weight}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="packaging_type">Packaging Type</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="packaging_type"
                          id="packaging_type"
                          value={editedValues.packaging_type || ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.packaging_type}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="quantity">Quantity</Label>
                      {isEditMode ? (
                        <Input
                          type="number"
                          name="quantity"
                          id="quantity"
                          value={editedValues.quantity || 0}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.quantity}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="weight_units">Weight Units</Label>
                      {isEditMode ? (
                        <Input
                          type="select"
                          name="weight_units"
                          id="weight_units"
                          value={editedValues.weight_units || "lb"}
                          onChange={handleInputChange}
                        >
                          <option value="lb">lb</option>
                          <option value="gallon">gallon</option>
                          <option value="gram">gram</option>
                          <option value="oz">oz</option>
                          <option value="kg">kg</option>
                        </Input>
                      ) : (
                        <p>{ingredientInventoryDetail.weight_units}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="serial_number">Serial Number</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="serial_number"
                          id="serial_number"
                          value={editedValues.serial_number || ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.serial_number}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="expiration_date">Expiration Date</Label>
                      {isEditMode ? (
                        <Input
                          type="date"
                          name="expiration_date"
                          id="expiration_date"
                          value={editedValues.expiration_date || ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.expiration_date}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="notes">Notes</Label>
                      {isEditMode ? (
                        <Input
                          type="textarea"
                          name="notes"
                          id="notes"
                          value={editedValues.notes || ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.notes}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="price">Price</Label>
                      {isEditMode ? (
                        <Input
                          type="number"
                          name="price"
                          id="price"
                          value={editedValues.price || 0}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          $
                          {ingredientInventoryDetail.price
                            ? parseFloat(
                                ingredientInventoryDetail.price
                              ).toFixed(2)
                            : "0.00"}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="is_active">Is Active</Label>
                      {isEditMode ? (
                        <Input
                          type="checkbox"
                          name="is_active"
                          id="is_active"
                          checked={editedValues.is_active}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          {ingredientInventoryDetail.is_active ? "Yes" : "No"}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="is_recalled">Is Recalled</Label>
                      {isEditMode ? (
                        <Input
                          type="checkbox"
                          name="is_recalled"
                          id="is_recalled"
                          checked={editedValues.is_recalled}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          {ingredientInventoryDetail.is_recalled ? "Yes" : "No"}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="recall_date">Recall Date</Label>
                      {isEditMode ? (
                        <Input
                          type="date"
                          name="recall_date"
                          id="recall_date"
                          value={editedValues.recall_date || ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.recall_date}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="is_flagged">Is Flagged</Label>
                      {isEditMode ? (
                        <Input
                          type="checkbox"
                          name="is_flagged"
                          id="is_flagged"
                          checked={editedValues.is_flagged}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          {ingredientInventoryDetail.is_flagged ? "Yes" : "No"}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="barcode">Barcode</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="barcode"
                          id="barcode"
                          value={editedValues.barcode || ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{ingredientInventoryDetail.barcode}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            {isEditMode ? (
              <>
                <Button color="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setIsEditMode(false)}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button color="primary" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            )}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </ModalBody>
    </Modal>
  );
};

export default IngredientInventoryDetailModal;
