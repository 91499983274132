import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./views/examples/PrivateRoute";
import FirebaseMessagingSetup from "./FirebaseMessagingSetup";
import Modal from "react-modal";
import * as Sentry from "@sentry/react";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/scss/argon-dashboard-react.scss';

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Initialize Sentry with the new configuration
Sentry.init({
  dsn: "https://96abc8ff16daa995aab9398778f63bbd@o4506532521574400.ingest.us.sentry.io/4507241384706048",
  integrations: [
    new Sentry.BrowserTracing(),   // Corrected usage of BrowserTracing
    new Sentry.Replay(),           // Corrected usage of Replay integration
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // Sample rate at 10%
  replaysOnErrorSampleRate: 1.0, // Sample rate at 100% on errors
});

// Get the container element
const container = document.getElementById("root");
Modal.setAppElement(container); // Set the app element for react-modal

// Create a root and render the app
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <FirebaseMessagingSetup />
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<PrivateRoute><AdminLayout /></PrivateRoute>} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  </React.StrictMode>
);
