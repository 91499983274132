import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Container,
  Row,
  Input,
  Button,
  Table,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import axios from "axios";
import "../../assets/css/batch_list.css";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faPlus,
  faBarcode,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as solidFaStar } from "@fortawesome/free-solid-svg-icons"; // Solid star
import { faStar as regularFaStar } from "@fortawesome/free-regular-svg-icons"; // Regular star
import BatchCreationModal from "./BatchCreationModal";
import BatchDetailModal from "./BatchDetailModal";
import BarcodeGenerator from "./BarcodeGenerator";
import LoadingSpinner from "./LoadingSpinner";

const BatchInventory = () => {
  const [barcodeModalOpen, setBarcodeModalOpen] = useState(false);
  const [selectedBarcode, setSelectedBarcode] = useState("");
  const [batches, setBatches] = useState([]);
  const [filteredBatches, setFilteredBatches] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [supplies, setSupplies] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [batchDetail, setBatchDetail] = useState({ products: [] });
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);
  const barcodeRef = useRef(null);
  const [batchCode, setBatchCode] = useState("");
  const [serialNumbers, setSerialNumbers] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchStores();
    fetchProducts();
    fetchSupplies();
    fetchBatches();
  }, [startDate, endDate]);

  const getCsrfToken = () => {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const handleProductCreated = () => {
    fetchProducts();
  };

  const refreshProducts = () => {
    fetchProducts();
  };

  const handleDetailModalSave = () => {
    setIsDetailModalOpen(false);
    fetchBatches();
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const displayIsRecalled = (isRecalled) => {
    return isRecalled ? (
      <span style={{ fontWeight: "bold", color: "red" }}>Recalled</span>
    ) : (
      "No"
    );
  };

  const toggleDetailModal = () => {
    setIsDetailModalOpen(!isDetailModalOpen);
  };

  const toggleCreationModal = () => {
    setIsCreationModalOpen(!isCreationModalOpen);
  };

  const fetchStores = async () => {
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken },
        }
      );
      setStores(
        response.data.map((store) => ({ label: store.name, value: store.id }))
      );
    } catch (error) {
      console.error("Error fetching stores:", error);
      toast.error("Failed to fetch stores.");
    }
  };

  const fetchBatches = async () => {
    setIsLoading(true); // Set loading to true before fetching
    try {
      const csrfToken = getCsrfToken();
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate);
      if (endDate) params.append("end_date", endDate);

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch-list/`,
        {
          params: params,
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken },
        }
      );

      console.log("batches:", response.data);
      setBatches(response.data);
      setFilteredBatches(response.data);
    } catch (error) {
      console.error("Error fetching batches:", error);
      toast.error("Failed to fetch batches.");
    } finally {
      setIsLoading(false); // Ensure loading is set to false after fetching
    }
  };

  const fetchProducts = async (callback) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-list/`,
        {
          headers: { "X-CSRFToken": csrfToken },
          withCredentials: true,
        }
      );
      setProducts(response.data.results);
      if (callback) {
        callback();
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products.");
    }
  };

  const fetchSupplies = async () => {
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-list/`,
        {
          withCredentials: true,
          headers: { "X-CSRFToken": csrfToken },
        }
      );
      console.log("Fetched supplies data:", response.data);
      const mappedSupplies = response.data.map((supply) => ({
        label: supply.name,
        value: supply.id,
      }));
      setSupplies(mappedSupplies);
      console.log("Mapped supplies for Select:", mappedSupplies);
    } catch (error) {
      console.error("Error fetching supplies:", error);
      toast.error("Failed to fetch supplies.");
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    const filtered = batches.filter((batch) =>
      batch.batch_code.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredBatches(filtered);
  };

  const handleDeleteBatch = async (batchId) => {
    if (window.confirm("Are you sure you want to delete this batch?")) {
      const csrfToken = getCsrfToken();
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/delete/${batchId}/`,
          {
            headers: { "X-CSRFToken": csrfToken },
            withCredentials: true,
          }
        );
        toast.warn("Batch deleted successfully!");
        fetchBatches(); // Refresh the list of batches after deletion
      } catch (error) {
        console.error("Error deleting batch:", error);
        toast.error("Error deleting batch!");
      }
    }
  };

  const markAsFavorite = async (batchId) => {
    const csrfToken = getCsrfToken();
    const batchToUpdate = batches.find((batch) => batch.id === batchId);
    if (!batchToUpdate) {
      console.error("Batch not found");
      toast.error("Batch not found.");
      return;
    }

    const updatedFavoriteStatus = !batchToUpdate.is_favorite;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batches/${batchId}/mark_favorite/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          credentials: "include",
          body: JSON.stringify({ is_favorite: updatedFavoriteStatus }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Batch favorite status updated:", data);
        fetchBatches();
        toast.success(
          `Batch ${
            updatedFavoriteStatus
              ? "marked as favorite"
              : "unmarked as favorite"
          }.`
        );
      } else {
        console.error("Failed to update batch favorite status");
        toast.error("Failed to update batch favorite status.");
      }
    } catch (error) {
      console.error("Error updating batch favorite status:", error);
      toast.error("Error updating batch favorite status.");
    }
  };

  // Function to open barcode modal and set selected barcode
  const handleShowBarcode = (barcode) => {
    setSelectedBarcode(barcode);
    setBarcodeModalOpen(true);
  };

  const toggleBarcodeModal = () => {
    setBarcodeModalOpen(!barcodeModalOpen);
  };

  // Recall Batch
  const handleRecallBatch = async (batchId) => {
    if (window.confirm("Are you sure you want to recall this batch?")) {
      const csrfToken = getCsrfToken();
      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/batch/recall/${batchId}/`,
          null,
          {
            headers: { "X-CSRFToken": csrfToken },
            withCredentials: true,
          }
        );
        toast.warn("Batch recalled successfully!");
        fetchBatches(); // Refresh the list of batches after recall
      } catch (error) {
        console.error("Error recalling batch:", error);
        toast.error("Error recalling batch!");
      }
    }
  };

  const handleDetails = async (batchId) => {
    try {
      const csrfToken = getCsrfToken();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/batch-detail/${batchId}/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );

      const enrichedProductQuantities = response.data.product_quantities.map(
        (pq) => ({
          ...pq,
          ...products.find((p) => p.id === pq.product_id),
        })
      );

      console.log("Batch details fetched:", response.data);
      setBatchDetail({
        ...response.data,
        product_quantities: enrichedProductQuantities,
      });
      setIsDetailModalOpen(true);
    } catch (error) {
      console.error("Error fetching batch details:", error);
    }
  };

  const renderBatchRows = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBatches.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    return currentItems.map((batch) => (
      <tr key={batch.id}>
        <td>{batch.batch_code}</td>
        <td>{new Date(batch.date_created).toLocaleDateString()}</td>
        <td>{new Date(batch.expiry_date).toLocaleDateString()}</td>
        <td>{batch.store.name}</td>
        <td>{displayIsRecalled(batch.is_recalled)}</td>
        <td className="actions-cell">
          <Button
            color="primary"
            size="sm"
            onClick={() => handleDetails(batch.id)}
          >
            <FontAwesomeIcon icon={faCircleInfo} />
          </Button>
          <Button
            className={`fav-icon-button ${batch.is_favorite ? "favorite" : ""}`}
            onClick={() => markAsFavorite(batch.id)}
            size="sm"
            title="Mark as favorite"
          >
            <FontAwesomeIcon
              icon={batch.is_favorite ? solidFaStar : regularFaStar}
              style={{ color: batch.is_favorite ? "gold" : "#ccc" }}
            />
          </Button>
          <Button
            color="warning"
            size="sm"
            onClick={() => handleRecallBatch(batch.id)}
            disabled={batch.is_recalled}
          >
            Recall
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => handleDeleteBatch(batch.id)}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
          <Button
            color="info"
            size="sm"
            onClick={() => handleShowBarcode(batch.barcode || batch.batch_code)} // Display barcode based on availability or use batch code
          >
            <FontAwesomeIcon icon={faBarcode} />
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Button
                  className="custom-button"
                  onClick={() => {
                    fetchSupplies();
                    toggleCreationModal();
                  }}
                  title="Create New Batch"
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <FormGroup className="mb-0" style={{ width: "60%" }}>
                  <Input
                    type="text"
                    placeholder="Search Batches"
                    value={searchText}
                    onChange={handleSearchChange}
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginRight: "20px",
                    }}
                  />
                </FormGroup>
                <div
                  style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
                >
                  <FormGroup>
                    <Label
                      for="startDate"
                      style={{
                        display: "block",
                        marginTop: "35px",
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "#32325d",
                      }}
                    ></Label>
                    <Input
                      type="date"
                      name="startDate"
                      id="startDate"
                      className="argon-date-picker start-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "0.625rem 1.25rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.5,
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.25rem",
                        transition: "all 0.15s ease-in-out",
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label
                      for="endDate"
                      style={{
                        display: "block",
                        marginTop: "35px",
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "#32325d",
                      }}
                    ></Label>
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      className="argon-date-picker end-date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "0.625rem 1.25rem",
                        fontSize: "0.875rem",
                        lineHeight: 1.5,
                        color: "#8898aa",
                        backgroundColor: "#fff",
                        backgroundClip: "padding-box",
                        border: "1px solid #cad1d7",
                        borderRadius: "0.25rem",
                        transition: "all 0.15s ease-in-out",
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
              {isLoading ? (
                <LoadingSpinner />
              ) : filteredBatches.length > 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Batch Code</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Store</th>
                      <th scope="col">Is Recalled</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{renderBatchRows()}</tbody>
                </Table>
              ) : (
                <div className="text-center p-5">
                  <h6>No batches available</h6>
                </div>
              )}
              <nav>
                <ul className="pagination">
                  {Array.from(
                    {
                      length: Math.ceil(filteredBatches.length / itemsPerPage),
                    },
                    (_, i) => (
                      <li key={i + 1} className="page-item">
                        <Button
                          className="page-link"
                          onClick={() => setCurrentPage(i + 1)}
                        >
                          {i + 1}
                        </Button>
                      </li>
                    )
                  )}
                </ul>
              </nav>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modals for Detail View, Creation, and Barcode Display */}
      <BatchDetailModal
        isOpen={isDetailModalOpen}
        toggle={toggleDetailModal}
        batchDetail={batchDetail}
        onSave={handleDetailModalSave}
      />

      <BatchCreationModal
        isOpen={isCreationModalOpen}
        toggle={toggleCreationModal}
        stores={stores}
        products={products}
        supplies={supplies}
        fetchBatches={fetchBatches}
        refreshProducts={refreshProducts}
      />

      {/* Barcode Modal */}
      <Modal isOpen={barcodeModalOpen} toggle={toggleBarcodeModal}>
        <ModalHeader toggle={toggleBarcodeModal}>Batch Barcode</ModalHeader>
        <ModalBody>
          <BarcodeGenerator ref={barcodeRef} value={selectedBarcode} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default BatchInventory;
