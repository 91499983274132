import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Headers/Header.js";
import axios from "axios"; // Import Axios for making HTTP requests
import "../../assets/css/recipe_list.css";
import classnames from "classnames";
import Select from "react-select";
import IngredientModal from "./IngredientModal";
import "../../assets/css/recipe_list_modal.css";
import { toast } from "react-toastify";
import NutritionLabel from "./NutritionLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarcodeGenerator from "./BarcodeGenerator";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { nutrientMapping } from "./nutrientMapping";
import { useNavigate } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import LoadingSpinner from "./LoadingSpinner"; // Adjust the path as necessary
import {
  faAppleAlt,
  faTag,
  faTrash,
  faCircleInfo,
  faInfoCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons"; // Import the apple icon
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  Alert,
  Card,
  Container,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
} from "reactstrap";
import { min } from "lodash";

const RecipeList = () => {
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableRecipe, setEditableRecipe] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [editableIngredientId, setEditableIngredientId] = useState(null);
  const [isIngredientModalOpen, setIngredientModalOpen] = useState(false);
  const [currentRecipeId, setCurrentRecipeId] = useState(null);
  const [currentRecipeName, setCurrentRecipeName] = useState("");
  const [isNutritionModalOpen, setNutritionModalOpen] = useState(false);
  const [selectedNutritionData, setSelectedNutritionData] = useState(null);
  const [barcodeModal, setBarcodeModal] = useState(false);
  const [barcodeBase64, setBarcodeBase64] = useState("");
  const [barcodeGenerated, setBarcodeGenerated] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [recipeCache, setRecipeCache] = useState({});
  const [page, setPage] = useState(1); // State for pagination
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const [isEditRecipeModalOpen, setIsEditRecipeModalOpen] = useState(false);
  const [selectedTagFilter, setSelectedTagFilter] = useState("");
  const [tagMap, setTagMap] = useState({});
  const [tagSuggestions, setTagSuggestions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // Calculate Grand total Cost of Recipe
  const calculateGrandTotal = (recipe) => {
    const { labor_cost, overhead_cost, total_cost } = recipe;
    // Summing up all the costs to get the grand total
    return (
      (labor_cost || 0) +
      (overhead_cost || 0) +
      (parseFloat(total_cost) || 0)
    ) // Ensure total_cost is a float
      .toFixed(2); // Rounds to two decimal places
  };

  // Fetch recipes with caching
  const fetchRecipes = (page = 1) => {
    if (recipeCache[page]) {
      setRecipes((prevRecipes) =>
        deduplicateRecipes([...prevRecipes, ...recipeCache[page]])
      );
      return;
    }

    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipes-list/?page=${page}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      )
      .then((response) => {
        const newRecipes = response.data; // Directly accessing response.data as an array
        if (Array.isArray(newRecipes)) {
          setRecipes((prevRecipes) =>
            deduplicateRecipes([...prevRecipes, ...newRecipes])
          );
          setRecipeCache((prevCache) => ({ ...prevCache, [page]: newRecipes }));
          // Assuming you have a way to determine if there are more pages
          setHasMore(newRecipes.length > 0);
        } else {
          console.error("Unexpected response format:", response.data);
          toast.error("Unexpected response format.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching recipes: ", error);
        console.error("Error fetching recipes: ", error);
        setIsLoading(false);
      });
  };

  // Helper function to remove duplicates
  const deduplicateRecipes = (recipes) => {
    const seen = new Set();
    return recipes.filter((recipe) => {
      const isDuplicate = seen.has(recipe.id);
      seen.add(recipe.id);
      return !isDuplicate;
    });
  };

  useEffect(() => {
    fetchRecipes(page);
  }, [page]);

  // Function to handle loading more recipes when the user scrolls to the bottom
  const loadMoreRecipes = () => {
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Add event listener for scrolling to load more recipes
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [recipes, isLoading, hasMore]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    loadMoreRecipes();
  };

  // Unified fetchTags function
  const fetchTags = (input = "") => {
    const searchParam = input ? `?search=${encodeURIComponent(input)}` : "";
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/tags/${searchParam}`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": getCsrfToken(),
          },
        }
      )
      .then((response) => {
        const newTagMap = {};
        const newTagOptions = [];
        response.data.forEach((tag) => {
          newTagMap[tag.id] = {
            id: tag.id,
            name: tag.name,
            color: tag.color,
          };
          newTagOptions.push({
            value: tag.id,
            label: tag.name,
          });
        });
        setTagMap(newTagMap);
        setTagSuggestions(newTagOptions); // This will be used for Select component
      })
      .catch((error) => {
        console.error("Error fetching tags:", error);
        toast.error("Error fetching tags:", error);
      });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  // Example usage for search
  // This can be triggered by a search input field or similar interaction
  const handleSearchTags = (searchTerm) => {
    fetchTags(searchTerm);
  };

  // Redirect to Create Recipe on Table.js component
  const redirectToTables = () => {
    navigate("/admin/tables");
  };

  const UNIT_CHOICES = [
    { value: "cup", label: "cup" },
    { value: "lb", label: "lb" },
    { value: "gallon", label: "gallon" },
    { value: "gram", label: "gram" },
    { value: "oz", label: "oz" },
    { value: "kg", label: "kg" },
    { value: "tbsp", label: "tbsp" },
  ];

  // Converts units, using grams as universal reference point.
  const conversionRates = {
    cup: 240, // Assuming 1 cup = 240 grams for this example
    lb: 453.592, // 1 lb = 453.592 grams
    gallon: 3785.41, // 1 gallon = 3785.41 grams
    oz: 28.3495, // 1 oz = 28.3495 grams
    kg: 1000, // 1 kg = 1000 grams
    tbsp: 14.787, // 1 tablespoon = 14.787 grams
    // gram is the base unit, so no conversion needed for grams
  };

  // Converts units to grams.
  function convertToGrams(quantity, unit) {
    // For grams, return the quantity as is
    if (unit === "gram") return quantity;
    // Otherwise, convert using the conversionRates
    const conversionRate = conversionRates[unit];
    return quantity * conversionRate;
  }

  // Ref for the content you want to print or download as PDF
  const printRef = useRef();

  // Function to handle the print action
  const handlePrint = () => {
    window.print();
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Example of setting barcodeValue with dependency on recipeId
  useEffect(() => {
    if (currentRecipeId) {
      const uniqueValue = `Recipe-${currentRecipeId}`;
      setBarcodeValue(uniqueValue);
    }
  }, [currentRecipeId]); // Dependency array ensures this runs when currentRecipeId changes

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; ca.length > i; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const handleViewNutritionClick = () => {
    setNutritionModalOpen(true);
  };

  const addIngredient = (recipeId, ingredientData) => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/core/api/add-recipe-ingredient/`;
    const method = "post";

    // Ensure ingredient_detail is the ID of the ingredient
    const dataToSend = {
      ...ingredientData,
      recipe: recipeId,
      ingredient_detail: ingredientData.ingredient,
    };

    const csrfToken = getCsrfToken();

    return axios({
      method: method,
      url: endpoint,
      data: dataToSend,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      withCredentials: true,
    })
      .then((response) => {
        toast.success("Ingredient added successfully!");
        setRecipes((prevRecipes) => {
          return prevRecipes.map((recipe) => {
            if (recipe.id === recipeId) {
              return {
                ...recipe,
                recipe_ingredients: [
                  ...recipe.recipe_ingredients,
                  response.data,
                ],
              };
            }
            return recipe;
          });
        });
        return response.data;
      })
      .catch((error) => {
        toast.error(`Error adding ingredient: ${error.message}`);
        console.error("Error adding ingredient", error);
        throw error;
      });
  };

  // Open ingredient modal for a specific recipe
  const openIngredientModal = (recipeId) => {
    const selected = recipes.find((recipe) => recipe.id === recipeId);
    if (selected) {
      setCurrentRecipeId(recipeId);
      console.log("setCurrentRecipeId called with:", recipeId);

      setCurrentRecipeName(selected.name); // Set the name from the found recipe
      setIngredientModalOpen(true);
    } else {
      console.error("Recipe not found!");
      toast.error("Recipe not found!");
      // Handle the case where the recipe is not found, if necessary
    }
  };

  // Close the ingredient modal
  const closeIngredientModal = () => {
    setIngredientModalOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/recipes-list/`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
        },
      })
      .then((response) => {
        setRecipes(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(`Error fetching data: ${error.message}`);
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      });
  }, []);

  const fetchIngredients = async (recipeId) => {
    if (!recipeId) {
      console.error("fetchIngredients was called without a valid recipeId.");
      return [];
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe-ingredients/${recipeId}/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );

      setRecipeIngredients(response.data);
      return response.data;
    } catch (error) {
      toast.error(`Error fetching ingredients: ${error.message}`);
      console.error("Error fetching ingredients: ", error);
      return [];
    }
  };

  // Extract ingredetin names for Label
  const extractIngredientList = (data) => {
    // Ensure data is always treated as an array
    const ingredients = data?.recipe_ingredients || [];
    return ingredients
      .map((ri) => ri.ingredient_details.ingredient_description)
      .join(", ");
  };

  const fetchRecipeDetails = async (recipeId) => {
    if (!recipeId) {
      console.error("fetchRecipeDetails was called without a valid recipeId.");
      return {
        servingSize: "N/A",
        servingsPerContainer: "N/A",
        suggestedServingSize: "N/A",
        fda_approved_serving_size: "N/A",
        allergens: "N/A",
      };
    }
  
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipes-detail/${recipeId}/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
  
      const {
        fda_approved_serving_size,
        servings_per_container,
        sugested_serving_size,
        allergens,
      } = response.data;
  
      return {
        servingSize: fda_approved_serving_size || "N/A",
        servingsPerContainer: servings_per_container || "N/A",
        suggestedServingSize: sugested_serving_size || "N/A",
        allergens: allergens || "N/A",
      };
    } catch (error) {
      toast.error(`Error fetching recipe details: ${error.message}`);
      console.error("Error fetching recipe details:", error);
      return {
        servingSize: "N/A",
        servingsPerContainer: "N/A",
        suggestedServingSize: "N/A",
        fda_approved_serving_size: "N/A",
        allergens: "N/A",
      };
    }
  };
  

  const toggleExpand = async (recipe) => {
    if (selectedRecipe.id === recipe.id) {
      setSelectedRecipe({});
      setCurrentRecipeName("");
      setSelectedNutritionData(null);
      setCurrentRecipeId(null);
    } else {
      setSelectedRecipe(recipe);
      setCurrentRecipeName(recipe.name);
      setCurrentRecipeId(recipe.id);
  
      try {
        const fetchedIngredients = await fetchIngredients(recipe.id);
        const nutritionFacts = calculateNutritionFactsAndDV(fetchedIngredients);
        const details = await fetchRecipeDetails(recipe.id);
        const ingredientNames = extractIngredientList(recipe);
  
        setSelectedNutritionData({
          ...nutritionFacts,
          ...details,
          allergens: details.allergens,
          ingredientList: [ingredientNames],
        });
      } catch (error) {
        console.error("Error fetching recipe details:", error);
        toast.error(`Error fetching recipe details: ${error.message}`);
      }
    }
  };
  

  const handleEditClick = (recipe, event) => {
    event.stopPropagation(); // Prevents the click from reaching the row
    // Instead of opening the modal, expand the recipe details as if the row was clicked
    toggleExpand(recipe);
  };

  useEffect(() => {
    // Filter recipes based on the search term and selected tag filter
    if (searchTerm === "" && selectedTagFilter === "") {
      setFilteredRecipes(recipes);
    } else {
      setFilteredRecipes(
        recipes.filter((recipe) => {
          const matchesSearchTerm = recipe.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          const matchesTagFilter =
            selectedTagFilter === "" ||
            recipe.tags.includes(parseInt(selectedTagFilter));
          return matchesSearchTerm && matchesTagFilter;
        })
      );
    }
  }, [searchTerm, recipes, selectedTagFilter]);

  // Save edited ingredintes that belong to a recipe
  const saveIngredient = async (recipeIngredientId) => {
    // Find the specific recipe ingredient to update
    const recipeIngredientToUpdate = recipeIngredients.find(
      (ingredient) => ingredient.id === recipeIngredientId
    );
  
    if (!recipeIngredientToUpdate) {
      console.error(
        "Recipe ingredient to update not found:",
        recipeIngredientId
      );
      toast.error("Ingredient not found."); // Display an error toast
      return;
    }
  
    const payload = {
      ingredient: recipeIngredientToUpdate.ingredient_details.id,
      quantity_used: recipeIngredientToUpdate.quantity_used.toString(),
      units: recipeIngredientToUpdate.units,
      waste: recipeIngredientToUpdate.waste,
      total_cost: recipeIngredientToUpdate.total_cost,
      allergens: recipeIngredientToUpdate.allergens,
    };
  
    const csrfToken = getCsrfToken(); // Retrieve CSRF token
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe-ingredient-edit/${recipeIngredientId}/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          withCredentials: true,
        }
      );
  
      console.log("Ingredient updated successfully:", response.data);
      toast.success("Ingredient updated successfully!"); // Display a success toast
  
      // Refetch ingredients and nutrition data
      const fetchedIngredients = await fetchIngredients(currentRecipeId);
      const updatedNutritionData = calculateNutritionFactsAndDV(fetchedIngredients);
      const updatedDetails = await fetchRecipeDetails(currentRecipeId);
      const ingredientNames = extractIngredientList(fetchedIngredients);
  
      setRecipeIngredients(fetchedIngredients);
      setSelectedNutritionData({
        ...updatedNutritionData,
        ...updatedDetails,
        allergens: updatedDetails.allergens,
        ingredientList: [ingredientNames],
      });
  
      setEditableIngredientId(null); // Exit edit mode
    } catch (error) {
      console.error(
        "Error updating ingredient:",
        error.response ? error.response.data : error
      );
      toast.error("Failed to update ingredient."); // Display an error toast
    }
  };
  
  

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const handleIngredientChange = (id, field, value) => {
    setRecipeIngredients((ingredients) =>
      ingredients.map((ingredient) =>
        ingredient.id === id ? { ...ingredient, [field]: value } : ingredient
      )
    );
  };

  const refreshIngredients = async () => {
    if (currentRecipeId) {
      const fetchedIngredients = await fetchIngredients(currentRecipeId);
      // Assuming fetchIngredients now sets the state for recipeIngredients directly.
      // Recalculate nutrition facts
      const nutritionFacts = calculateNutritionFactsAndDV(fetchedIngredients);
      // Fetch additional details if needed, for example, serving size and servings per container
      console.log("Fetching 2nd details for Recipe ID:", currentRecipeId);
      const recipeDetails = await fetchRecipeDetails(currentRecipeId);
      // Combine fetchedIngredients, nutritionFacts, and recipeDetails as needed
      setSelectedNutritionData({
        ...nutritionFacts,
        ...recipeDetails, // Merge additional details if necessary
      });
    }
  };

  const handleDeleteIngredient = (ingredientId) => {
    // Confirmation dialog
    if (window.confirm("Are you sure you want to delete this ingredient?")) {
      const csrfToken = getCsrfToken(); // Get the CSRF token

      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe_ingredient/delete/${ingredientId}/`,
        headers: {
          "X-CSRFToken": csrfToken, // Include CSRF token in the request header
        },
        withCredentials: true, // Include credentials in the request
      })
        .then((response) => {
          // Handle the successful deletion
          setRecipeIngredients((currentIngredients) =>
            currentIngredients.filter(
              (ingredient) => ingredient.id !== ingredientId
            )
          );
          // Display a success toast message with a warning theme
          toast.warn("Ingredient deleted successfully!", {
            // Customizing the toast
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored", // Use the 'colored' theme for more emphasis
          });
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Error deleting ingredient:", error);
          // Display an error toast message
          toast.error("Failed to delete ingredient.", {
            // Customizing the toast
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored", // Use the 'colored' theme for more emphasis
          });
        });
    } else {
      // User canceled the deletion
      console.log("Ingredient deletion cancelled.");
    }
  };

  // Function to convert nutreins to readable format.
  function formatNutrientName(name) {
    // Split the name into words based on camelCase or underscores
    const words = name
      .replace(/([A-Z])/g, " $1")
      .replace(/_/g, " ")
      .trim();

    // Capitalize the first letter of each word
    return words
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // FDA's reference Daily Values (DVs) for common nutrients
  const FDA_DVs = {
    calories: 2000,
    totalFat: 78, // g
    saturatedFat: 20, // g
    Cholesterol: 300, // mg
    sodium: 2300, // mg
    totalCarbohydrates: 275, // g
    dietaryFiber: 28, // g
    totalSugars: 50, // g
    protein: 50, // g,
    vitaminD: 20, // mcg
    calcium: 1300, // mg
    iron: 18, // mg
    potassium: 4700, // mg
    addedSugars: 50, // g
  };

  // Function to calculate nutrition facts and %DV for a recipe
  function calculateNutritionFactsAndDV(recipeIngredients) {
    let nutritionSums = {
      calories: 0,
      totalFat: 0,
      saturatedFat: 0,
      cholesterol: 0,
      sodium: 0,
      totalCarbohydrates: 0,
      dietaryFiber: 0,
      totalSugars: 0,
      protein: 0,
      vitaminD: 0,
      calcium: 0,
      iron: 0,
      potassium: 0,
      addedSugars: 0,
    };

    const energyPriority = [
      "Energy",
      "Energy (Atwater Specific Factors)",
      "Energy (Atwater General Factors)",
    ];
    const carbPriority = ["Carbohydrates", "Carbohydrate, by difference"];

    let totalRecipeWeight = 0;

    recipeIngredients.forEach((ingredient, index) => {
      const quantityInGrams = convertToGrams(
        ingredient.quantity_used,
        ingredient.units
      );
      totalRecipeWeight += quantityInGrams;
      console.log(
        `Ingredient #${index + 1}:`,
        ingredient.ingredient_details.ingredient_description
      );
      console.log(
        `Original Quantity: ${ingredient.quantity_used} ${ingredient.units}, Converted to Grams: ${quantityInGrams}g`
      );

      // Energy calculation with priority
      let energyAdded = false;
      energyPriority.forEach((energyType) => {
        if (!energyAdded) {
          const energyNutrient = ingredient.ingredient_details.nutrients.find(
            (n) => n.name === energyType
          );
          if (energyNutrient) {
            console.log(
              `Using ${energyType} for calories: ${energyNutrient.amount} KCAL`
            );
            nutritionSums.calories +=
              (quantityInGrams * energyNutrient.amount) / 100;
            energyAdded = true;
          }
        }
      });

      // Carbohydrate calculation with priority
      let carbsAdded = false;
      carbPriority.forEach((carbType) => {
        if (!carbsAdded) {
          const carbNutrient = ingredient.ingredient_details.nutrients.find(
            (n) => n.name === carbType
          );
          if (carbNutrient) {
            console.log(
              `Using ${carbType} for total carbohydrates: ${carbNutrient.amount} G`
            );
            nutritionSums.totalCarbohydrates +=
              (quantityInGrams * carbNutrient.amount) / 100;
            carbsAdded = true;
          }
        }
      });

      // Other nutrients calculation, excluding energy and carbohydrates already processed
      ingredient.ingredient_details.nutrients.forEach((nutrient) => {
        let mappedNutrient = nutrientMapping[nutrient.name];
        if (
          mappedNutrient &&
          nutritionSums.hasOwnProperty(mappedNutrient) &&
          !energyPriority.includes(nutrient.name) &&
          !carbPriority.includes(nutrient.name)
        ) {
          let nutrientAmount = (quantityInGrams * nutrient.amount) / 100;
          nutritionSums[mappedNutrient] += nutrientAmount;
          console.log(
            `Adding ${nutrient.name}: ${nutrient.amount}, Converted Amount: ${nutrientAmount}`
          );
        }
      });
    });

    // Ensure suggestedServingSize and numberOfServings exist and are valid
    let suggestedServingSize = recipeIngredients[0]?.sugested_serving_size || 1;
    let numberOfServings = recipeIngredients[0]?.number_of_servings || 1;

    // Calculate the scaling factor based on the total recipe weight and the suggested serving size
    let scalingFactor =
      (suggestedServingSize * numberOfServings) / totalRecipeWeight;

    // Scale the nutrition sums to the suggested serving size
    Object.keys(nutritionSums).forEach((nutrient) => {
      nutritionSums[nutrient] *= scalingFactor;
    });

    // Calculate %DV and format the output
    let nutritionFactsWithDV = {};
    console.log("Nutrition Sums Scaled to Serving Size:", nutritionSums);
    Object.entries(nutritionSums).forEach(([nutrient, amount]) => {
      const dv = FDA_DVs[nutrient];
      const percentDV = dv ? ((amount / dv) * 100).toFixed(0) : null;
      nutritionFactsWithDV[nutrient] = {
        amount: amount.toFixed(0),
        percentDV: percentDV !== null ? `${percentDV}` : "0",
      };
      console.log(
        `${nutrient}: ${amount.toFixed(0)} (${
          percentDV !== null ? `${percentDV}%` : "N/A"
        } DV)`
      );
    });

    return nutritionFactsWithDV;
  }

  // Ref for barcodeGenerator
  const barcodeSvgRef = useRef(null);

  // Create Barcode
  // Adjust the handleGenerateBarcodeClick to accept a parameter
  const handleGenerateBarcodeClick = (id) => {
    if (!id) {
      toast.error("Error: No recipe selected.");
      return;
    }
    const uniqueValue = `Recipe-${id}`;
    setBarcodeValue(uniqueValue);
    setBarcodeModal(true);
  };

  useEffect(() => {
    const updateNutritionData = async () => {
      try {
        if (!recipeIngredients.length) {
          setSelectedNutritionData(null);
          return;
        }

        const updatedNutritionData =
          calculateNutritionFactsAndDV(recipeIngredients);
        console.log("Fetching details for Recipe ID:", currentRecipeId);

        const recipeDetails = await fetchRecipeDetails(currentRecipeId);
        const ingredientNames = extractIngredientList(recipeDetails);

        const updatedSelectedNutritionData = {
          ...updatedNutritionData,
          ...recipeDetails,
          ingredientList: ingredientNames.split(","),
        };

        setSelectedNutritionData(updatedSelectedNutritionData);
        console.log(
          "selectedNutritionData after setting:",
          updatedSelectedNutritionData
        );
      } catch (error) {
        console.error("Failed to update nutrition data:", error);
        // Handle the error appropriately, maybe set an error state
      }
    };

    updateNutritionData();
  }, [recipeIngredients, currentRecipeId]);
  // Delete Recipe
  const handleDeleteRecipe = (recipeId) => {
    if (window.confirm("Are you sure you want to delete this recipe?")) {
      const csrfToken = getCsrfToken(); // Reuse your existing CSRF token retrieval logic

      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe/${recipeId}/delete/`,
        headers: {
          "X-CSRFToken": csrfToken,
        },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Recipe deleted successfully!");
          // Remove the recipe from the state to update the UI
          setRecipes((prevRecipes) =>
            prevRecipes.filter((recipe) => recipe.id !== recipeId)
          );
        })
        .catch((error) => {
          console.error("Error deleting recipe:", error);
          toast.error("Failed to delete recipe.");
        });
    }
  };

  const handleOpenEditModal = (recipe) => {
    const existingTags = recipe.tags
      .map((tagId) => {
        const tagDetail = tagMap[tagId];
        return tagDetail ? { value: tagId, label: tagDetail.name } : null;
      })
      .filter((tag) => tag !== null);

    setSelectedTags(existingTags);
    setEditableRecipe(recipe);
    setIsEditRecipeModalOpen(true);
  };

  const updateEditableRecipe = (name, value) => {
    setEditableRecipe((prevEditableRecipe) => ({
      ...prevEditableRecipe,
      [name]: value,
    }));
  };

  const handleUpdateRecipe = (updatedRecipeData) => {
    const csrfToken = getCsrfToken();
    const url = `${process.env.REACT_APP_API_BASE_URL}/core/api/recipe-edit/${updatedRecipeData.id}/`;
  
    const payload = {
      name: updatedRecipeData.name,
      description: updatedRecipeData.description,
      internal_code: updatedRecipeData.internal_code,
      labor_cost: updatedRecipeData.labor_cost,
      overhead_cost: updatedRecipeData.overhead_cost,
      is_active: updatedRecipeData.is_active,
      is_recalled: updatedRecipeData.is_recalled,
      date_recalled: updatedRecipeData.date_recalled,
      qty_produced: updatedRecipeData.qty_produced,
      notes: updatedRecipeData.notes,
      packages_per_batch: updatedRecipeData.packages_per_batch,
      sugested_serving_size: updatedRecipeData.sugested_serving_size,
      units: updatedRecipeData.units || "gram",
      is_favorite: updatedRecipeData.is_favorite,
      fda_approved_serving_size: updatedRecipeData.fda_approved_serving_size,
      number_of_servings: updatedRecipeData.number_of_servings,
      servings_per_container: updatedRecipeData.servings_per_container,
      tags: updatedRecipeData.tags.length > 0 ? updatedRecipeData.tags : [],
      creation_date: updatedRecipeData.creation_date,
      total_cost: parseFloat(updatedRecipeData.total_cost).toFixed(2),
      allergens: updatedRecipeData.allergens,
    };
  
    axios({
      method: "put",
      url: url,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      withCredentials: true,
    })
      .then((response) => {
        toast.success("Recipe updated successfully!");
        setIsEditRecipeModalOpen(false);
  
        // Fetch the updated recipe data
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/core/api/recipes-detail/${updatedRecipeData.id}/`,
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCsrfToken(),
              },
            }
          )
          .then((response) => {
            const updatedRecipe = response.data;
  
            // Update the recipes state with the updated recipe
            setRecipes((prevRecipes) =>
              prevRecipes.map((recipe) =>
                recipe.id === updatedRecipe.id ? updatedRecipe : recipe
              )
            );
  
            // Update the selectedRecipe if it's the same as the updated recipe
            setSelectedRecipe((prevSelectedRecipe) =>
              prevSelectedRecipe.id === updatedRecipe.id
                ? updatedRecipe
                : prevSelectedRecipe
            );
          })
          .catch((error) => {
            console.error("Error fetching updated recipe:", error);
            toast.error("Failed to fetch updated recipe.");
          });
      })
      .catch((error) => {
        console.error("Error updating recipe:", error);
        toast.error("Failed to update recipe.");
      });
  };
  
  

  // Define the SERVING_SIZES for the modal Recipe Edit
  const SERVING_SIZES = [
    { value: "TSP", label: "teaspoon" },
    { value: "PAT", label: "pat (1 inch sq, 1/2 inch high)" },
    { value: "STICK", label: "stick" },
    { value: "MG", label: "milligram" },
    { value: "G", label: "gram" },
    { value: "OZ", label: "ounce" },
    { value: "LB", label: "pound" },
    { value: "KG", label: "kilogram" },
    { value: "TBSP", label: "tablespoon" },
    { value: "FL_OZ", label: "fluid ounce" },
    { value: "ML", label: "milliliter" },
    { value: "CUP", label: "cup" },
    { value: "PINT", label: "pint" },
    { value: "QT", label: "quart" },
    { value: "L", label: "liter" },
    { value: "GAL", label: "gallon" },
    { value: "TBS", label: "tabsp(s)" },
    { value: "CUPS", label: "cup(s)" },
    { value: "SLICE", label: "slice" },
    { value: "UNIT", label: "unit" },
  ];

  // Modal to Edit Overall recipe Settings, the nutreints are edited on the dynamic table.
  const RecipeModal = ({ isOpen, toggle, recipe, mode, onSave }) => {
    const [activeTab, setActiveTab] = useState("1");
    const [formData, setFormData] = useState({
      ...recipe,
      total_cost: recipe.total_cost || 0,
      allergens: recipe.allergens || "",
      tags: [],
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);

    // Fetch tags from the server
    useEffect(() => {
      setFormData({
        ...recipe,
        total_cost: recipe.total_cost || 0,
        allergens: recipe.allergens || "",
        tags: recipe.tags || [], // Set the tags from the recipe
      });
    }, [recipe]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      let parsedValue = value;
      if (name === "servings_per_container") {
        // Assuming a whole number is expected; use parseFloat for decimal values.
        parsedValue = parseInt(value, 10);
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: parsedValue,
      }));
    };

    const handleTagChange = (selectedOptions) => {
      setFormData((prevData) => ({
        ...prevData,
        tags: selectedOptions.map((option) => option.value),
      }));
    };

    const saveChanges = () => {
      const updatedRecipeData = {
        ...formData,
        // Ensure servings_per_container is an integer.
        servings_per_container:
          parseInt(formData.servings_per_container, 10) || 0, // Fallback to 0 if parsing fails
        tags: formData.tags, // Use the tags from formData
      };

      // Now using the updated state to update the parent component
      onSave(updatedRecipeData);

      // Close the modal after saving changes
      toggle();
    };

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>
          {mode === "edit" ? "Edit Recipe" : "Recipe Details"}
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            {/* Tab Headers */}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => setActiveTab("1")}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => setActiveTab("2")}
              >
                Costs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => setActiveTab("3")}
              >
                Servings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => setActiveTab("4")}
              >
                Miscellaneous
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            {/* General Info Tab */}
            <TabPane tabId="1">
              <Form>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    id="name"
                    name="name"
                    value={formData.name || ""}
                    onChange={handleInputChange}
                    placeholder="Recipe Name"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="tags">Tags</Label>
                  <Select
                    isMulti
                    name="tags"
                    options={tagSuggestions}
                    value={tagSuggestions.filter((tag) =>
                      formData.tags.includes(tag.value)
                    )}
                    onChange={handleTagChange}
                    classNamePrefix="select"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="description">Recipe Instructions</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    style={{ minHeight: "200px" }}
                    value={formData.description || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="allergens">Allergens</Label>
                  <Input
                    type="text"
                    name="allergens"
                    id="allergens"
                    value={formData.allergens || ""}
                    onChange={handleInputChange}
                    placeholder="Enter allergens here"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="creationDate">Creation Date</Label>
                  <Input
                    type="date"
                    name="creation_date"
                    id="creationDate"
                    value={formData.creation_date || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="is_active">Is Active?</Label>
                  <Input
                    type="checkbox"
                    name="is_active"
                    id="is_active"
                    checked={formData.is_active || false}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="is_favorite">Is Favorite?</Label>
                  <Input
                    type="checkbox"
                    name="is_favorite"
                    id="is_favorite"
                    checked={formData.is_favorite || false}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                {/* Add more fields as necessary */}
              </Form>
            </TabPane>

            {/* Costs Tab */}
            {/* Costs Tab */}
            <TabPane tabId="2">
              <Form>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="laborCost">Labor Cost</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="laborCostTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="laborCostTooltip">
                      Enter the labor cost associated with the recipe.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="labor_cost"
                    id="laborCost"
                    value={formData.labor_cost || 0}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="overheadCost">Overhead Cost</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="overheadCostTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="overheadCostTooltip">
                      Enter the overhead cost associated with the recipe.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="overhead_cost"
                    id="overheadCost"
                    value={formData.overhead_cost || 0}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="totalCost">Total Ingredients Cost</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="totalCostTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="totalCostTooltip">
                      The total cost of all ingredients used in the recipe.
                    </UncontrolledTooltip>
                  </div>
                  {mode === "edit" ? (
                    <Input
                      type="number"
                      name="total_cost"
                      id="totalCost"
                      value={formData.total_cost}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <Input
                      type="text"
                      name="total_cost"
                      id="totalCost"
                      value={`$${formData.total_cost}`}
                      readOnly
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="qtyProduced">Quantity Produced</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="qtyProducedTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="qtyProducedTooltip">
                      Enter the total quantity of the recipe produced.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="qty_produced"
                    id="qtyProduced"
                    value={formData.qty_produced || 0}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="packagesPerBatch">Packages per Batch</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="packagesPerBatchTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="packagesPerBatchTooltip">
                      Enter the number of packages produced per batch of the
                      recipe.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="packages_per_batch"
                    id="packagesPerBatch"
                    value={formData.packages_per_batch || 0}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Form>
            </TabPane>
            {/* Servings Tab */}
            {/* Servings Tab */}
            <TabPane tabId="3">
              <Form>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="fdaApprovedServingSize">
                      FDA Approved Serving Size
                    </Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="fdaApprovedServingSizeTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="fdaApprovedServingSizeTooltip">
                      Select the FDA approved serving size for the recipe. We do
                      conversions automatically using grams as the unit of
                      measure. You only need to select the serving size.
                      Different sizes affect the nutirtion facts calculus. Use
                      the summary below to make sense of how the recipe
                      nutrition serving size reads.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="select"
                    name="fda_approved_serving_size"
                    id="fdaApprovedServingSize"
                    value={formData.fda_approved_serving_size || ""}
                    onChange={handleInputChange}
                  >
                    {SERVING_SIZES.map((size) => (
                      <option key={size.value} value={size.value}>
                        {size.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="suggestedServingSize">
                      Suggested Serving Size
                    </Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="suggestedServingSizeTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="suggestedServingSizeTooltip">
                      Enter the suggested serving size for the recipe.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="sugested_serving_size"
                    id="suggestedServingSize"
                    value={formData.sugested_serving_size || 0}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="UnitsOfServings">Units for Serving Size</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="UnitsOfServingsTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="UnitsOfServingsTooltip">
                      Select the units for the serving size.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="select"
                    name="units"
                    id="UnitsOfServings"
                    value={formData.units || ""}
                    onChange={handleInputChange}
                  >
                    <option value="cup">cup</option>
                    <option value="lb">lb</option>
                    <option value="gallon">gallon</option>
                    <option value="gram">gram</option>
                    <option value="oz">oz</option>
                    <option value="kg">kg</option>
                    <option value="tbsp">tbsp</option>
                    {/* Add other options as needed */}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="numberOfServings">
                      Number of Units for Units for Serving Size
                    </Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="numberOfServingsTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="numberOfServingsTooltip">
                      Enter the number of units for the serving size (e.g. if
                      above is gram and tihs field is 100, it will be 100
                      grams.).
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="number_of_servings"
                    id="numberOfServings"
                    value={formData.number_of_servings || 0}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Label for="servingsPerContainer">
                      Servings per Container
                    </Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="servingsPerContainerTooltip"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    />
                    <UncontrolledTooltip target="servingsPerContainerTooltip">
                      Enter the number of servings per container.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name="servings_per_container"
                    id="servingsPerContainer"
                    value={formData.servings_per_container || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                {/* Summary Section */}
                <div className="summary-section">
                  <h5>Summary:</h5>
                  <p>
                    FDA Approved Serving Size is{" "}
                    <strong>
                      {
                        SERVING_SIZES.find(
                          (size) =>
                            size.value === formData.fda_approved_serving_size
                        )?.label
                      }
                    </strong>
                    , Suggested Serving Size is{" "}
                    <strong>{formData.sugested_serving_size}</strong>, Units for
                    Serving Size is <strong>{formData.units}</strong>, Number of
                    Units for Serving Size is{" "}
                    <strong>
                      {formData.number_of_servings} {formData.units}
                    </strong>
                    . This will read as{" "}
                    <strong>
                      {formData.sugested_serving_size}{" "}
                      {formData.fda_approved_serving_size} of{" "}
                      {formData.number_of_servings} {formData.units} each with a
                      suggested serving of {formData.sugested_serving_size}{" "}
                      {formData.fda_approved_serving_size}.
                    </strong>
                  </p>
                </div>
              </Form>
            </TabPane>

            {/* Miscellaneous Tab */}
            <TabPane tabId="4">
              <Form>
                <FormGroup>
                  <Label for="notes">Notes</Label>
                  <Input
                    type="textarea"
                    name="notes"
                    id="notes"
                    value={formData.notes || ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                {/* Add more fields as necessary */}
              </Form>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveChanges}>
            Save Changes
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* Search Bar within Card */}
              <div
                className="mb-3 d-flex align-items-center justify-content-between"
                style={{ margin: "10px" }}
              >
                <Button
                  className="custom-button"
                  onClick={redirectToTables}
                  style={{ marginRight: "10px" }}
                  title="Create Recipe"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                {/* Tag filter dropdown */}
                <div style={{ marginRight: "10px" }}>
                  <select
                    id="tagFilter"
                    value={selectedTagFilter}
                    onChange={(e) => setSelectedTagFilter(e.target.value)}
                    style={{
                      padding: "10px 12px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "14px",
                      width: "200px", // Adjust the width as needed
                      color: "#8898aa",
                    }}
                  >
                    <option value="">All</option>
                    {tagSuggestions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <Input
                  type="text"
                  placeholder="Search recipes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    flexGrow: 1, // Allow the input to fill the available space
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
              </div>

              {/* Table */}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Ingredients</th>
                    <th>Updated</th>
                    <th>Tags</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <LoadingSpinner />
                      </td>
                    </tr>
                  ) : (
                    recipes.map((recipe) => (
                      <React.Fragment key={recipe.id}>
                        <tr onClick={() => toggleExpand(recipe)}>
                          <td>{recipe.name}</td>
                          <td className="ingredients-field">
                            {recipe.recipe_ingredients
                              ? recipe.recipe_ingredients.length
                              : 0}{" "}
                            Ingredients
                          </td>
                          <td>{formatDate(recipe.date_updated)}</td>
                          <td>
                            {recipe.tags.map((tagId) => {
                              const tag = tagMap[tagId];
                              return (
                                <span
                                  key={tagId}
                                  style={{
                                    backgroundColor: tag?.color || "gray",
                                    color: "white",
                                    padding: "4px 8px",
                                    marginRight: "4px",
                                    borderRadius: "16px",
                                    display: "inline-block",
                                  }}
                                >
                                  {tag?.name || "Unknown Tag"}
                                </span>
                              );
                            })}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Button
                              className="button-edit-recipe"
                              title="Details"
                              size="sm"
                              onClick={(e) => handleEditClick(recipe, e)}
                            >
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </Button>
                            <Button
                              color="primary"
                              size="sm"
                              title="Edit"
                              onClick={() => handleOpenEditModal(recipe)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button
                              color="info"
                              className="button-view-nutrition"
                              onClick={handleViewNutritionClick}
                              size="sm"
                              title="Nutrition Label"
                            >
                              <FontAwesomeIcon icon={faTag} />
                            </Button>
                            <Button
                              color="danger"
                              size="md"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent toggling the expansion of the recipe details
                                handleDeleteRecipe(recipe.id);
                              }}
                              style={{ marginLeft: "10px" }}
                              size="sm"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                        {selectedRecipe.id === recipe.id && (
                          <tr>
                            <td colSpan="5" className="grey-background">
                              <h2
                                style={{
                                  textAlign: "center",
                                  marginBottom: "30px",
                                }}
                              >
                                Ingredients List
                              </h2>
                              {recipeIngredients.map((ingredient) => (
                                <div
                                  key={ingredient.id}
                                  className="ingredient-item"
                                >
                                  {editableIngredientId === ingredient.id ? (
                                    <>
                                      <div className="form-group">
                                        <label className="ingredient-label">
                                          {
                                            ingredient.ingredient_details
                                              .ingredient_description
                                          }
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`quantity_used_${ingredient.id}`}
                                        >
                                          Quantity Used
                                        </label>
                                        <input
                                          type="text"
                                          id={`quantity_used_${ingredient.id}`}
                                          className="form-control"
                                          value={ingredient.quantity_used}
                                          onChange={(e) =>
                                            handleIngredientChange(
                                              ingredient.id,
                                              "quantity_used",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`units_${ingredient.id}`}
                                        >
                                          Units
                                        </label>
                                        <select
                                          id={`units_${ingredient.id}`}
                                          className="form-control"
                                          value={ingredient.units}
                                          onChange={(e) =>
                                            handleIngredientChange(
                                              ingredient.id,
                                              "units",
                                              e.target.value
                                            )
                                          }
                                        >
                                          {UNIT_CHOICES.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`waste_${ingredient.id}`}
                                        >
                                          Waste
                                        </label>
                                        <input
                                          type="text"
                                          id={`waste_${ingredient.id}`}
                                          className="form-control"
                                          value={ingredient.waste}
                                          onChange={(e) =>
                                            handleIngredientChange(
                                              ingredient.id,
                                              "waste",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor={`price_${ingredient.id}`}
                                        >
                                          Ingredient Cost
                                        </label>
                                        <input
                                          type="number"
                                          id={`price_${ingredient.id}`}
                                          className="form-control"
                                          value={ingredient.price || 0}
                                          onChange={(e) =>
                                            handleIngredientChange(
                                              ingredient.id,
                                              "price",
                                              parseFloat(e.target.value)
                                            )
                                          }
                                          step="0.01"
                                        />
                                      </div>
                                      <button
                                        className="button-save"
                                        onClick={() =>
                                          saveIngredient(ingredient.id)
                                        }
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="button-cancel"
                                        onClick={() =>
                                          setEditableIngredientId(null)
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {
                                        ingredient.ingredient_details
                                          .ingredient_description
                                      }{" "}
                                      - {ingredient.quantity_used}{" "}
                                      {ingredient.units}
                                      <Button
                                        size="sm"
                                        title="Edit"
                                        className="button-edit"
                                        onClick={() =>
                                          setEditableIngredientId(ingredient.id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      <Button
                                        className="button-delete"
                                        onClick={() =>
                                          handleDeleteIngredient(ingredient.id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </>
                                  )}
                                </div>
                              ))}
                              <h2
                                style={{
                                  textAlign: "center",
                                  marginBottom: "30px",
                                }}
                              >
                                Recipe Costs
                              </h2>
                              <div className="recipe-cost-details">
                                <p>
                                  Labor Cost: ${recipe.labor_cost.toFixed(2)}
                                </p>
                                <p>
                                  Overhead Cost: $
                                  {recipe.overhead_cost.toFixed(2)}
                                </p>
                                <p>
                                  Total Ingredient Cost: ${recipe.total_cost}
                                </p>
                                <p>Cost per Gram: ${recipe.cost_per_gram}</p>
                                <strong>
                                  Estimated Recipe Cost: $
                                  {calculateGrandTotal(recipe)}
                                </strong>
                              </div>
                              <Button
                                className="button-manage"
                                onClick={() => openIngredientModal(recipe.id)}
                              >
                                Add Ingredients
                              </Button>
                              <Button
                                color="info"
                                className="button-view-nutrition"
                                onClick={handleViewNutritionClick}
                              >
                                <FontAwesomeIcon icon={faTag} />
                              </Button>
                              {selectedNutritionData && (
                                <div className="nutrition-facts-container">
                                  <h5>Nutrition Facts</h5>
                                  <NutritionLabel
                                    data={selectedNutritionData}
                                  />
                                  <Table bordered striped hover>
                                    <thead>
                                      <tr>
                                        <th>Nutrient</th>
                                        <th>Amount</th>
                                        <th>% Daily Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(
                                        selectedNutritionData
                                      ).map(
                                        ([nutrient, { amount, percentDV }]) => (
                                          <tr key={nutrient}>
                                            <td>
                                              {formatNutrientName(nutrient)}
                                            </td>
                                            <td>{amount}</td>
                                            <td>{percentDV}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              )}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  )}
                  {filteredRecipes.length === 0 && !isLoading && (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No recipes found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Modal
          isOpen={isNutritionModalOpen}
          toggle={() => setNutritionModalOpen(false)}
        >
          <ModalHeader toggle={() => setNutritionModalOpen(false)}>
            Nutrition Facts Label
          </ModalHeader>
          <ModalBody>
            <div ref={printRef} className="printable">
              {selectedNutritionData && (
                <NutritionLabel data={selectedNutritionData} />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            {/* Print button */}
            <Button color="primary" onClick={handlePrint}>
              Print
            </Button>
            {/* PDF download button */}
            {/* Uncomment and use the following line if you have set up react-to-pdf or similar library */}
            {/* <PDFDownloadLink document={<NutritionLabel data={selectedNutritionData} />} fileName="nutrition-label.pdf">
            {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
          </PDFDownloadLink> */}
            <Button
              color="secondary"
              onClick={() => setNutritionModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={barcodeModal} toggle={() => setBarcodeModal(false)}>
          <ModalHeader toggle={() => setBarcodeModal(false)}>
            Barcode
          </ModalHeader>
          <ModalBody>
            {/* Directly render the BarcodeGenerator with the current barcodeValue */}
            {barcodeValue && <BarcodeGenerator value={barcodeValue} />}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlePrint}>
              Print
            </Button>
            <Button color="secondary" onClick={() => setBarcodeModal(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
      {isIngredientModalOpen && (
        <IngredientModal
          recipeId={currentRecipeId}
          recipeName={currentRecipeName}
          onClose={closeIngredientModal}
          addIngredient={addIngredient} // Updated function passed here
          onIngredientAdded={refreshIngredients} // Pass the refresh function as a prop
        />
      )}

      {/* Conditional rendering of RecipeModal based on editableRecipe */}
      {editableRecipe && (
        <RecipeModal
          isOpen={isEditRecipeModalOpen}
          toggle={() => setIsEditRecipeModalOpen(false)}
          recipe={editableRecipe}
          mode="edit"
          onSave={handleUpdateRecipe}
          // Make sure to pass any additional required props here
        />
      )}
    </>
  );
};

export default RecipeList;
