/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { sendTokenToServer } from "../../FirebaseMessagingSetup";
import { loadStripe } from "@stripe/stripe-js";
import discountTicket from "../../assets/img/theme/discount-ticket.png";
import "../../assets/css/Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [isTrialEnded, setTrialEnded] = useState(false);
  const [isSubscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [authToken, setAuthToken] = useState(""); // Store the authentication token
  const [isDiscountModalOpen, setDiscountModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const DJANGO_BASE_URL = process.env.DJANGO_BASE_URL; // Define this at the top of your file or import from a config
  const stripePromise = loadStripe(
    "pk_test_51NpGecIJk8kvQl2h2Gq9s3mo3cFDEx6loeyIfgfOz8uzrmVLGL2ctEZA8qlcvWf3tJKyxDsnboXjxstbMZuHIqSj00IOs09QCY"
  );

  useEffect(() => {
    if (isTrialEnded) {
      setSubscriptionModalOpen(true);
    }
  }, [isTrialEnded]);

  // Call this function when the "Maybe Later" button is clicked
  const handleMaybeLater = () => {
    console.log("Maybe Later clicked"); // This should appear in the console
    setSubscriptionModalOpen(false);
    setDiscountModalOpen(true);
  };

  const closeDiscountModal = () => {
    setDiscountModalOpen(false); // Close the discount modal
  };

  const getCsrfToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/get-csrf-token/`,
        {
          method: "GET",
          credentials: "include", // Include credentials for cookies if needed
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.csrfToken; // Make sure this matches the actual key in the JSON response
    } catch (error) {
      console.error("Error fetching CSRF token:", error);
      return "";
    }
  };

  // Function to initiate Stripe checkout
  const initiateStripeCheckout = async () => {
    const stripe = await stripePromise;
    const csrfToken = await getCsrfToken(); // Fetch the CSRF token asynchronously

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/create-checkout-session/`,
      {
        method: "POST",
        credentials: "include", // Include credentials for cookies
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken, // Include the CSRF token in the request header
        },
      }
    );

    const session = await response.json();

    if (response.ok) {
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        toast.error(result.error.message);
      }
    } else {
      toast.error("Failed to create checkout session. Please try again.");
    }
  };

  const handleSubscribeWithDiscount = async () => {
    // Call your API endpoint to create a checkout session with a discount
    // Pass necessary data, like a flag indicating that the discount should be applied
    const csrfToken = await getCsrfToken();
    const stripe = await stripePromise;

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/create-checkout-session-with-discount/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ applyDiscount: true }),
      }
    )
      .then((response) => response.json())
      .then((session) => {
        return stripe.redirectToCheckout({ sessionId: session.sessionId });
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(
          "Failed to create a discounted checkout session. Please try again."
        );
      });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);

    const csrfToken = await getCsrfToken();

    if (!csrfToken) {
      toast.error(
        "CSRF token is missing. Cannot proceed with login. Refresh the browser and try again."
      );
      return;
    }

    const credentials = { email, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/login/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(credentials),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setAuthToken(responseData.authToken); // Store the auth token

        // Check if the trial has ended and set the state accordingly
        if (responseData.isTrialEnded) {
          setTrialEnded(true);
          setSubscriptionModalOpen(true); // Open the subscription modal
        } else {
          navigate("/admin/index"); // Proceed to navigate to the dashboard
        }
      } else {
        // Handle non-OK responses
        const errorData = await response.json(); // Consider extracting error details if the server provides them
        toast.error(
          `Login failed: ${
            errorData.message || "Please check your credentials."
          }`
        );
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred during login. Please try again later.");
    }
  };

  const handlePasswordReset = async () => {
    const csrfToken = await getCsrfToken(); // Ensure CSRF token is fetched

    if (!csrfToken) {
      console.log("CSRF token is missing. Cannot proceed with password reset.");
      toast.error(
        "CSRF token is missing. Cannot proceed with password reset. Refresh the browser and try again."
      );
      setModalOpen(false);
      return;
    }

    setLoading(true); // Set loading to true when the request starts

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/password_reset/`, // Update this to the correct Django endpoint
        {
          method: "POST",
          credentials: "include", // Include credentials for CSRF
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Changed to URL-encoded form data
            "X-CSRFToken": csrfToken, // CSRF token is needed for POST requests
          },
          body: new URLSearchParams({ email: resetEmail }).toString(), // Convert form data to URL-encoded string
        }
      );

      if (response.ok) {
        console.log("Password reset email sent");
        toast.success(
          "Password reset email has been sent. Please check your email to continue."
        );
      } else {
        console.log("Failed to send password reset email");
        const data = await response.json(); // Assuming the error details are in JSON format
        const errorMessage = data.email
          ? data.email[0]
          : "Failed to send password reset email. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error(
        "An error occurred while resetting password. Please check your network connection and try again."
      );
    } finally {
      setLoading(false); // Reset loading to false when the request completes
      setModalOpen(false);
    }
  };


  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Registerd Users</small>
            </div>
            <div className="btn-wrapper text-center">
              {/* ... social buttons ... */}
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleLogin}>
              <div className="text-center">
                {errorMessage && (
                  <div className="text-danger">{errorMessage}</div>
                )}
                {isTrialEnded && (
                  <div style={{ color: "red" }}>Trial ended. Subscribe!!.</div>
                )}
              </div>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id="customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                {errorMessage && (
                  <div className="text-danger">{errorMessage}</div>
                )}
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              href="#"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
              }}
            >
              <small>Forgot password?</small>
            </a>
            {/* password reset  */}
            <div className="text-center">
              {loading && <div className="spinner"></div>}
            </div>
            <Modal
              isOpen={isModalOpen}
              toggle={() => setModalOpen(!isModalOpen)}
            >
              <ModalHeader toggle={() => setModalOpen(!isModalOpen)}>
                Password Reset
              </ModalHeader>
              <ModalBody>
                <Input
                  placeholder="Enter your email"
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={handlePasswordReset}>
                  Reset Password
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => setModalOpen(!isModalOpen)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
          <Col className="text-right" xs="6">
            <NavLink className="text-light" to="/auth/register">
              <small>Create new account</small>
            </NavLink>
          </Col>
        </Row>
      </Col>
      {/* Subscription Modal */}
      <Modal
        isOpen={isSubscriptionModalOpen}
        toggle={() => setSubscriptionModalOpen(!isSubscriptionModalOpen)}
      >
        <ModalHeader
          toggle={() => setSubscriptionModalOpen(!isSubscriptionModalOpen)}
        >
          <h2>Let's keep working together!!</h2>
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>
              Your trial has ended — Subscribe to keep focusing on what's
              important and leave the boring stuff to us.
            </strong>
          </p>
          <p>
            We hope you liked our app so far adn see value added to your
            bussiness. We work hard to provide an app that helps you keep track
            of your operations, stop login time, stay compliant and focus on
            growing your bussiness.
          </p>

          <p>
            Unlock the full potential of Food Recall Tracker and ensure seamless
            operations.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={initiateStripeCheckout}
            style={{ fontSize: "1.2em" }}
          >
            <i class="fas fa-bolt"></i> Subscribe Now
          </Button>
          <Button color="secondary" onClick={handleMaybeLater}>
            Maybe Later
          </Button>
        </ModalFooter>
      </Modal>
      {/* Discount Modal */}
      <Modal
        isOpen={isDiscountModalOpen}
        toggle={() => setDiscountModalOpen(!isDiscountModalOpen)}
      >
        <ModalHeader>Special Discount Just for You!</ModalHeader>
        <ModalBody>
          <img
            src={discountTicket}
            className="mx-auto d-block"
            alt="Discount Ticket"
            style={{ width: "300px" }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubscribeWithDiscount}>
            Subscribe with Discount
          </Button>
          <Button
            color="secondary"
            onClick={() => setDiscountModalOpen(!isDiscountModalOpen)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Login;
