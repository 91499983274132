import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import { toast } from 'react-toastify';

const CreateEmployeeModal = ({ isOpen, toggle, refreshUsers, userToEdit }) => {
  const isEditMode = !!userToEdit; // Determine if in edit mode based on userToEdit prop

  const [activeTab, setActiveTab] = useState('1');
  const [formData, setFormData] = useState({
    role: 'user',
    email: '',
    first_name: '',
    last_name: '',
    picture: null,
    date_joined: '',
    date_updated: '',
  });


  useEffect(() => {
    if (isEditMode && userToEdit) {
      setFormData({
        role: userToEdit.role || 'user', // Assuming 'role' is a property of user. Adjust as needed.
        email: userToEdit.email || '',
        first_name: userToEdit.first_name || '',
        last_name: userToEdit.last_name || '',
        // Add other fields as necessary based on your user model
      });
    } else {
      // Reset form data if not in edit mode or if userToEdit is null
      setFormData({
        role: 'user',
        email: '',
        first_name: '',
        last_name: '',
        picture: null,
        date_joined: '',
        date_updated: '',
      });
    }
  }, [userToEdit, isEditMode]); 



  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Determine the endpoint based on whether we are editing or creating a new user
    const endpoint = userToEdit
      ? `/users/api/tenant-users/${userToEdit.id}/update/` // Endpoint for editing user
      : `/users/api/tenant-users/create/`; // Endpoint for creating user
  
    const method = userToEdit ? 'put' : 'post';
  
    // Build the full URL by appending the endpoint to the base URL from environment variables
    const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;
  
    try {
      const response = await axios({
        method,
        url,
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCsrfToken(),
        },
      });
  
      console.log('Response:', response); // Logging the response for debugging
      
      // Handle responses based on whether it's a create or update operation
      if (!userToEdit) {
        toast.success('User created successfully! New user will receive an email to reset his password and login.');
      } else {
        toast.success('User updated successfully!');
      }
  
      // Refresh the user list in the parent component if available
      if (typeof refreshUsers === 'function') {
        refreshUsers();
      }
      toggle(); // Close the modal
    } catch (error) {
      console.error(`Error ${userToEdit ? 'updating' : 'creating'} user:`, error);
      let errorMessage = `Failed to ${userToEdit ? 'update' : 'create'} user.`; // Default error message
  
      // Construct detailed error messages if there are specifics provided by the server
      if (error.response && error.response.data) {
        const errors = error.response.data;
        const detailedMessage = Object.keys(errors)
          .map(key => {
            // Ensure error messages are displayed as a comma-separated list
            const errorMessages = Array.isArray(errors[key]) ? errors[key] : [errors[key]];
            return `${key}: ${errorMessages.join(", ")}`;
          })
          .join("; ");
        errorMessage += ` Details: ${detailedMessage}`;
      }
  
      toast.error(errorMessage);
    }
  };
  
  
  
  

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{isEditMode ? 'Edit User' : 'Create User'}</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggleTab('1'); }}
            >
              Basic Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggleTab('2'); }}
            >
              Details
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" value={formData.email} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="first_name">First Name</Label>
              <Input type="text" name="first_name" id="first_name" value={formData.first_name} onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for="last_name">Last Name</Label>
              <Input type="text" name="last_name" id="last_name" value={formData.last_name} onChange={handleChange} />
            </FormGroup>
          </TabPane>
          <TabPane tabId="2">
            <FormGroup>
              <Label for="role">Role</Label>
              <Input type="select" name="role" id="role" value={formData.role} onChange={handleChange}>
                {/* Assuming ROLE_CHOICES is defined elsewhere and imported */}
                <option value="user">User</option>
                <option value="admin">Admin</option>
                {/* Add other roles as needed */}
              </Input>
            </FormGroup>
            {/* Additional fields like picture upload can be added here */}
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>{isEditMode ? 'Update' : 'Create'}</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateEmployeeModal;
