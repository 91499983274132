// nutrientMapping.js

export const nutrientMapping = {
    "Energy": "calories",
    "Energy (Atwater General Factors)": "calories_2",
    "Energy (Atwater Specific Factors)": "calories_3",
    "Protein": "protein",
    "Total lipid (fat)": "totalFat",
    "Fiber, total dietary": "dietaryFiber",
    "Sugars, total including NLEA": "totalSugars",
    "Calcium, Ca": "calcium",
    "Iron, Fe": "iron",
    "Sodium, Na": "sodium",
    "Vitamin D (D2 + D3)": "vitaminD",
    "Potassium, K": "potassium",
    "Fatty acids, total saturated": "saturatedFat",
    "Cholesterol": "cholesterol",
    "Carbohydrate, by difference": "totalCarbohydrates_1",
    "Carbohydrates": "totalCarbohydrates_2",
    "Carbohydrate, other": "otherCarbohydrates",
    // Add more mappings as needed
    "Sugars, added": "addedSugars",
    "Fatty acids, total monounsaturated": "monounsaturatedFat",
    "Fatty acids, total polyunsaturated": "polyunsaturatedFat",
    "Niacin": "niacin",
    "Thiamin": "thiamin",
    "Riboflavin": "riboflavin",
    "Vitamin B-6": "vitaminB6",
    "Phosphorus, P": "phosphorus",
    "Magnesium, Mg": "magnesium",
    "Vitamin B-12": "vitaminB12",
    "Zinc, Zn": "zinc",
    "Folate, total": "folate",
    "Folic acid": "folicAcid",
    "Copper, Cu": "copper",
    "Pantothenic acid": "pantothenicAcid",
    "Manganese, Mn": "manganese",
    "Selenium, Se": "selenium",
    "Vitamin K (phylloquinone)": "vitaminK",
    "Water": "water",
    "Total sugar alcohols": "sugarAlcohols",
    "Fiber, soluble": "solubleFiber",
    "Fiber, insoluble": "insolubleFiber",
    "Vitamin A": "vitaminA",
    "Ash": "ash",
    "Folate, DFE": "folateDFE",
    "SFA 16:0": "saturatedFat16_0",
    "SFA 18:0": "saturatedFat18_0",
    "Vitamin E (alpha-tocopherol)": "vitaminE",
    "SFA 14:0": "saturatedFat14_0",
    "SFA 12:0": "saturatedFat12_0",
    "Retinol": "retinol",
    "PUFA 20:4": "polyunsaturatedFat20_4",
    "SFA 10:0": "saturatedFat10_0",
    "PUFA 18:2": "polyunsaturatedFat18_2",
    "MUFA 18:1": "monounsaturatedFat18_1",
    "Vitamin A, RAE": "vitaminA_RAE",
    "PUFA 18:3": "polyunsaturatedFat18_3",
    "Folate, food": "foodFolate",
    "MUFA 16:1": "monounsaturatedFat16_1",
    "SFA 8:0": "saturatedFat8_0",
    "MUFA 20:1": "monounsaturatedFat20_1",
    "PUFA 20:5 n-3 (EPA)": "polyunsaturatedFat20_5_n3_EPA",
    "PUFA 22:6 n-3 (DHA)": "polyunsaturatedFat22_6_n3_DHA",
    "PUFA 22:5 n-3 (DPA)": "polyunsaturatedFat22_5_n3_DPA",
    "SFA 6:0": "saturatedFat6_0",
    "SFA 4:0": "saturatedFat4_0",
    "PUFA 18:4": "polyunsaturatedFat18_4",
    "Caffeine": "caffeine",
    "Choline, total": "choline",
    "Carotene, beta": "betaCarotene",
    "MUFA 22:1": "monounsaturatedFat22_1",
    "Vitamin A, RE": "vitaminA_RE",
    "Vanadium, V": "vanadium",
    "Titanium, Ti": "titanium",
    "Tin, Sn": "tin",
    "Strontium, Sr": "strontium",
    "Silver, Ag": "silver",
    "Silicon, Si": "silicon",
    "Rubidium, Rb": "rubidium",
    "Mercury, Hg": "mercury",
    "Lithium, Li": "lithium",
    "Lead, Pb": "lead",
    "p-Hydroxy benzoic acid": "pHydroxyBenzoicAcid",
    "Caffeic acid": "caffeicAcid",
    "Glycolipids": "glycolipids",
    "Ferrulic acid": "ferrulicAcid",
    "Cysteine and methionine(sulfer containig AA)": "cysteineMethionine",
    "Phenylalanine and tyrosine (aromatic AA)": "phenylalanineTyrosine",
    "Asparagine": "asparagine",
    "Vanillic acid": "vanillicAcid",
    "Tyrosol": "tyrosol",
    "Sugars, intrinsic": "intrinsicSugars",
    "Gentisic acid": "gentisicAcid",
    "Iron, added": "addedIron",
    "Calcium, intrinsic": "intrinsicCalcium",
    "Iron, intrinsic": "intrinsicIron",
    "Vitamin C, added": "addedVitaminC",
    "Thiamin, added": "addedThiamin",
    "Phospholipids": "phospholipids",
    "Riboflavin, added": "addedRiboflavin",
    "Niacin, added": "addedNiacin",
    "Ellagic acid": "ellagicAcid",
    "Vitamin C, intrinsic": "intrinsicVitaminC",
    "Vitamin E, intrinsic": "intrinsicVitaminE",
    "Thiamin, intrinsic": "intrinsicThiamin",
    "Riboflavin, intrinsic": "intrinsicRiboflavin",
    "Niacin, intrinsic": "intrinsicNiacin",
    "Vitamin B-12, intrinsic": "intrinsicVitaminB12",
    "p-Coumaric acid": "pCoumaricAcid",
    "Glycerides": "glycerides",
    "Iron, non-heme": "nonHemeIron",
    "Pelargonidin": "pelargonidin",
    "Iron, heme": "hemeIron",
    "Reducing sugars": "reducingSugars",
    "Glycogen": "glycogen",
    "ORAC, Hydrophyllic": "oracHydrophilic",
    "ORAC, Lipophillic": "oracLipophilic",
    "ORAC, Total": "oracTotal",
    "Total Phenolics": "totalPhenolics",
    "Glycitein": "glycitein",
    "Isoflavones": "isoflavones",
    "Biochanin A": "biochaninA",
    "Formononetin": "formononetin",
    "Coumestrol": "coumestrol",
    "Flavonoids, total": "totalFlavonoids",
    "Anthocyanidins": "anthocyanidins",
    "Cyanidin": "cyanidin",
    "Proanthocyanidin (dimer-A linkage)": "proanthocyanidinDimer",
    "Phenolic acids, total": "totalPhenolicAcids",
    "Proanthocyanidin dimers": "proanthocyanidinDimers",
    "Proanthocyanidin trimers": "proanthocyanidinTrimers",
    "Proanthocyanidin 4-6mers": "proanthocyanidin46mers",
    "Proanthocyanidin 7-10mers": "proanthocyanidin710mers",
    "Proanthocyanidin polymers (>10mers)": "proanthocyanidinPolymers",
    "Delphinidin": "delphinidin",
    "Beta-glucans": "betaGlucans",
    "Fatty acids, total polyunsat., NLEA": "totalPolyunsaturatedFatNLEA",
    "Fatty acids, total monounsat., NLEA": "totalMonounsaturatedFatNLEA",
    "PUFA 18:2 t,c": "PUFA18_2TransCis",
    "Gold, Au": "gold",
    "Cadmium, Cd": "cadmium",
    "Delta-7-avenasterol": "delta7Avenasterol",
    "Bromine, Br": "bromine",
    "Alpha-spinasterol": "alphaSpinasterol",
    "Beryllium, Be": "beryllium",
    "Barium, Ba": "barium",
    "Arsenic, As": "arsenic",
    "Wax Esters(Total Wax)": "totalWaxEsters",
    "Antimony, Sb": "antimony",
    "Aluminum, Al": "aluminum",
    "PUFA 18:2 c,t": "PUFA18_2CisTrans",
    "Fatty acids, total sat., NLEA": "totalSaturatedFatNLEA",
    "25-hydroxyergocalciferol": "hydroxyergocalciferol",
    "Theophylline": "theophylline",
    "Xylose": "xylose",
    "Fatty acids, saturated, other": "otherSaturatedFats",
    "Fatty acids, monounsat., other": "otherMonounsaturatedFats",
    "Fatty acids, polyunsat., other": "otherPolyunsaturatedFats",
    "Arabinose": "arabinose",
    "SFA 19:0": "saturatedFat19_0",
    "Resistant starch": "resistantStarch",
    "PUFA 16:2": "polyunsaturatedFat16_2",
    "Nonstarch polysaccharides": "nonstarchPolysaccharides",
    "Calcium, added": "addedCalcium",
         

  };
  
  export const mapNutrients = (nutrientList) => {
    return nutrientList.reduce((mapped, nutrient) => {
      const key = nutrientMapping[nutrient];
      if (key) {
        mapped[key] = nutrient;
      }
      return mapped;
    }, {});
  };
  