import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import "../../assets/css/ingred_modal.css";
import axios from "axios";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";

const IngredientModal = ({
  recipeId,
  recipeName,
  onClose,
  addIngredient,
  onIngredientAdded,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [units, setUnits] = useState("");
  const [initialIngredients, setInitialIngredients] = useState([]);
  const [quantityMap, setQuantityMap] = useState({});
  const [unitsMap, setUnitsMap] = useState({});

  const UNIT_CHOICES = [
    { value: "cup", label: "1 cup" },
    { value: "lb", label: "1 lb" },
    { value: "gallon", label: "1 gallon" },
    { value: "gram", label: "1 gram" },
    { value: "oz", label: "1 oz" },
    { value: "kg", label: "1 kg" },
  ];

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const fetchInitialIngredients = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/tenant-ingredients/`,
        {
          params: { limit: 20 },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      )
      .then((response) => {
        const results = response.data.results || [];
        setInitialIngredients(results);
      })
      .catch((error) => {
        console.error("Error fetching initial ingredients:", error);
      });
  }, []);

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim()) {
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/food/api/tenant-ingredients/`,
            {
              params: { search: searchTerm },
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCsrfToken(),
              },
            }
          )
          .then((response) => {
            const results = response.data.results || [];
            const filteredResults = results.filter((ingredient) =>
              ingredient.ingredient_description
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
            setSearchResults(filteredResults);
          })
          .catch((error) => {
            console.error("Error searching ingredients:", error);
            setSearchResults([]); // Set searchResults to an empty array in case of error
          });
      } else {
        setSearchResults([]);
      }
    }, 300),
    []
  ); // Dependencies array is empty, meaning the function is created only once

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  useEffect(() => {
    fetchInitialIngredients();
  }, [fetchInitialIngredients]);

  const handleAddIngredient = (ingredient) => {
    const ingredientId = ingredient.id;
    const quantity = quantityMap[ingredientId] || "";
    const units = unitsMap[ingredientId] || "";
  
    if (!quantity || !units) {
      alert("Please enter both quantity and units.");
      return;
    }
  
    const ingredientData = {
      ingredient: ingredient.id,
      quantity_used: quantity,
      units,
      recipe: recipeId,
    };
  
    addIngredient(recipeId, ingredientData)
      .then(() => {
        onIngredientAdded();
        setQuantityMap((prevMap) => ({ ...prevMap, [ingredientId]: "" }));
        setUnitsMap((prevMap) => ({ ...prevMap, [ingredientId]: "" }));
        setSearchResults((results) =>
          results.filter((item) => item.id !== ingredientId)
        );
        onClose();
      })
      .catch((error) => {
        console.error("Error adding ingredient:", error);
      });
  };
  
  
  

  return (
    <Modal
      isOpen={true}
      toggle={onClose}
      style={{ maxWidth: "600px", width: "100%" }}
    >
      <ModalHeader toggle={onClose}>
        Add Ingredients to {recipeName}
      </ModalHeader>
      <ModalBody>
        <div className="table-container">
          <Input
            type="text"
            placeholder="Search for ingredients..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mb-3"
          />
          <Table striped className="table-custom-striped">
            <tbody>
              {(searchTerm ? searchResults : initialIngredients).map(
                (ingredient, index) => (
                  <tr
                    key={ingredient.id}
                    style={{ backgroundColor: index % 2 ? "#f2f2f2" : "white" }}
                  >
                    <td className="description-cell" style={{ width: "200px" }}>
                      {ingredient.ingredient_description}
                    </td>
                    <td className="quantity-cell" style={{ width: "80px" }}>
                      <Input
                        type="number"
                        placeholder="Qty"
                        value={quantityMap[ingredient.id] || ""}
                        onChange={(e) =>
                          setQuantityMap((prevMap) => ({
                            ...prevMap,
                            [ingredient.id]: e.target.value,
                          }))
                        }
                        style={{ width: "80px" }}
                      />
                    </td>
                    <td className="units-cell" style={{ width: "100px" }}>
                      <Input
                        type="select"
                        value={unitsMap[ingredient.id] || ""}
                        onChange={(e) =>
                          setUnitsMap((prevMap) => ({
                            ...prevMap,
                            [ingredient.id]: e.target.value,
                          }))
                        }
                        style={{ width: "100%" }}
                      >
                        <option value="">Units</option>
                        {UNIT_CHOICES.map((unit) => (
                          <option key={unit.value} value={unit.value}>
                            {unit.label}
                          </option>
                        ))}
                      </Input>
                    </td>
                    <td className="button-cell">
                      <Button
                        className="add-btn"
                        onClick={() => handleAddIngredient(ingredient)}
                        style={{
                          backgroundColor: "#5E72E4",
                          color: "white",
                          border: "none",
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default IngredientModal;
