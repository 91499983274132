import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import "../../assets/css/SupplyDetailModal.css";
import { toast } from 'react-toastify';
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

const SupplyDetailModal = ({
  isOpen,
  toggle,
  selectedSupplyDetail,
  selectedStore,
  setSelectedSupplyDetail,
  setShowSupplyModal,
  onSupplyDetailUpdated,
}) => {
  const [supplyDetail, setSupplyDetail] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const getCsrfToken = () => {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  useEffect(() => {
    if (isOpen && selectedSupplyDetail) {
      setSupplyDetail(selectedSupplyDetail);
    }
  }, [isOpen, selectedSupplyDetail]);

  const fetchSupplyDetail = async () => {
    try {
      const csrfToken = getCsrfToken();

      // Fetch the specific StoreSupply object for the given ID
      const storeSupplyResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/store-supply/${selectedSupplyDetail.id}/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      if (!storeSupplyResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const storeSupplyData = await storeSupplyResponse.json();

      if (storeSupplyData) {
        setSelectedSupplyDetail({
          ...storeSupplyData,
          id: selectedSupplyDetail.id, // Include the id from selectedSupplyDetail
        });
        setShowSupplyModal(true);
      } else {
        console.error("StoreSupply object not found");
      }
    } catch (error) {
      console.error("Error fetching store supply detail:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupplyDetail((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveChanges = async () => {
    const csrfToken = getCsrfToken();
    try {
      if (!selectedSupplyDetail.id) {
        throw new Error("Selected supply detail does not have an id");
      }
  
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/store-supply/${selectedSupplyDetail.id}/`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({
            id: selectedSupplyDetail.id,
            store: selectedSupplyDetail.store,
            supply: selectedSupplyDetail.supply,
            quantity: supplyDetail.quantity,
            restock_date: supplyDetail.restock_date,
            date_added: supplyDetail.date_added,
            last_updated: supplyDetail.last_updated,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      // Refetch parent component and close modal.
      if (onSupplyDetailUpdated) {
        onSupplyDetailUpdated();
      }
      toggle();
  
      const updatedData = await response.json();
      setSupplyDetail(updatedData);
      setIsEditMode(false);
  
      // Display success toast notification
      toast.success('Supply details updated successfully');
    } catch (error) {
      console.error("Error updating supply details:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-thinner">
      <ModalHeader toggle={toggle}>Supply Details</ModalHeader>
      <ModalBody>
        {supplyDetail ? (
          <div>
            <FormGroup>
              <Label for="quantity">Quantity</Label>
              {isEditMode ? (
                <Input
                  type="number"
                  name="quantity"
                  id="quantity"
                  value={supplyDetail.quantity || ""}
                  onChange={handleInputChange}
                />
              ) : (
                <p>
                  {supplyDetail.quantity !== null
                    ? supplyDetail.quantity
                    : "N/A"}
                </p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="restock_date">Restock Date</Label>
              {isEditMode ? (
                <Input
                  type="date"
                  name="restock_date"
                  id="restock_date"
                  value={supplyDetail.restock_date || ""}
                  onChange={handleInputChange}
                />
              ) : (
                <p>{supplyDetail.restock_date || "N/A"}</p>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="date_added">Date Added</Label>
              <p>
                {supplyDetail.date_added
                  ? format(
                      parseISO(supplyDetail.date_added),
                      "MMMM d, yyyy 'at' h:mm a"
                    )
                  : "N/A"}
              </p>
            </FormGroup>
            <FormGroup>
              <Label for="last_updated">Last Updated</Label>
              <p>
                {supplyDetail.last_updated
                  ? format(
                      parseISO(supplyDetail.last_updated),
                      "MMMM d, yyyy 'at' h:mm a"
                    )
                  : "N/A"}
              </p>
            </FormGroup>
            <FormGroup>
              <Label for="created_by">Created By</Label>
              <p>
                {supplyDetail.created_by
                  ? supplyDetail.created_by.username
                  : "N/A"}
              </p>
            </FormGroup>
            <FormGroup>
              <Label for="updated_by">Updated By</Label>
              <p>
                {supplyDetail.updated_by
                  ? supplyDetail.updated_by.username
                  : "N/A"}
              </p>
            </FormGroup>
            {isEditMode ? (
              <Button color="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            ) : (
              <Button color="primary" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            )}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SupplyDetailModal;
