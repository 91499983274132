// ProductInventoryDetailModal.js
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

const ProductInventoryDetailModal = ({ isOpen, toggle, selectedProductInventoryDetail, activeTab, toggleTab, onUpdateSuccess }) => {

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [showProductInventoryDetailModal, setShowProductInventoryDetailModal] =
    useState(false);
  useState(null);

  const handleInputChange = (e) => {
    setEditedValues({
      ...editedValues,
      [e.target.name]: e.target.value,
    });
  };

  const getCsrfToken = () => {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };


  const handleSaveChanges = async () => {
    try {
      const csrfToken = getCsrfToken();
      const payload = {
        ...editedValues,
        store: selectedProductInventoryDetail.store,
        product: selectedProductInventoryDetail.product,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/product-inventory-detail/${selectedProductInventoryDetail.id}/`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        // Update successful
        console.log('Product inventory updated successfully');
        setIsEditMode(false);
        // Close the modal
        toggle();
        // Refetch the product inventory data in the parent component
        onUpdateSuccess();
        // Display success toast notification
        toast.success('Product inventory updated successfully');
      } else {
        // Handle error
        console.error('Error updating product inventory');
      }
    } catch (error) {
      console.error('Error updating product inventory:', error);
      let errorMessage = "Error updating product inventory.";
  
      // Check if the error response has data and message
      if (error.response && error.response.data) {
        // Extract error message
        errorMessage = error.response.data.message || JSON.stringify(error.response.data);
      } else if (error.message) {
        // Use a generic error message
        errorMessage += ` Additional info: ${error.message}`;
      }
  
      toast.error(errorMessage);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Product Inventory Details</ModalHeader>
      <ModalBody>
        {selectedProductInventoryDetail && (
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => toggleTab("1")}
                >
                  Basic Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => toggleTab("2")}
                >
                  Additional Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => toggleTab("3")}
                >
                  Recall Details
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="storeName">Store Name</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="storeName"
                          id="storeName"
                          value={
                            editedValues.store_name ||
                            selectedProductInventoryDetail.store_name
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.store_name}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="productName">Product Name</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="productName"
                          id="productName"
                          value={
                            editedValues.product_name ||
                            selectedProductInventoryDetail.product_name
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.product_name}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="quantity">Quantity</Label>
                      {isEditMode ? (
                        <Input
                          type="number"
                          name="quantity"
                          id="quantity"
                          value={
                            editedValues.quantity ||
                            selectedProductInventoryDetail.quantity
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.quantity}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="price">Price</Label>
                      {isEditMode ? (
                        <Input
                          type="number"
                          name="price"
                          id="price"
                          value={
                            editedValues.price ||
                            selectedProductInventoryDetail.price
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.price}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="expiryDate">Expiry Date</Label>
                      {isEditMode ? (
                        <Input
                          type="date"
                          name="expiryDate"
                          id="expiryDate"
                          value={
                            editedValues.expiry_date ||
                            selectedProductInventoryDetail.expiry_date
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.expiry_date}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="serialNumber">Serial Number</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="serialNumber"
                          id="serialNumber"
                          value={
                            editedValues.serial_number ||
                            selectedProductInventoryDetail.serial_number
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.serial_number}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="isActive">Is Active</Label>
                      {isEditMode ? (
                        <Input
                          type="checkbox"
                          name="isActive"
                          id="isActive"
                          checked={
                            editedValues.is_active ||
                            selectedProductInventoryDetail.is_active
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          {selectedProductInventoryDetail.is_active
                            ? "Yes"
                            : "No"}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="isRecalled">Is Recalled</Label>
                      {isEditMode ? (
                        <Input
                          type="checkbox"
                          name="isRecalled"
                          id="isRecalled"
                          checked={
                            editedValues.is_recalled ||
                            selectedProductInventoryDetail.is_recalled
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          {selectedProductInventoryDetail.is_recalled
                            ? "Yes"
                            : "No"}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="isFlagged">Is Flagged</Label>
                      {isEditMode ? (
                        <Input
                          type="checkbox"
                          name="isFlagged"
                          id="isFlagged"
                          checked={
                            editedValues.is_flagged ||
                            selectedProductInventoryDetail.is_flagged
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>
                          {selectedProductInventoryDetail.is_flagged
                            ? "Yes"
                            : "No"}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="barcode">Barcode</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="barcode"
                          id="barcode"
                          value={
                            editedValues.barcode ||
                            selectedProductInventoryDetail.barcode
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.barcode}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="batchNumber">Batch Number</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="batchNumber"
                          id="batchNumber"
                          value={
                            editedValues.batch_number ||
                            selectedProductInventoryDetail.batch_number
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.batch_number}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="transportInfo">Transport Info</Label>
                      {isEditMode ? (
                        <Input
                          type="text"
                          name="transportInfo"
                          id="transportInfo"
                          value={
                            editedValues.transport_info ||
                            selectedProductInventoryDetail.transport_info
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.transport_info}</p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="notes">Notes</Label>
                      {isEditMode ? (
                        <Input
                          type="textarea"
                          name="notes"
                          id="notes"
                          value={
                            editedValues.notes ||
                            selectedProductInventoryDetail.notes
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.notes}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="createdBy">Created By</Label>
                      <p>{selectedProductInventoryDetail.created_by}</p>
                    </FormGroup>
                    <FormGroup>
                      <Label for="updatedBy">Updated By</Label>
                      <p>{selectedProductInventoryDetail.updated_by}</p>
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label for="recallDate">Recall Date</Label>
                      {isEditMode ? (
                        <Input
                          type="date"
                          name="recallDate"
                          id="recallDate"
                          value={
                            editedValues.recall_date ||
                            selectedProductInventoryDetail.recall_date
                          }
                          onChange={handleInputChange}
                        />
                      ) : (
                        <p>{selectedProductInventoryDetail.recall_date}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6">{/* Add any other recall-related fields */}</Col>
                </Row>
              </TabPane>
            </TabContent>
            {isEditMode ? (
              <>
                <Button color="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setIsEditMode(false)}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button color="primary" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProductInventoryDetailModal;
