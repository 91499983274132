import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink as NavLinkRRD } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import PropTypes from "prop-types";
import "../../assets/css/sidebar.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  NavbarBrand,
} from "reactstrap";
import UpgradeModal from "./UpgradeModal";
import HelpResources from "./HelpResources"; // Import the new HelpResources modal

const Sidebar = (props) => {
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false); // State for HelpResources modal
  const navigate = useNavigate();

  const toggleUpgradeModal = () => setUpgradeModalOpen(!upgradeModalOpen);
  const toggleHelpModal = () => setHelpModalOpen(!helpModalOpen); // Toggle function for HelpResources modal

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("/users/api/your-profile-endpoint");
        setUserProfile(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = async () => {
    try {
      const csrfResponse = await fetch("/api/get-csrf-token/", {
        method: "GET",
        credentials: "include",
      });

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      const response = await fetch("/api/logout/", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
        },
      });

      if (response.ok) {
        navigate("/auth/login");
      } else {
        console.error("Logout failed.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const createLinks = (routes, title) => {
    return (
      <>
      <h6 className="navbar-heading text-muted" style={{ paddingLeft: '10px' }}>{title}</h6>
        {routes.map((prop, key) => {
          if (prop.showInSidebar === false) {
            return null;
          }
          return (
            <NavItem key={key} className={activeRoute(prop.path)}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active-link"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          );
        })}
      </>
    );
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps = logo && logo.innerLink
    ? { to: logo.innerLink, tag: Link }
    : { href: logo.outterLink, target: "_blank" };

  const dashboard = routes.filter(route => route.name === "Dashboard");
  const audits = routes.filter(route => route.name === "Audits");
  const settings = routes.filter(route => route.name === "Settings");
  const favorites = routes.filter(route => route.name === "Favorites");

  const inventoryInputs = routes.filter(route => 
    ["Create Recipe", "Recipes", "Products", "Ingredient", "Supplies"].includes(route.name));
  const inventoryOperations = routes.filter(route => 
    ["Store Inventory", "Batches", "Recalls"].includes(route.name));

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
              style={{ width: '400px', height: '318px' }}
            />
          </NavbarBrand>
        ) : null}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="User Avatar"
                    src={userProfile.picture || require("../../assets/img/theme/user-icon-person-icon-client-symbol-login-head-sign-icon-design-vector.jpg")}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Profile</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={handleLogout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
          {createLinks([...dashboard, ...audits], "Dashboard")}
            <hr className="my-3" />
            {createLinks(inventoryInputs, "Inventory Inputs")}
            <hr className="my-3" />
            {createLinks(inventoryOperations, "Inventory Operations")}
            {createLinks(favorites, "Favorites")}
            {createLinks(settings, "Settings")}
          </Nav>
          <hr className="my-3" />
          <Nav navbar>
            <NavItem>
              <NavLink href="#" onClick={handleLogout}>
                <i className="ni ni-user-run" style={{ fontSize: '24px' }} />
                Logout
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar>
            <NavItem>
              <NavLink
                href="#"
                onClick={toggleUpgradeModal}
              >
                <i className="ni ni-credit-card" />
                Upgrade
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                onClick={toggleHelpModal} // Link for opening HelpResources modal
              >
                <i className="ni ni-support-16" />
                Help Center
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <UpgradeModal isOpen={upgradeModalOpen} toggle={toggleUpgradeModal} customerId={userProfile.stripe_customer_id} />
        <HelpResources isOpen={helpModalOpen} toggle={toggleHelpModal} /> {/* Add the HelpResources modal */}
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
