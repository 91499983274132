import React from 'react';
import Index from "./views/Index.js";
import Profile from "./views/examples/Profile.js";
import Maps from "./views/examples/Maps.js";
import Register from "./views/examples/Register.js";
import Login from "./views/examples/Login.js";
import Tables from "./views/examples/Tables.js";
import RecipeList from "./views/examples/RecipeList.js";
import ProductList from "./views/examples/ProductList.js";
import IngredientInventory from "./views/examples/IngredientInventory.js";
import StoreInventory from "./views/examples/StoreInventory.js";
import BatchList from "./views/examples/BatchList.js";
import RecallList from "./views/examples/RecallList.js";
import SupplyList from "./views/examples/SupplyList.js";
import FavoriteBatch from "./views/examples/FavoriteBatch.js";
import SettingsComponent from "./views/examples/SettingsComponent.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Audits",
    icon: "fas fa-calendar-alt text-orange",
    component: <Maps />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Create Recipe",
    icon: "fa fa-utensils text-red",
    component: <Tables />,
    layout: "/admin",
  },
  {
    path: "/recipes",
    name: "Recipes",
    icon: "fa fa-blender text-green",
    component: <RecipeList />,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-tag text-purple",
    component: <ProductList />,
    layout: "/admin",
  },
  {
    path: "/ingredients",
    name: "Ingredient",
    icon: "ni ni-basket text-green",
    component: <IngredientInventory />,
    layout: "/admin",
  },
  {
    path: "/batches",
    name: "Batches",
    icon: "fas fa-boxes text-primary",
    component: <BatchList />,
    layout: "/admin",
  },
  {
    path: "/recall",
    name: "Recalls",
    icon: "fas fa-exclamation-triangle text-warning",
    component: <RecallList />,
    layout: "/admin",
  },
  {
    path: "/supplies",
    name: "Supplies",
    icon: "fas fa-tools text-blue",
    component: <SupplyList />,
    layout: "/admin",
  },
  {
    path: "/register",
    name: "Registro",
    icon: "ni ni-button-power text-pink",
    component: <Register />,
    layout: "/auth",
    showInSidebar: false,
  },
  {
    path: "/store-inventory",
    name: "Store Inventory",
    icon: "ni ni-shop text-orange",
    component: <StoreInventory />,
    layout: "/admin",
  },
  {
    path: "/favorites",
    name: "Favorites",
    icon: "fas fa-star text-yellow",
    component: <FavoriteBatch />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "fas fa-cog text-grey",
    component: <SettingsComponent />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
    showInSidebar: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    showInSidebar: false,
  },
];

export default routes;
