import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(null);


  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/check-auth`, {
      credentials: 'include', 
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAuthenticated(data.authenticated);
        if (!data.authenticated) {
          navigate('/auth/login');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/auth/login');
      });
  }, [navigate]);
  

  return (
    <>
      {authenticated === null ? null : authenticated ? (
        children
      ) : (
        <Navigate to="/auth/login" replace />
      )}
    </>
  );
};

export default PrivateRoute;
