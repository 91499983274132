import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalFooter, // Add this for ModalFooter
  ModalBody,
  Label,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import { Alert } from "reactstrap";
import { Table } from "reactstrap";
import "../../assets/css/supply_inventroy.css";
import "../../assets/css/recipe_list.css";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios"; // Import Axios for making HTTP requests
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "./LoadingSpinner";

import {
  faPlus,
  faWarehouse,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

const SupplyList = () => {
  const [activeTab, setActiveTab] = useState("generalInfo");
  const [supplies, setSupplies] = useState([]);
  const [stores, setStores] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddToInventoryModalOpen, setIsAddToInventoryModalOpen] =
    useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedSupplies, setSelectedSupplies] = useState([]);
  const suppliesPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const [selectedSupplyDetail, setSelectedSupplyDetail] = useState({});
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [filteredSupplies, setFilteredSupplies] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [recipes, setRecipes] = useState([]); // State to hold recipes
  const [selectedRecipe, setSelectedRecipe] = useState(null); // State to hold selected recipe
  const [selectedRecipeId, setSelectedRecipeId] = useState("");
  const [supplyDetails, setSupplyDetails] = useState({});
  const [barcodes, setBarcodes] = useState({});

  const [newSupply, setNewSupply] = useState({
    supply: { id: "" },
    supplier: { id: "" },
    name: "", // Set to an empty string
    internal_code: "",
    date_created: "",
    date_updated: "",
    price: "", // Set to an empty string
    notes: "",
    updated_by: "",
    created_by: "",
    barcode: "", // Set to an empty string
  });

  useEffect(() => {
    fetchSuppliers();
    fetchStores();
    // Other fetch calls...
  }, []);

  // Create Barcode
  const handleBarcodeChange = (e, supplyId) => {
    const newBarcode = e.target.value;
    setBarcodes((prevBarcodes) => ({
      ...prevBarcodes,
      [supplyId]: newBarcode,
    }));
  };

  const renderTabContent = () => {
    // Helper function to format dates
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    switch (activeTab) {
      case "generalInfo":
        return (
          <>
            {isEditMode ? (
              <>
                <FormGroup>
                  <Label for="storeSelect">Stores</Label>
                  <Select
                    isMulti
                    name="stores"
                    options={stores.map((store) => ({
                      value: store.id,
                      label: store.name,
                    }))}
                    value={selectedStore}
                    onChange={handleStoreSelectChange}
                    placeholder="Select Store(s)"
                  />
                </FormGroup>
                {selectedStore && (
                  <FormGroup key={selectedStore.value}>
                    <Label for={`quantity-${selectedStore.value}`}>
                      {selectedStore.label} Quantity
                    </Label>
                    <Input
                      type="number"
                      id={`quantity-${selectedStore.value}`}
                      name={`quantity-${selectedStore.value}`}
                      value={selectedStore.quantity || ""}
                      onChange={(e) =>
                        handleQuantityChange(e, selectedStore.value)
                      }
                    />
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="supplyName">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="supplyName"
                    value={selectedSupplyDetail.name || ""}
                    onChange={handleEditInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="supplyDescription">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="supplyDescription"
                    value={selectedSupplyDetail.description || ""}
                    onChange={handleEditInputChange}
                  />
                </FormGroup>
                {/* Include other fields as necessary */}
              </>
            ) : (
              <>
                <p>
                  <strong>Name:</strong> {selectedSupplyDetail.name}
                </p>
                <p>
                  <strong>Description:</strong>{" "}
                  {selectedSupplyDetail.description}
                </p>
                {/* Displaying the table for store quantities in read-only mode */}
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Store Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedStore ? (
                      <tr key={selectedStore.value}>
                        <th scope="row">1</th>
                        <td>{selectedStore.label}</td>
                        <td>{selectedStore.quantity}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No related stores.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                {!selectedStore && <p>No related stores.</p>}
              </>
            )}
          </>
        );
      case "relatedInfo":
        // Logic for "Related Info" tab
        return (
          <div>
            {" "}
            {/* Use a div as a wrapper for the content */}
            {isEditMode ? (
              // Edit mode: Show form fields for editing supply details
              <>
                <FormGroup>
                  <Label for="supplierSelect">Supplier</Label>
                  <Select
                    id="supplierSelect"
                    name="supplier"
                    options={suppliers.map((supplier) => ({
                      value: supplier.id,
                      label: supplier.name, // Assuming your supplier objects have `id` and `name` properties
                    }))}
                    // Find the supplier object that matches the selectedSupplyDetail.supplier ID
                    value={
                      suppliers.find(
                        (supplier) =>
                          supplier.id === selectedSupplyDetail.supplier
                      )
                        ? {
                            value: selectedSupplyDetail.supplier,
                            label: suppliers.find(
                              (supplier) =>
                                supplier.id === selectedSupplyDetail.supplier
                            ).name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleEditInputChange({
                        target: {
                          name: "supplier",
                          // Ensure you're setting the supplier ID back to the selectedSupplyDetail state
                          value: selectedOption ? selectedOption.value : "",
                        },
                      })
                    }
                    placeholder="Select Supplier"
                    isClearable
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="internalCode">Internal Code</Label>
                  <Input
                    type="text"
                    name="internal_code"
                    id="internalCode"
                    value={selectedSupplyDetail.internal_code || ""}
                    onChange={handleEditInputChange}
                  />
                </FormGroup>
                {/* Add more editable fields as necessary */}
              </>
            ) : (
              // View mode: Display supply details in read-only format
              <>
                <p>
                  <strong>Supplier:</strong>{" "}
                  {selectedSupplyDetail.supplier_name}
                </p>
                <p>
                  <strong>Internal Code:</strong>{" "}
                  {selectedSupplyDetail.internal_code}
                </p>
                <p>
                  <strong>Description:</strong>{" "}
                  {selectedSupplyDetail.description}
                </p>
                <p>
                  <strong>Notes:</strong> {selectedSupplyDetail.notes}
                </p>
              </>
            )}
          </div>
        );

        <>
          {isEditMode ? (
            <div>
              {" "}
              {/* Wrap the entire section in a div or another suitable element */}
              <FormGroup>
                <Label for="dateCreated">Date Created</Label>
                <Input
                  type="date"
                  name="date_created"
                  id="dateCreated"
                  value={selectedSupplyDetail.date_created || ""}
                  onChange={handleEditInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dateUpdated">Date Updated</Label>
                <Input
                  type="date"
                  name="date_updated"
                  id="dateUpdated"
                  value={selectedSupplyDetail.date_updated || ""}
                  onChange={handleEditInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="updatedBy">Updated By</Label>
                <Input
                  type="text"
                  name="updated_by"
                  id="updatedBy"
                  value={selectedSupplyDetail.updated_by || ""}
                  onChange={handleEditInputChange}
                />
              </FormGroup>
            </div>
          ) : (
            <div>
              {" "}
              {/* Wrap read-only content as well for consistency */}
              {/* Display read-only information here */}
              <p>Date Created: {selectedSupplyDetail.date_created}</p>
              <p>Date Updated: {selectedSupplyDetail.date_updated}</p>
              <p>Updated By: {selectedSupplyDetail.updated_by}</p>
              {/* Add other details as needed */}
            </div>
          )}
        </>;

      default:
        return <div>No content available for this tab.</div>;
    }
  };

  const fetchStores = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      );
      setStores(response.data); // Assuming the response data is an array of stores
    } catch (error) {
      console.error("Error fetching stores:", error);
      //toast.error("Error fetching stores");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleStoreSelectChange = (selectedOption) => {
    setSelectedStore(selectedOption);
  };

  const fetchSuppliers = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`, {
      method: "GET",
      credentials: "include", // Include credentials for CORS requests
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setSuppliers(data))
      .catch((error) => console.error("Error fetching suppliers:", error));
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleAddToInventoryModal = () => {
    setIsAddToInventoryModalOpen(!isAddToInventoryModalOpen);
  };

  // useEffect hook to handle side effects when the Add to Inventory modal closes
  useEffect(() => {
    if (!isAddToInventoryModalOpen) {
      // Reset selected supplies when closing the Add to Inventory modal
      setSelectedSupplies([]);
    }
  }, [isAddToInventoryModalOpen]); // Depend on isAddToInventoryModalOpen state

  // useEffect hook to handle side effects when the Create Ingredient modal closes
  useEffect(() => {
    if (!isModalOpen) {
      setIsSuccess(false);
      setNewSupply({
        store: { id: "" },
        supply: { id: "" },
        // Reset to initial state or whatever you see fit
      });
    }
  }, [isModalOpen]); // Depend on isModalOpen state

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle store and supply specifically
    if (name === "store" || name === "supply") {
      setNewSupply({
        ...newSupply,
        [name]: { id: parseInt(value, 10) || null },
      });
    } else {
      // Update the newSupply state without trimming the value
      setNewSupply((prev) => ({ ...prev, [name]: value }));

      // Re-evaluate form validity
      const isFormValid = newSupply.name && newSupply.price;
      setIsFormValid(isFormValid);
    }
  };

  // Search Filter hook
  useEffect(() => {
    const filtered = supplies.filter((supply) => {
      const descriptionMatch = supply.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const internalCodeMatch =
        supply.date_created &&
        supply.date_created.toLowerCase().includes(searchText.toLowerCase());
      return descriptionMatch || internalCodeMatch;
    });
    setFilteredSupplies(filtered);
  }, [supplies, searchText]);

  // Create Supply
  const handleCreateSupply = async () => {
    if (!newSupply.name || !newSupply.price) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    const payload = {
      ...newSupply,
      supplier: newSupply.supplier ? newSupply.supplier.id : null,
      stores: selectedStore ? [selectedStore.value] : [],
    };
  
    try {
      const csrfToken = getCsrfToken();
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/create-supply`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 201) {
        toast.success("Supply successfully created!");
        toggleModal();
        fetchSupplies();
      }
    } catch (error) {
      console.error("Error creating supply:", error);
      toast.error(
        `Error creating supply: ${error.response?.data || "Unknown error"}`
      );
    }
  };

  // Edit Supply
  const handleSubmit = async () => {
    if (!isFormValid) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Prepare the payload
    let payload = {
      ...newSupply,
      store: selectedStore.map((store) => store.value), // Adjust according to your model relationships
      quantity: newSupply.quantity,
      // Include other supply details as needed
    };

    try {
      // Retrieve the CSRF token using the getCsrfToken function
      const csrfToken = getCsrfToken();

      // Include the CSRF token in the headers of your axios request
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/`,
        payload,
        {
          headers: {
            "X-CSRFToken": csrfToken, // Set the CSRF token in the request header
          },
          withCredentials: true, // Include credentials if necessary
        }
      );

      if (response.status === 201) {
        toast.success("Supply successfully created!");
        toggleModal(); // Assuming this function toggles the visibility of your modal
        fetchSupplies(); // Make sure this function is updated to fetch supplies list
      }
    } catch (error) {
      console.error("Error creating supply:", error);
      toast.error(`Error creating supply: ${error.message || "Unknown error"}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetch("/core/api/stores/")
      .then((response) => response.json())
      .then((json) => {
        setStores(json);
        setIsLoading(false);
      });
    fetchSupplys();
  }, []);

  const handleSupplyRemove = (supplyId) => {
    setSelectedSupplies((prevSupplys) =>
      prevSupplys.filter((id) => id !== supplyId)
    );
  };

  const fetchSupplies = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-list/`,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      );
      if (response.status === 200) {
        setSupplies(response.data); // Assuming setSupplies is your state setter function for supplies
        console.log("Supplies after fetch:", response.data);
      } else {
        console.error("Failed to fetch supplies:", response.status);
      }
    } catch (error) {
      console.error("Error fetching supplies:", error);
    }
  };

  useEffect(() => {
    fetchSupplies();
  }, []); // Empty dependency array means this runs once on mount

  const handleQuantityChange = (e, storeValue) => {
    const newQuantity = e.target.value;
    setSelectedStore((currentStore) => {
      if (currentStore && currentStore.value === storeValue) {
        return { ...currentStore, quantity: newQuantity };
      }
      return currentStore;
    });
  };

  const handlePriceChange = (event, supplyId) => {
    const newPrice = event.target.value;
    setSupplyDetails((prevDetails) => ({
      ...prevDetails,
      [supplyId]: { ...prevDetails[supplyId], price: newPrice },
    }));
  };

  const updateSupply = async (supplyId, updatedData) => {
    try {
      const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-list/${supplyId}/`,
        updatedData,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken, // Set the CSRF token in the request header
          },
        }
      );
      if (response.status === 200) {
        console.log("Supply updated successfully");
        // Close the modal here by setting the state controlling its visibility to false
        setIsDetailModalOpen(false); // Assuming this is the state variable controlling the modal visibility
        fetchSupplies(); // Refresh the list to show the updated data
        toast.success("Supply updated successfully!"); // Provide a success message to the user
      } else {
        // Handle non-200 responses, if necessary
        console.error("Failed to update supply:", response.status);
        toast.error(`Failed to update supply with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating supply:", error);
      toast.error(
        `Error updating supply: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const deleteSupply = async (supplyId) => {
    // Confirm before deleting
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (!isConfirmed) {
      return; // Exit the function if the user cancels
    }

    try {
      const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-list/${supplyId}/`,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            "X-CSRFToken": csrfToken, // Set the CSRF token in the request header
          },
        }
      );
      if (response.status === 204) {
        // HTTP 204 No Content indicates success
        console.log("Supply deleted successfully");
        fetchSupplies(); // Refresh the list to remove the deleted item
      } else {
        console.error("Failed to delete supply:", response.status);
      }
    } catch (error) {
      console.error("Error deleting supply:", error);
    }
  };

  const SelectedSupplysList = ({
    selectedSupplies,
    supplies = [],
    onRemove,
  }) => {
    // Default supplies to an empty array
    console.log("Supplies prop:", supplies);
    console.log("Selected Supplies prop:", selectedSupplies);
    return (
      <div className="selected-supplies">
        <h5>Selected Supplies:</h5>
        <div className="selected-supplies-grid">
          {selectedSupplies.map((supplyId) => {
            const supply = supplies.find((supply) => supply.id === supplyId);
            return (
              <div className="supply-card" key={supplyId}>
                <div className="supply-description">
                  {supply ? supply.name : "Unknown Supply"}
                </div>
                <button
                  className="remove-button"
                  onClick={() => onRemove(supplyId)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const fetchSupplys = (storeId = "") => {
    // Construct the URL based on the selected store ID
    const url = `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-list/${
      storeId ? "?store_id=" + storeId : ""
    }`;

    // Log the URL to the console for debugging
    console.log("Fetching URL:", url);

    // Fetching the supplies
    fetch(url, {
      method: "GET",
      credentials: "include", // Include credentials for CORS requests
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
      },
    })
      .then((response) => {
        // Check if the response is okay
        if (!response.ok) {
          // If not, throw an error
          throw new Error("Network response was not ok");
        }
        return response.json(); // Convert the response to JSON
      })
      .then((json) => {
        setSupplies(json);
        // Update totalPages based on the length of filtered supplies
        setTotalPages(
          Math.ceil(
            json.filter((supply) =>
              supply.name.toLowerCase().includes(searchText.toLowerCase())
            ).length / suppliesPerPage
          )
        );
      })
      .catch((error) => {
        // Log any errors to the console
        console.error("Fetching supplies failed:", error);
        toast.error("Fetching supplies failed!");
      });
  };

  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    setSelectedStore(selectedStoreId);
    console.log("Selected Store ID:", selectedStoreId);
    fetchSupplys(selectedStoreId);
  };

  const handleSupplySelect = (e) => {
    const supplyId = parseInt(e.target.value, 10);
    setSelectedSupplies((prev) =>
      e.target.checked
        ? [...prev, supplyId]
        : prev.filter((id) => id !== supplyId)
    );
  };

  const addSelectedSupplysToInventory = async () => {
    if (!selectedStore) {
      toast.error("Please select a store before adding supplies to inventory.");
      return;
    }

    const csrfToken = getCsrfToken();

    // Loop through each selected supply
    for (const supplyId of selectedSupplies) {
      const quantityInput = document.querySelector(
        `input[name='quantity-${supplyId}']`
      );

      const quantity = quantityInput ? quantityInput.value : "";

      if (!quantity || isNaN(quantity) || Number(quantity) <= 0) {
        toast.error(
          `Invalid quantity for supply ID ${supplyId} in store ${selectedStore.label}.`
        );
        continue; // Skip to the next iteration if there's an issue
      }

      const inventoryData = {
        store: selectedStore.value, // Use the store ID from the selected option
        supply: supplyId,
        quantity: Number(quantity),
        barcode: barcodes[supplyId] || "",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-inventory/`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
            body: JSON.stringify(inventoryData),
          }
        );

        if (!response.ok) {
          const errorDetail = await response.json();
          throw new Error(
            `Failed to add supply to inventory for store ${selectedStore.label}: ${errorDetail.error}`
          );
        }

        console.log(
          `Supply ID ${supplyId} successfully added to store ID ${selectedStore.value}:`,
          await response.json()
        );
        toast.success(
          `Supply successfully added to store ${selectedStore.label}!`
        );
      } catch (error) {
        console.error("Error adding supply to inventory:", error);
        toast.error(
          `Error adding supply ID ${supplyId} to inventory for store ${selectedStore.label}: ${error.message}`
        );
      }
    }

    // Reset selected supplies and close modal after processing
    setSelectedSupplies([]);
    setBarcodes({});
    toggleAddToInventoryModal(false);
  };

  const getSupplyDetails = (supplyId) => {
    const supply = findIngredientById(supplyId); // Using the existing function
    if (!supply) {
      console.error(`Supply not found for ID: ${supplyId}`);
      toast.error("Supply not found");
      return { name: "Unknown", description: "No description available" };
    }
    return supply;
  };

  const findIngredientById = (id) => {
    return supplies.find((supply) => supply.id === id);
  };

  const fetchStoreDetails = async (storeIds) => {
    return Promise.all(
      storeIds.map((id) =>
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/store/${id}/`, {
            withCredentials: true, // Include credentials if necessary
            headers: {
              "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
            },
          })
          .then((res) => res.data)
      )
    );
  };

  const handleDetailClick = async (supplyId) => {
    try {
      const { data: supplyDetails } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-detail/${supplyId}/`,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      );
      console.log("Fetched Supply Details:", supplyDetails);

      // Assuming you have a state or a way to fetch all stores with their details if necessary
      const allStores = await fetchAllStoresIfNeeded(); // Implement this based on your needs

      const updatedStores = supplyDetails.stores_supply.map((storeSupply) => {
        // Find the store details in the allStores array or define a fallback
        const storeDetail = allStores.find(
          (store) => store.id === storeSupply.store
        ) || { name: "Unknown Store" };
        return {
          value: storeSupply.store,
          label: storeDetail.name,
          quantity: storeSupply.quantity,
        };
      });

      setSelectedStore(updatedStores);
      setSelectedSupplyDetail(supplyDetails);
      setIsDetailModalOpen(true);
    } catch (error) {
      console.error("Error fetching supply details:", error);
      toast.error("Error fetching supply details");
    }
  };

  // Placeholder for fetching all store details, if not already available in component state
  async function fetchAllStoresIfNeeded() {
    if (!stores.length) {
      // Assuming 'stores' is your component state holding store details
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      );
      setStores(response.data); // Update your state accordingly
    }
    return stores; // Return the existing or freshly fetched stores
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);

    if (!isEditMode) {
      // If entering edit mode
      // Initialize `selectedStore` based on `selectedSupplyDetail`
      const initialSelectedStores = selectedSupplyDetail.stores.map(
        (storeId) => {
          const store = stores.find((store) => store.id === storeId);
          return { value: store.id, label: store.name };
        }
      );

      setSelectedStore(initialSelectedStores);
    }
  };

  // Detail Modal view/ Togle
  const toggleDetailModal = () => {
    setIsDetailModalOpen(!isDetailModalOpen);
    setIsEditMode(false); // Reset edit mode when toggling the modal
  };

  const renderModalContent = () => {
    if (!selectedSupplyDetail) {
      return <div>No details available for this supply.</div>;
    }

    if (isEditMode) {
      return (
        <>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              id="name"
              name="name"
              value={selectedSupplyDetail.name}
              onChange={handleEditInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="price">Price</Label>
            <Input
              id="price"
              name="price"
              type="number"
              value={selectedSupplyDetail.price}
              onChange={handleEditInputChange}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                id="is_active"
                name="is_active"
                checked={selectedSupplyDetail.is_active}
                onChange={handleEditInputChange}
              />{" "}
              Active
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              id="description"
              name="description"
              value={selectedSupplyDetail.description}
              onChange={handleEditInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="date_created">Date Created</Label>
            <Input
              type="text"
              id="date_created"
              name="date_created"
              value={formatDate(selectedSupplyDetail.date_created)}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="date_updated">Date Updated</Label>
            <Input
              type="text"
              id="date_updated"
              name="date_updated"
              value={formatDate(selectedSupplyDetail.date_updated)}
              readOnly
            />
          </FormGroup>
        </>
      );
    }

    return (
      <>
        <div>
          <h5>General Info</h5>
          <p>
            <strong>Name:</strong> {selectedSupplyDetail.name}
          </p>
          <p>
            <strong>Price:</strong> ${selectedSupplyDetail.price}
          </p>
          <p>
            <strong>Active:</strong>{" "}
            {selectedSupplyDetail.is_active ? "Yes" : "No"}
          </p>
          <p>
            <strong>Description:</strong> {selectedSupplyDetail.description}
          </p>
        </div>
        <div>
          <h5>Status & Dates</h5>
          <p>
            <strong>Date Created:</strong>{" "}
            {formatDate(selectedSupplyDetail.date_created)}
          </p>
          <p>
            <strong>Date Updated:</strong>{" "}
            {formatDate(selectedSupplyDetail.date_updated)}
          </p>
        </div>
      </>
    );
  };

  // Update Supply
  const handleUpdate = async () => {
    const csrfToken = getCsrfToken();
    const supplyId = selectedSupplyDetail.id; // Assuming this is how you access the current supply's ID

    // Update the `stores_supply` within `selectedSupplyDetail` based on `selectedStore`
    const updatedStoresSupply = selectedStore.map((store) => ({
      store: store.value,
      quantity: parseInt(store.quantity, 10) || 0, // Ensure quantity is an integer and defaults to 0 if undefined
      restock_date: null, // Assuming you want to keep or update restock_date here
    }));

    // Prepare the updated supply detail including the updated `stores_supply`
    const updatedSupplyDetail = {
      ...selectedSupplyDetail,
      stores_supply: updatedStoresSupply,
      stores: selectedStore.map((store) => store.value), // Assuming you're updating stores directly
      // You can remove or adjust fields as necessary for your backend here
    };

    console.log("Payload being sent to the server:", updatedSupplyDetail);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-detail/${supplyId}/`,
        updatedSupplyDetail, // Use the updated detail as payload
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Supply updated successfully!");
        setIsDetailModalOpen(false);
        fetchSupplies();
      } else {
        // Handle non-200 responses
        toast.error(`Update failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating supply:", error);
      toast.error(`Error updating supply: ${error.message || "Unknown error"}`);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedSupplyDetail((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Create a function to handle multi-select changes
  const handleSupplySelectChange = (selectedOptions) => {
    const selectedSupplyIds = selectedOptions.map((option) => option.value);
    setSelectedSupplies(selectedSupplyIds);
  };

  // Create an options array for react-select from your supplies
  const supplyOptions = supplies.map((supply) => ({
    value: supply.id,
    label: supply.name,
  }));

  // Delete Product
  const handleDelete = async (supplyId) => {
    // Confirm before deleting
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this supply?"
    );
    if (!isConfirmed) {
      return; // Exit the function if the user cancels
    }

    try {
      const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method

      // Perform the delete request
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/store-supply-inventory/${supplyId}/delete/`,
        {
          headers: {
            "X-CSRFToken": csrfToken, // Include CSRF token in request headers
          },
          withCredentials: true, // Include credentials if necessary
        }
      );

      // Check if the response status is 204 (No Content) indicating success
      if (response.status === 204) {
        toast.success("Supply deleted successfully!");
        fetchSupplies(); // Refresh the supplies list
      } else {
        console.error("Failed to delete supply:", response.status);
        toast.error(`Failed to delete supply with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error deleting supply:", error);
      toast.error("Error deleting supply");
    }
  };

  return (
    <>
      {/* Success Message */}
      <div className="mt-3">{/* Success Message */}</div>

      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* Search and Buttons in one row */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                {/* Buttons with Icons */}
                <Button
                  className="custom-button me-2"
                  onClick={toggleModal}
                  title="Create Supply"
                >
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {/* Assuming faPlus is imported */}
                </Button>
                <Button
                  className="custom-button me-2"
                  onClick={toggleAddToInventoryModal}
                  title="Add Supply to Store Inventory"
                >
                  <FontAwesomeIcon icon={faWarehouse} />{" "}
                  {/* Assuming faWarehouse is imported */}
                </Button>

                {/* Search Input */}
                <FormGroup
                  className="mb-0 flex-grow-1"
                  style={{ marginLeft: "10px" }}
                >
                  <Input
                    type="text"
                    name="searchText"
                    id="searchText"
                    placeholder="Search by product name or code"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </FormGroup>
              </div>
              {/* Selected Products List */}
              {selectedSupplies.length > 0 && (
                <SelectedSupplysList
                  selectedSupplies={selectedSupplies}
                  supplies={supplies}
                  onRemove={handleSupplyRemove}
                />
              )}
              {/* Supplies Table */}
              {isLoading ? (
                <LoadingSpinner />
              ) : filteredSupplies.length > 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Select</th>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Active</th>
                      <th scope="col">Date Created</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSupplies
                      .slice(
                        (currentPage - 1) * suppliesPerPage,
                        currentPage * suppliesPerPage
                      )
                      .map((supply) => (
                        <tr key={supply.id}>
                          <td>
                            <Input
                              type="checkbox"
                              value={supply.id}
                              onChange={handleSupplySelect}
                              checked={selectedSupplies.includes(supply.id)}
                            />
                          </td>
                          <td>{supply.name}</td>
                          <td>${supply.price}</td>
                          <td>{supply.quantity}</td>
                          <td>{supply.is_active ? "Yes" : "No"}</td>
                          <td>{formatDate(supply.date_created)}</td>
                          <td>
                            <Button
                              color="primary"
                              size="sm"
                              title="View Details"
                              onClick={() => handleDetailClick(supply.id)}
                            >
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => handleDelete(supply.id)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center p-5">
                  <h6>No supplies available</h6>
                </div>
              )}

              {/* Pagination Controls */}
              <div className="pagination">{/* Pagination Buttons */}</div>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal for Supply Details Read/View Only */}
      <Modal isOpen={isDetailModalOpen} toggle={toggleDetailModal}>
        <ModalHeader toggle={toggleDetailModal}>Supply Details</ModalHeader>
        <ModalBody>{renderModalContent()}</ModalBody>
        <ModalFooter>
          {isEditMode && (
            <Button color="primary" onClick={handleUpdate}>
              Save Changes
            </Button>
          )}

          <Button
            color="primary"
            onClick={() => setIsEditMode(!isEditMode)}
            className="float-right"
            style={{ marginLeft: "20px" }}
          >
            {isEditMode ? "Cancel" : "Edit"}
          </Button>

          <Button color="secondary" onClick={toggleDetailModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Supply Details</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="supplyName">Name</Label>
            <Input
              type="text"
              name="name"
              id="supplyName"
              placeholder="Enter supply name"
              onChange={handleInputChange}
              value={newSupply.name || ""}
            />
          </FormGroup>

          <FormGroup>
  <Label for="supplierSelect">Supplier</Label>
  <Select
    id="supplierSelect"
    name="supplier"
    options={suppliers.map((supplier) => ({
      value: supplier.id,
      label: supplier.name,
    }))}
    value={
      newSupply.supplier
        ? {
            value: newSupply.supplier.id,
            label: newSupply.supplier.name,
          }
        : null
    }
    onChange={(selectedOption) =>
      handleInputChange({
        target: {
          name: "supplier",
          value: selectedOption
            ? {
                id: selectedOption.value,
                name: selectedOption.label,
              }
            : null,
        },
      })
    }
    placeholder="Select Supplier"
    isClearable
  />
</FormGroup>

          <FormGroup>
            <Label for="supplyDescription">Description</Label>
            <Input
              type="textarea"
              name="description"
              id="supplyDescription"
              onChange={handleInputChange}
              value={newSupply.description || ""}
            />
          </FormGroup>

          <FormGroup>
            <Label for="supplyPrice">Price</Label>
            <Input
              type="number"
              name="price"
              id="supplyPrice"
              onChange={handleInputChange}
              value={newSupply.price || ""}
            />
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="is_active"
                id="isActive"
                onChange={handleInputChange}
                checked={newSupply.is_active || false}
              />{" "}
              Active
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              handleCreateSupply();
              toggleModal();
            }}
          >
            Save Supply
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isAddToInventoryModalOpen}
        toggle={toggleAddToInventoryModal}
      >
        <ModalHeader toggle={toggleAddToInventoryModal}>
          Add Supplies to Store Inventory
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="selectStore">Select Store</Label>
            <Select
              name="selectStore"
              id="selectStore"
              options={stores.map((store) => ({
                value: store.id,
                label: store.name,
              }))}
              value={selectedStore}
              onChange={handleStoreSelectChange}
              placeholder="Select a Store"
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </FormGroup>

          {selectedSupplies.length > 0 && (
            <Table bordered>
              <thead>
                <tr>
                  <th>Supply</th>
                  <th>Quantity</th>
                  <th>Barcode</th>
                </tr>
              </thead>
              <tbody>
                {selectedSupplies.map((supplyId) => {
                  const supply = findIngredientById(supplyId);
                  return (
                    <tr key={supplyId}>
                      <td>{supply ? supply.name : "Unknown Supply"}</td>
                      <td>
                        <Input
                          type="number"
                          name={`quantity-${supplyId}`}
                          placeholder="Quantity"
                          onChange={(e) => handleQuantityChange(e, supplyId)}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          name={`barcode-${supplyId}`}
                          placeholder="UPC/Serial Number"
                          onChange={(e) => handleBarcodeChange(e, supplyId)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {selectedSupplies.length === 0 && <p>No supplies selected.</p>}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addSelectedSupplysToInventory}>
            Add to Inventory
          </Button>
          <Button color="secondary" onClick={toggleAddToInventoryModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SupplyList;
