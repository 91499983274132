import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import "../../assets/css/upgradeModal.css";
import LoadingSpinner from "../../views/examples/LoadingSpinner";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const priceIds = {
  monthly: {
    growing: 'price_1PLPneIJk8kvQl2hZGFt35xf',
    standard: 'price_1PLPm2IJk8kvQl2h4zNGiUwH',
    pro: 'price_1P0vytIJk8kvQl2hVsGzDqCx'
  },
  yearly: {
    growing: 'price_1PLPplIJk8kvQl2hyMV3wvOD',
    standard: 'price_1PLPqqIJk8kvQl2hTNXUHcuZ',
    pro: 'price_1PLPsVIJk8kvQl2hEovzw2aR'
  }
};

const UpgradeModal = ({ isOpen, toggle, customerId }) => {
  const [isAnnual, setIsAnnual] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initiateStripeCheckout = async (priceId) => {
    setIsLoading(true);
    const stripe = await stripePromise;
    const csrfToken = await getCsrfToken();

    console.log(`Initiating checkout with priceId: ${priceId} and customerId: ${customerId}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-or-upgrade-checkout-session/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ priceId, customerId }),
      });

      if (response.ok) {
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });

        if (result.error) {
          console.error("Stripe Checkout error:", result.error.message);
        }
      } else {
        console.error("Failed to create checkout session. Please try again.");
      }
    } catch (error) {
      console.error("Error during Stripe checkout initiation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCsrfToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/get-csrf-token/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      return '';
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="upgrade-modal">
      <ModalHeader toggle={toggle}>Upgrade Plan</ModalHeader>
      <ModalBody>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <>
            <div className="text-center mb-4">
              <label className="switch">
                <input type="checkbox" onChange={() => setIsAnnual(!isAnnual)} />
                <span className="slider round"></span>
              </label>
              <span>Annual (Save 10%)</span>
            </div>
            <Row>
              <Col xs="12" sm="6" lg="4" className="mb-4">
                <Card className="custom-pricing-card">
                  <CardBody>
                    <CardTitle tag="h5">Growing Plan</CardTitle>
                    <div className="custom-price">
                      <span className="custom-currency">$</span>
                      <span className="custom-amount">{isAnnual ? "540" : "50"}</span>
                      <span className="custom-period">{isAnnual ? "/yr" : "/mo"}</span>
                    </div>
                    <CardText>
                      Best for small teams starting with food recall management.
                    </CardText>
                    <Button color="primary" onClick={() => initiateStripeCheckout(isAnnual ? priceIds.yearly.growing : priceIds.monthly.growing)}>
                      Select
                    </Button>
                    <p><a href="https://foodrecalltracker.com/#pricing" target="_blank">More information...</a></p>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="4" className="mb-4">
                <Card className="custom-pricing-card">
                  <CardBody>
                    <CardTitle tag="h5">Standard Plan</CardTitle>
                    <div className="custom-price">
                      <span className="custom-currency">$</span>
                      <span className="custom-amount">{isAnnual ? "1069" : "99"}</span>
                      <span className="custom-period">{isAnnual ? "/yr" : "/mo"}</span>
                    </div>
                    <CardText>
                      Ideal for medium-sized businesses needing advanced features.
                    </CardText>
                    <Button color="primary" onClick={() => initiateStripeCheckout(isAnnual ? priceIds.yearly.standard : priceIds.monthly.standard)}>
                      Select
                    </Button>
                    <p><a href="https://foodrecalltracker.com/#pricing" target="_blank">More information...</a></p>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" sm="6" lg="4" className="mb-4">
                <Card className="custom-pricing-card">
                  <CardBody>
                    <CardTitle tag="h5">Pro Plan</CardTitle>
                    <div className="custom-price">
                      <span className="custom-currency">$</span>
                      <span className="custom-amount">{isAnnual ? "1350" : "150"}</span>
                      <span className="custom-period">{isAnnual ? "/yr" : "/mo"}</span>
                    </div>
                    <CardText>
                      Perfect for large organizations with extensive needs.
                    </CardText>
                    <Button color="primary" onClick={() => initiateStripeCheckout(isAnnual ? priceIds.yearly.pro : priceIds.monthly.pro)}>
                      Select
                    </Button>
                    <p><a href="https://foodrecalltracker.com/#pricing" target="_blank">More information...</a></p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default UpgradeModal;
