import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import TenantIngredientModal from "./TenantIngredientModal";
import { Alert } from "reactstrap";
import { Table } from "reactstrap";
import "../../assets/css/inggred_inventroy.css";
import axios from "axios"; // Import Axios for making HTTP requests
import LoadingSpinner from "./LoadingSpinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import ReactSelect from "react-select";
import {
  faPlus,
  faSeedling,
  faStore,
  faUtensils,
  faEdit,
  faShoppingCart,
  faAppleAlt,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IngredientDetailModal from "./IngredientDetailModal";

const IngredientInventory = () => {
  const [ingredients, setIngredients] = useState([]);
  const [customPackagingTypes, setCustomPackagingTypes] = useState([]);
  const [isCustomPackagingModalOpen, setIsCustomPackagingModalOpen] =
    useState(false);
  const [newPackagingType, setNewPackagingType] = useState("");
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serialNumbers, setSerialNumbers] = useState({});
  const [isAddToInventoryModalOpen, setIsAddToInventoryModalOpen] =
    useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const ingredientsPerPage = 20;
  const handlePageChange = (newPage) => {
    if (newPage === 1) {
      setIngredients([]);
    }
    setCurrentPage(newPage);
  };
  const [ingredientSuppliers, setIngredientSuppliers] = useState({});
  const [selectedIngredientDetail, setSelectedIngredientDetail] = useState({});
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const navigate = useNavigate();
  const [selectedNutrients, setSelectedNutrients] = useState([]);
  const [nutrients, setNutrients] = useState([]);
  const [ingredientDetails, setIngredientDetails] = useState({});
  const [isTenantIngredientModalOpen, setIsTenantIngredientModalOpen] =
    useState(false);

  const [newIngredient, setNewIngredient] = useState({
    supplier: "", // Assuming this will be the supplier ID
    ingredient_description: "",
    nutrient_code: "",
    nutrient_value: "",
    nutrient_value_source: "",
    fdc_id: "",
    derivation_code: "",
    foundation_year_acquired: "",
    start_date: "",
    end_date: "",
    notes: "",
    is_recalled: false,
    recall_date: "",
    is_flagged: false,
    is_active: true,
    is_favorite: false,
  });

  // Open Modal To select Ingredetins from shared table to add to TenantIngredetinDetail table.
  const toggleTenantIngredientModal = () => {
    console.log("Toggling modal. Current state:", isTenantIngredientModalOpen);
    setIsTenantIngredientModalOpen(!isTenantIngredientModalOpen);
    console.log("New state:", !isTenantIngredientModalOpen);
  };

  const toggleCustomPackagingModal = () => {
    setIsCustomPackagingModalOpen(!isCustomPackagingModalOpen);
  };

  const handleAddCustomPackagingType = () => {
    if (newPackagingType.trim() !== "") {
      setCustomPackagingTypes([
        ...customPackagingTypes,
        newPackagingType.trim(),
      ]);
      setNewPackagingType("");
      toggleCustomPackagingModal();
    }
  };

  const handleDeleteCustomPackagingType = (index) => {
    setCustomPackagingTypes(customPackagingTypes.filter((_, i) => i !== index));
  };

  const redirectToTables = () => {
    navigate("/admin/tables"); // Use the correct path based on your routing setup
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);
  console.log("Current suppliers state:", suppliers); // Add this line

  useEffect(() => {
    fetchIngredients(selectedStore); // Pass selectedStore as an argument if needed
  }, [selectedStore]);

  const fetchSuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      console.log("Suppliers fetched:", response.data); // Log the fetched data
      setSuppliers(response.data); // Assuming the response is the array of suppliers
    } catch (error) {
      console.error("Error fetching suppliers:", error);
      toast.error("Failed to fetch suppliers");
    }
  };

  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // Funtion to generate a serial number
  const handleGenerateSerialNumber = (ingredientId) => {
    const newSerialNumber = generateSerialNumber();
    setSerialNumbers((prev) => ({ ...prev, [ingredientId]: newSerialNumber }));
  };

  const generateSerialNumber = () => {
    return `INGRED-${Math.random().toString(36).substr(2, 9).toUpperCase()}`;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleAddToInventoryModal = () => {
    setIsAddToInventoryModalOpen(!isAddToInventoryModalOpen);
  };

  // useEffect hook to handle side effects when the Add to Inventory modal closes
  useEffect(() => {
    if (!isAddToInventoryModalOpen) {
      // Reset selected ingredients when closing the Add to Inventory modal
      setSelectedIngredients([]);
    }
  }, [isAddToInventoryModalOpen]); // Depend on isAddToInventoryModalOpen state

  // useEffect hook to handle side effects when the Create Ingredient modal closes
  useEffect(() => {
    if (!isModalOpen) {
      // Reset success message and ingredient form when closing the Create Ingredient modal
      setIsSuccess(false);
      setNewIngredient({
        // Reset to initial state or whatever you see fit
      });
    }
  }, [isModalOpen]); // Depend on isModalOpen state

  const handleInputChange = (e) => {
    const { name, value } = e.target; // Get the name and value from the event
    setNewIngredient({ ...newIngredient, [name]: value }); // Update the state
    console.log("Updated newIngredient:", newIngredient); // Log the updated state
  };

  // Search Filter hook
  useEffect(() => {
    const filtered = ingredients.filter((ingredient) => {
      const descriptionMatch =
        ingredient.ingredient_description &&
        ingredient.ingredient_description
          .toLowerCase()
          .includes(searchText.toLowerCase());
      const internalCodeMatch =
        ingredient.internal_code &&
        ingredient.internal_code
          .toLowerCase()
          .includes(searchText.toLowerCase());
      return descriptionMatch || internalCodeMatch;
    });
    setFilteredIngredients(filtered);
  }, [ingredients, searchText]);



// Get ingredient name helper function
const getIngredientNameById = (id) => {
  const ingredient = ingredients.find((ingredient) => ingredient.id === id);
  return ingredient ? ingredient.ingredient_description : 'Unknown Ingredient';
};



  // Create new Custom Ingredient
  const handleSubmit = () => {
    const csrfToken = getCsrfToken();

    // Example of validating required fields
    if (!newIngredient.supplier || newIngredient.supplier.trim() === "") {
      toast.error("Supplier is required.");
      return;
    }
    if (
      !newIngredient.ingredient_description ||
      newIngredient.ingredient_description.trim() === ""
    ) {
      toast.error("Ingredient description is required.");
      return;
    }

    // If using the nutrient feature, ensure nutrients are added
    if (selectedNutrients.length === 0) {
      toast.error("At least one nutrient must be selected.");
      return;
    }

    // Validate nutrient details if required
    for (let nutrient of selectedNutrients) {
      if (!nutrient.amount || nutrient.amount <= 0) {
        toast.error(
          `Amount for ${nutrient.label} must be valid and greater than zero.`
        );
        return;
      }
      if (!nutrient.unit_name || nutrient.unit_name.trim() === "") {
        toast.error(`Unit for ${nutrient.label} is required.`);
        return;
      }
    }

    const detailedNutrientData = selectedNutrients.map((nutrient) => ({
      name: nutrient.label,
      unit_name: nutrient.unit_name,
      amount: nutrient.amount,
      fdc_id: nutrient.fdc_id  // Including fdc_id to manage nutrients effectively
    }));

    const ingredientData = {
      supplier: newIngredient.supplier,
      ingredient_description: newIngredient.ingredient_description,
      is_recalled: newIngredient.is_recalled,
      new_nutrients: detailedNutrientData,
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/food/api/ingredients/create/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(ingredientData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.id) {
          console.log("Ingredient created successfully:", data);
          setIsModalOpen(false);
          toast.success("Ingredient created successfully!");
          fetchIngredients(); // Re-fetch ingredients to refresh the list
          setNewIngredient({}); // Reset the form fields
          setSelectedNutrients([]); // Clear selected nutrients
        } else {
          throw new Error("Failed to create ingredient");
        }
      })
      .catch((error) => {
        console.error("Error creating ingredient:", error);
        toast.error(
          "Error creating ingredient: " + (error.message || "Unknown error")
        );
      });
};


  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`, {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(),
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setStores(json);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
        setIsLoading(false);
      });
  }, []); // This useEffect does not depend on currentPage

  useEffect(() => {
    fetchIngredients(currentPage, searchText);
}, [currentPage, searchText]);

  const handleIngredientRemove = (ingredientId) => {
    setSelectedIngredients((prevIngredients) =>
      prevIngredients.filter((id) => id !== ingredientId)
    );
  };

  useEffect(() => {
    fetchNutrients();
  }, []);

  const fetchNutrients = async () => {
    setIsLoading(true); // Set loading state to true at the beginning of the fetch operation
    try {
      const csrfToken = getCsrfToken(); // Ensure getCsrfToken function is correctly defined and accessible
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/custom-nutrient-name/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );

      // Directly accessing the 'results' array from the response
      const nutrientsData = response.data.results;

      // Mapping over the 'results' array to create the desired structure for your state
      // Including the 'fdc_id' to manage nutrient data effectively
      const formattedNutrients = nutrientsData.map((nutrient, index) => ({
        value: `nutrient-${index}`, // Creating a unique identifier using the index
        label: nutrient.name, // Using the 'name' property from each nutrient object
        fdc_id: nutrient.fdc_id // Storing fdc_id to manage nutrients
      }));

      setNutrients(formattedNutrients); // Update the state with the formatted nutrients
    } catch (error) {
      console.error("Error fetching nutrients:", error);
    } finally {
      setIsLoading(false); // Reset loading state regardless of request success or failure
    }
};


  const unitOptions = [
    { label: "G", value: "G" },
    { label: "MG", value: "MG" },
    { label: "UG", value: "UG" },
    { label: "KCAL", value: "KCAL" },
    { label: "IU", value: "IU" },
    { label: "UMOL_TE", value: "UMOL_TE" },
    { label: "MCG_RE", value: "MCG_RE" },
    { label: "PH", value: "PH" },
    { label: "SP_GR", value: "SP_GR" },
    { label: "kJ", value: "kJ" },
    { label: "MG_ATE", value: "MG_ATE" },
    { label: "MG_GAE", value: "MG_GAE" },
  ];

  const handleNutrientsChange = (selectedOptions) => {
    const updatedSelectedNutrients = selectedOptions.map((option) => ({
      value: option.value, // Assuming this is the nutrient ID
      label: option.label, // Make sure this exists in your option object
      unit_name: option.unit_name || "", // Default to empty string if undefined
      amount: option.amount || 0, // Default to 0 if undefined
    }));
    setSelectedNutrients(updatedSelectedNutrients);
  };

  // Convert your nutrients to the format expected by ReactSelect
  const nutrientOptions = nutrients.map((nutrient) => ({
    value: nutrient.id,
    label: nutrient.name,
  }));

  const SelectedIngredientsList = ({ selectedIngredients, onRemove }) => {
    return (
      <div className="selected-ingredients">
        <h5>Selected Ingredients:</h5>
        <div className="selected-ingredients-grid">
          {selectedIngredients.map((ingredientId) => {
            const ingredient = ingredients.find(
              (ing) => ing.id === ingredientId
            );
            return (
              <div className="ingredient-card" key={ingredientId}>
                <div className="ingredient-description">
                  {ingredient
                    ? ingredient.ingredient_description
                    : "Unknown Ingredient"}
                </div>
                <button
                  className="remove-button"
                  onClick={() => onRemove(ingredientId)}
                >
                  <i className="fas fa-trash-alt"></i> Remove
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const fetchIngredients = async (page = 1, searchText = "") => {
    console.log("Starting to fetch ingredients...");
    setIsLoading(true);

    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/food/api/tenant-ingredients/`;
        
        // Check if page is a valid number
        if (!isNaN(page) && page > 0) {
            url += `?page=${page}`;
        }
        
        if (searchText.trim() !== "") {
            url += `${url.includes('?') ? '&' : '?'}search=${encodeURIComponent(searchText)}`;
        }
        console.log("Fetching URL:", url);

        const response = await fetch(url, {
            credentials: "include",
            headers: {
                Accept: "application/json",
                "X-CSRFToken": getCsrfToken(),
            },
        });

        if (!response.ok) throw new Error("Network response was not ok");
        const json = await response.json();

        setIngredients(json.results); // Replace the ingredients state with the new results
        setTotalPages(Math.ceil(json.count / ingredientsPerPage)); // Update to use dynamic page size if API supports it
        console.log("Ingredients fetched successfully.");
    } catch (error) {
        console.error("Fetching ingredients failed:", error);
    } finally {
        setIsLoading(false);
    }
};


  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    setSelectedStore(selectedStoreId);
    fetchIngredients(selectedStoreId);
  };

  const handleIngredientSelect = (e) => {
    const ingredientId = parseInt(e.target.value, 10);
    setSelectedIngredients((prev) =>
      e.target.checked
        ? [...prev, ingredientId]
        : prev.filter((id) => id !== ingredientId)
    );
  };

  const addSelectedIngredientsToInventory = () => {
    const csrfToken = getCsrfToken();

    // Validate all fields for each ingredient before making the POST request
    let allFieldsValid = true;
    const ingredientDetails = selectedIngredients.map((ingredientId) => {
      console.log(`Handling ingredient with ID ${ingredientId}`);

      const quantityInput = document.querySelector(
        `input[name='quantity-${ingredientId}']`
      );
      const priceInput = document.querySelector(
        `input[name='price-${ingredientId}']`
      );
      const weightInput = document.querySelector(
        `input[name='weight-${ingredientId}']`
      );
      const weightUnitsSelect = document.querySelector(
        `select[name='weight_units-${ingredientId}']`
      );
      const packagingTypeInput = document.querySelector(
        `select[name='packaging_type-${ingredientId}']`
      );
      const serialNumberInput = document.querySelector(
        `input[name='serialNumber-${ingredientId}']`
      );
      const expirationDateInput = document.querySelector(
        `input[name='expirationDate-${ingredientId}']`
      );

      console.log("Input elements:", {
        quantityInput,
        priceInput,
        weightInput,
        weightUnitsSelect,
        packagingTypeInput,
        serialNumberInput,
        expirationDateInput,
      });

      // Check if input elements exist and have valid values
      if (
        !quantityInput ||
        !priceInput ||
        !weightInput ||
        !weightUnitsSelect ||
        !packagingTypeInput ||
        !serialNumberInput ||
        !expirationDateInput ||
        !quantityInput.value ||
        !priceInput.value ||
        !weightInput.value ||
        !weightUnitsSelect.value ||
        !packagingTypeInput.value ||
        !serialNumberInput.value ||
        !expirationDateInput.value
      ) {
        allFieldsValid = false;
      const ingredientName = getIngredientNameById(ingredientId);
      console.error(`Please fill all the fields for the ingredient: ${ingredientName} (ID: ${ingredientId}).`);
      toast.error(`Please fill all the fields for the ingredient: ${ingredientName} (ID: ${ingredientId}).`);
      return null; // Skip this ingredient
    }

      return {
        store: selectedStore,
        ingredient: ingredientId,
        quantity: quantityInput.value,
        weight: parseFloat(weightInput.value),
        weight_units: weightUnitsSelect.value,
        packaging_type: packagingTypeInput.value,
        price: parseFloat(priceInput.value),
        supplier: ingredientSuppliers[ingredientId],
        serial_number: serialNumberInput.value,
        expiration_date: expirationDateInput.value,
      };
    });

    // Filter out null values from the ingredientDetails array
    const validIngredientDetails = ingredientDetails.filter(
      (detail) => detail !== null
    );

    if (!allFieldsValid || validIngredientDetails.length === 0) {
      return;
    }

    console.log("Valid ingredient details:", validIngredientDetails);

    // If all fields are valid, make the POST request
    const ingredientRequests = validIngredientDetails.map((inventoryData) =>
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/ingredient-inventory/create/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(inventoryData),
        }
      ).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
    );

    Promise.all(ingredientRequests)
      .then((responses) => {
        console.log("Ingredients added to inventory successfully:", responses);
        toast.success("Ingredients added to inventory successfully!");
        setSelectedIngredients([]);
        toggleAddToInventoryModal();
      })
      .catch((error) => {
        console.error("Error adding ingredients to inventory:", error);
        toast.error("Error adding ingredients to inventory.");
      });
  };

  const findIngredientById = (id) => {
    const ingredient = ingredients.find((ingredient) => ingredient.id === id);
    return ingredient || {}; // Return an empty object if the ingredient is not found
  };

  const handleDetailClick = (ingredientId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/tenant-ingredient-detail/${ingredientId}/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": getCsrfToken(),
          },
        }
      )
      .then((response) => {
        // Assuming that the response includes nutrients and each nutrient includes the fdc_id
        const detailedNutrients = response.data.nutrients.map(nutrient => ({
          ...nutrient,
          fdc_id: nutrient.fdc_id // ensuring fdc_id is highlighted in the mapping
        }));
        
        setSelectedIngredientDetail({
          ...response.data,
          nutrients: detailedNutrients // updating the state with detailed nutrients including fdc_id
        });
        
        setIsDetailModalOpen(true);
      })
      .catch((error) => {
        console.error("Error fetching ingredient details:", error);
      });
};


  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const displayIsRecalled = (isRecalled) => {
    if (isRecalled) {
      return <span style={{ fontWeight: "bold", color: "red" }}>Yes</span>;
    } else {
      return "No";
    }
  };

  // Delete Ingredient
  const handleDelete = (ingredientId) => {
    // Show confirmation dialog
    if (window.confirm("Are you sure you want to delete this ingredient?")) {
      const csrfToken = getCsrfToken();

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/food/api/ingredients/delete/${ingredientId}/`,
        {
          method: "DELETE",
          credentials: "include", // Include credentials in the request
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            // Attempt to parse the error message from the response
            const errorData = await response.json(); // Parse JSON body
            const errorMessage = errorData.error || "Unknown error occurred"; // Fallback to a generic error message

            // Handle different response statuses
            if (response.status === 403) {
              // Permission denied error, use the server's error message
              console.error(
                "Permission denied for deleting ingredient:",
                errorMessage
              );
              toast.error(errorMessage); // Displaying the server-provided error message
            } else if (
              ("Permission denied for deleting ingredient:",
              response.status === 404)
            ) {
              console.error(
                "Ingredient not found or not custom:",
                errorMessage
              );
              toast.error("Ingredient not found or not custom.");
            } else {
              // For other types of errors, log and toast the error message
              console.error("Error deleting ingredient:", errorMessage);
              toast.error("Error deleting ingredient:", errorMessage);
            }
            return; // Exit the function early since an error occurred
          }
          // Remove the ingredient from the state on successful deletion
          setIngredients((prevIngredients) =>
            prevIngredients.filter(
              (ingredient) => ingredient.id !== ingredientId
            )
          );
          toast.warn("Ingredient deleted successfully!");
        })
        .catch((error) => {
          // Handle any errors that occurred during fetch or during response parsing
          console.error("Error processing deletion:", error);
          toast.error("Error processing deletion: " + error.toString());
        });
    } else {
      // User cancelled the deletion
      console.log("Ingredient deletion cancelled.");
    }
  };

  const Row = ({ index, style, data }) => {
    const { filteredIngredients, handleIngredientSelect, selectedIngredients } =
      data;
    const ingredient = filteredIngredients[index];
    const isChecked = selectedIngredients?.includes(ingredient.id); // Use optional chaining for safety

    // Ensure these styles match with the ones in your .table-flush thead th styles
    const cellStyle = {
      padding: "10px",
      display: "flex",
      alignItems: "left",
      justifyContent: "center",
    };

    return (
      <div
        style={{
          ...style,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #eee",
        }}
      >
        <div style={{ ...cellStyle, width: "10%" }}>
          <Input
            type="checkbox"
            value={ingredient.id}
            onChange={(e) => handleIngredientSelect(e)}
            checked={isChecked}
          />
        </div>
        <div
          className="ingredient-description"
          style={{
            ...cellStyle,
            width: "40%",
            textAlign: "center",
            fontSize: "13px",
            marginRight: "250px",
          }} // Add textAlign style here
        >
          {ingredient.ingredient_description}
        </div>
        <div style={{ ...cellStyle, width: "40%", fontSize: "13px" }}>
          {displayIsRecalled(ingredient.is_custom)}
        </div>

        <div style={{ ...cellStyle, width: "20%" }}>
          <Button
            color="primary"
            title="Edit"
            size="sm"
            onClick={() => handleDetailClick(ingredient.id)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={() => handleDelete(ingredient.id)}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Search and action buttons */}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ padding: "10px" }}
        >
          <div>
            <Button
              className="custom-button me-2"
              onClick={toggleModal}
              title="Create Ingredient"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              className="custom-button me-2"
              onClick={redirectToTables}
              title="Create Recipe"
            >
              <FontAwesomeIcon icon={faUtensils} />
            </Button>
            <Button
              className="custom-button"
              onClick={toggleAddToInventoryModal}
              title="Add Ingredients to Store"
            >
              <FontAwesomeIcon icon={faStore} />
            </Button>
            <Button
              className="custom-button me-2"
              onClick={toggleTenantIngredientModal}
              title="Add Ingredients from App Database"
            >
              <FontAwesomeIcon icon={faAppleAlt} />
            </Button>
          </div>
          <FormGroup className="mb-0" style={{ flex: 1, maxWidth: "80%" }}>
            <Input
              type="text"
              name="searchText"
              id="searchText"
              placeholder="Search by ingredient name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </FormGroup>
        </div>

        {/* Display selected ingredients if any */}
        {selectedIngredients.length > 0 && (
          <SelectedIngredientsList
            selectedIngredients={selectedIngredients}
            ingredients={ingredients} // Assuming you're passing ingredients to find details
            onRemove={handleIngredientRemove}
          />
        )}

        {/* Table for displaying ingredients */}
        <Card className="shadow border-0">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Select</th>
                <th>Description</th>
                <th>Is Custom</th>
                <th>Date Added</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
      {isLoading ? (
        <tr>
          <td colSpan="6" className="text-center">
            <LoadingSpinner />  {/* Spinner displayed when loading */}
          </td>
        </tr>
      ) : filteredIngredients.length > 0 ? (
        filteredIngredients.map((ingredient, index) => {
          const ingredientIndex = (currentPage - 1) * 30 + index + 1;
          return (
            <tr key={ingredient.id}>
              <td>{ingredientIndex}</td>
              <td>
                <Input
                  type="checkbox"
                  value={ingredient.id}
                  checked={selectedIngredients.includes(ingredient.id)}
                  onChange={handleIngredientSelect}
                />
              </td>
              <td>{ingredient.ingredient_description}</td>
              <td>{ingredient.is_custom ? "Yes" : "No"}</td>
              <td>{formatDate(ingredient.date_added)}</td>
              <td>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => handleDetailClick(ingredient.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDelete(ingredient.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan="6" className="text-center">
            No ingredients found.
          </td>
        </tr>
      )}
    </tbody>
          </Table>
          {/* Pagination */}
          <div className="pagination-wrapper">
            <Pagination>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <PaginationItem key={page} active={page === currentPage}>
                    <PaginationLink
                      onClick={() => handlePageChange(page)}
                      className={page === currentPage ? "active-page" : ""}
                      style={
                        page === currentPage
                          ? { backgroundColor: "#5e72e4", color: "#fff" }
                          : {}
                      }
                    >
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                )
              )}
            </Pagination>
          </div>
        </Card>
      </Container>

      <IngredientDetailModal
  isDetailModalOpen={isDetailModalOpen}
  setIsDetailModalOpen={setIsDetailModalOpen}
  selectedIngredientDetail={selectedIngredientDetail}
  refreshIngredients={fetchIngredients} // Pass the fetchIngredients function as the refreshIngredients prop
/>

      {/* Modal for Creating New Ingredient */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create New Ingredient</ModalHeader>
        <ModalBody>
          <FormGroup>
            {/* Assuming store and supplier are dropdowns populated with options */}
            {/* Supplier */}
            <Label for="supplier">Supplier</Label>
            <Input
              type="select"
              name="supplier"
              id="supplier"
              value={newIngredient.supplier}
              onChange={handleInputChange}
            >
              <option value="">Select Supplier</option>
              {suppliers.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </option>
              ))}
            </Input>

            <Label for="ingredientDescription">Ingredient name</Label>
            <Input
              type="textarea"
              name="ingredient_description"
              id="ingredientDescription"
              onChange={handleInputChange}
            />
            {/* ... Nutrient Values... */}
            {/* Existing fields */}
            {/* Multi-select Dropdown for Nutrients */}
            <Label for="nutrientsSelect">Select Nutrients</Label>
            <ReactSelect
              id="nutrientsSelect"
              isMulti
              options={nutrients}
              classNamePrefix="select"
              onChange={handleNutrientsChange}
              value={selectedNutrients}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
            {/* Display Selected Nutrients Dynamically */}
            {selectedNutrients.length > 0 && (
              <div>
                <h5>Selected Nutrients:</h5>
                {selectedNutrients.map((nutrient, index) => (
                  <FormGroup key={nutrient.id}>
                    <Label>{nutrient.label}</Label>
                    <ReactSelect
                      options={unitOptions}
                      onChange={(e) => {
                        const updatedNutrients = [...selectedNutrients];
                        updatedNutrients[index] = {
                          ...updatedNutrients[index],
                          unit_name: e.value,
                        };
                        setSelectedNutrients(updatedNutrients);
                      }}
                      value={unitOptions.find(
                        (option) => option.value === nutrient.unit_name
                      )}
                      placeholder="Select Unit"
                    />
                    <Input
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => {
                        const updatedNutrients = [...selectedNutrients];
                        updatedNutrients[index].amount = e.target.value;
                        setSelectedNutrients(updatedNutrients);
                      }}
                      value={nutrient.amount || ""}
                    />
                  </FormGroup>
                ))}
              </div>
            )}
            {/* Other fields */}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Create Ingredient
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal for Adding Ingredients to Inventory */}
      <Modal
        isOpen={isAddToInventoryModalOpen}
        toggle={toggleAddToInventoryModal}
      >
        <ModalHeader toggle={toggleAddToInventoryModal}>
          Add Selected Ingredients to Store
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="selectStore">Select Store</Label>
            <Input
              type="select"
              name="selectStore"
              id="selectStore"
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option value="">Select Store</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          {selectedIngredients.map((ingredientId) => {
            const ingredient = findIngredientById(ingredientId);

            return (
              <div key={ingredientId}>
                <h3>{ingredient.ingredient_description}</h3>
                <FormGroup>
                  <Label for={`supplier-${ingredientId}`}>Supplier</Label>
                  <Input
                    type="select"
                    name={`supplier-${ingredientId}`}
                    id={`supplier-${ingredientId}`}
                    value={ingredientSuppliers[ingredientId] || ""}
                    onChange={(e) => {
                      setIngredientSuppliers((prevSuppliers) => ({
                        ...prevSuppliers,
                        [ingredientId]: e.target.value,
                      }));
                    }}
                  >
                    <option value="">Select Supplier</option>
                    {suppliers.map((supplier) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <Label>Quantity</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ml-2"
                      id={`quantityTooltip-${ingredientId}`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`quantityTooltip-${ingredientId}`}
                    >
                      This is the quantity of packages of the ingredient beign
                      added to the store (ex. 100 units of 200 lb bags).
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name={`quantity-${ingredientId}`}
                    placeholder="Quantity"
                  />
                </FormGroup>
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <Label>Weight</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ml-2"
                      id={`weightTooltip-${ingredientId}`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`weightTooltip-${ingredientId}`}
                    >
                      This is the weight of the 1 package of the ingredient (ex.
                      1 bag of 200 lb).
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name={`weight-${ingredientId}`}
                    placeholder="Weight"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Weight Units</Label>
                  <Input type="select" name={`weight_units-${ingredientId}`}>
                    <option value="gram">Gram</option>
                    <option value="kg">Kilogram</option>
                    <option value="lb">Pound (lb)</option>
                    <option value="oz">Ounce (oz)</option>
                    {/* Add more units as necessary */}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <Label>Packaging Type</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ml-2"
                      id={`packagingTypeTooltip-${ingredientId}`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`packagingTypeTooltip-${ingredientId}`}
                    >
                      Select the type of packaging for the ingredient.
                    </UncontrolledTooltip>
                    <Button
                      color="primary"
                      className="ml-2"
                      title="Add New Packaging Type"
                      size="sm"
                      style={{ width: "30px" }}
                      onClick={toggleCustomPackagingModal}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                  <Input type="select" name={`packaging_type-${ingredientId}`}>
                    <option value="">Select Packaging Type</option>
                    <option value="box">Box</option>
                    <option value="bag">Bag</option>
                    <option value="jar">Jar</option>
                    <option value="bottle">Bottle</option>
                    <option value="can">Can</option>
                    <option value="pouch">Pouch</option>
                    <option value="carton">Carton</option>
                    <option value="case">Case</option>
                    <option value="drum">Drum</option>
                    <option value="pallet">Pallet</option>
                    {customPackagingTypes.map((type, index) => (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    ))}
                  </Input>
                  <div className="mt-2">
                    {customPackagingTypes.map((type, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-1"
                      >
                        <span>{type}</span>
                        <Button
                          color="danger"
                          className="ml-2"
                          size="sm"
                          title="Delete Custom Packaging Type"
                          style={{ width: "30px" }}
                          onClick={() => handleDeleteCustomPackagingType(index)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    ))}
                  </div>
                </FormGroup>

                <Modal
                  isOpen={isCustomPackagingModalOpen}
                  toggle={toggleCustomPackagingModal}
                >
                  <ModalHeader toggle={toggleCustomPackagingModal}>
                    Add Custom Packaging Type
                  </ModalHeader>
                  <ModalBody>
                    <Input
                      type="text"
                      value={newPackagingType}
                      onChange={(e) => setNewPackagingType(e.target.value)}
                      placeholder="Enter custom packaging type"
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={handleAddCustomPackagingType}
                    >
                      Add
                    </Button>
                    <Button
                      color="secondary"
                      onClick={toggleCustomPackagingModal}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
                <FormGroup>
                  <div className="d-flex align-items-center">
                    <Label>Price</Label>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ml-2"
                      id={`priceTooltip-${ingredientId}`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`priceTooltip-${ingredientId}`}
                    >
                      Enter the price of the ingredient per package (for just
                      one package).
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="number"
                    name={`price-${ingredientId}`}
                    placeholder="Price"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>UPC/Serial Number</Label>
                  <Input
                    type="text"
                    name={`serialNumber-${ingredientId}`}
                    id={`serialNumber-${ingredientId}`}
                    placeholder="Serial Number"
                    value={serialNumbers[ingredientId] || ""}
                    onChange={(e) =>
                      setSerialNumbers((prev) => ({
                        ...prev,
                        [ingredientId]: e.target.value,
                      }))
                    }
                  />
                  <Button
                    className="ml-2"
                    color="primary"
                    size="sm"
                    style={{ width: "70px", marginTop: "10px" }}
                    onClick={() => handleGenerateSerialNumber(ingredientId)}
                  >
                    Generate
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Label>Expiration Date</Label>
                  <Input type="date" name={`expirationDate-${ingredientId}`} />
                </FormGroup>
                {selectedIngredients.indexOf(ingredientId) !==
                  selectedIngredients.length - 1 && <hr />}
              </div>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={addSelectedIngredientsToInventory}>
            Add Ingredients to Store Inventory
          </Button>
          <Button color="secondary" onClick={toggleAddToInventoryModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* TenantIngredientModal component */}
      <TenantIngredientModal
        isOpen={isTenantIngredientModalOpen}
        toggle={toggleTenantIngredientModal}
        onIngredientsSubmitted={fetchIngredients} // Pass the function as a prop
      />

      {/* Success Message */}
      <div className="mt-3"></div>
    </>
  );
};

export default IngredientInventory;
