import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Row,
  Table,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Header from "../../components/Headers/Header.js";
import CreateStoreModal from "./CreateStoreModal";
import CreateEmployeeModal from "./CreateEmployeeModal"; // Ensure this path is correct
import CreateSupplierModal from "./CreateSupplierModal"; // Ensure this path is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/settings.css";

import {
  faStore,
  faUser,
  faTruckMoving,
  faUsers,
  faEdit,
  faTrash,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";

const SettingsComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false); // State for permission modal
  const [stores, setStores] = useState([]);
  const [storeToEdit, setStoreToEdit] = useState(null);
  const [isCreateSupplierModalOpen, setIsCreateSupplierModalOpen] =
    useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierToEdit, setSupplierToEdit] = useState(null);
  const [isCreateEmployeeModalOpen, setIsCreateEmployeeModalOpen] =
    useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeeToEdit, setEmployeeToEdit] = useState(null);

  useEffect(() => {
    fetchStores();
    fetchSuppliers();
  }, []);

  const fetchStores = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      setStores(response.data); // Assuming the response data is an array of stores
    } catch (error) {
      console.error("Error fetching stores:", error);
      toast.error("Error fetching stores");
    }
  };

  const toggleCreateStoreModal = () => {
    setIsCreateStoreModalOpen(!isCreateStoreModalOpen);
    if (isCreateStoreModalOpen) {
      // This is intended to clear the storeToEdit when closing the modal
      setStoreToEdit(null);
    }
  };

  const handleEditStore = (storeId) => {
    const store = stores.find((s) => s.id === storeId);
    setStoreToEdit(store); // Set the selected store for editing
    setIsCreateStoreModalOpen(true); // Open the modal
  };

  const handleDeleteStore = async (storeId) => {
    // Confirmation dialog before proceeding
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this store?"
    );

    if (isConfirmed) {
      try {
        // Call your API to delete the store
        console.log("Deleting store with id:", storeId);
        await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/store/${storeId}/delete/`,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCsrfToken(),
            },
          }
        );
        toast.warning("Store deleted successfully");
        fetchStores(); // Refetch stores to update the list
      } catch (error) {
        console.error("Error deleting store:", error);
        toast.error("Failed to delete store");
      }
    } else {
      // User clicked 'Cancel', do nothing
      console.log("Store deletion canceled");
    }
  };

  // Implement the search filter logic
  const filteredStores = stores.filter(
    (store) =>
      searchTerm.length === 0 ||
      (store.name &&
        store.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (store.address &&
        store.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (store.phone &&
        store.phone.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const filteredSuppliers = suppliers.filter(
    (supplier) =>
      searchTerm.length === 0 ||
      (supplier.name &&
        supplier.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (supplier.address &&
        supplier.address.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (supplier.phone &&
        supplier.phone.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const filteredEmployees = employees.filter(
    (employee) =>
      searchTerm.length === 0 ||
      (employee.first_name &&
        employee.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (employee.last_name &&
        employee.last_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (employee.email &&
        employee.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (employee.phone &&
        employee.phone.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Suppliers CRUD

  const fetchSuppliers = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`, {
      method: "GET",
      credentials: "include", // Include credentials for CORS requests
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => setSuppliers(data))
      .catch((error) => console.error("Error fetching suppliers:", error));
  };

  const toggleCreateSupplierModal = () => {
    setIsCreateSupplierModalOpen(!isCreateSupplierModalOpen);
    if (isCreateSupplierModalOpen) {
      // This clears the selected supplier for editing when closing the modal
      setSupplierToEdit(null);
    }
  };

  const handleEditSupplier = (supplierId) => {
    const supplier = suppliers.find((s) => s.id === supplierId);
    setSupplierToEdit(supplier); // Set the selected supplier for editing
    setIsCreateSupplierModalOpen(true); // Open the modal for editing
  };

  const handleDeleteSupplier = async (supplierId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this supplier?"
    );
    if (isConfirmed) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/${supplierId}/delete/`,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCsrfToken(),
            },
          }
        );
        toast.warning("Supplier deleted successfully");
        const updatedSuppliers = suppliers.filter(
          (supplier) => supplier.id !== supplierId
        );
        setSuppliers(updatedSuppliers); // Update the state to remove the deleted supplier
      } catch (error) {
        console.error("Error deleting supplier:", error);
        toast.error("Failed to delete supplier");
      }
    } else {
      console.log("Supplier deletion canceled");
    }
  };

  const createSupplier = async (supplierDetails) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/`,
        supplierDetails,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      if (response.data) {
        toast.success("Supplier created successfully");
        fetchSuppliers(); // Assuming you have a function to fetch all suppliers
      }
    } catch (error) {
      console.error("Error creating supplier:", error);
      toast.error("Failed to create supplier");
    }
  };

  const updateSupplier = async (supplierId, supplierDetails) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/suppliers/${supplierId}/`,
        supplierDetails,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCsrfToken(),
          },
        }
      );
      if (response.data) {
        toast.success("Supplier updated successfully");
        fetchSuppliers(); // Refresh the list of suppliers
      }
    } catch (error) {
      console.error("Error updating supplier:", error);
      toast.error(`Error updating supplier: ${error.message}`);
    }
  };

  // Function to fetch employees (users)
  const fetchEmployees = async () => {
    try {
      const csrfToken = getCsrfToken(); // Get CSRF token
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/users/api/tenant-users/`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken, // Include CSRF token in request headers
          },
        }
      );
      setEmployees(response.data); // Assuming the response data is the list of users
      console.log("Users fetched successfully:", response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  useEffect(() => {
    fetchEmployees(); // Fetch employees (users) when component mounts
  }, []); // Empty dependency array means this effect runs once on mount

  const toggleCreateEmployeeModal = () => {
    setIsCreateEmployeeModalOpen(!isCreateEmployeeModalOpen);
    if (isCreateEmployeeModalOpen) {
      setEmployeeToEdit(null); // Clear selected employee when closing modal
    }
  };

  const handleEditEmployee = (employeeId) => {
    const employee = employees.find((e) => e.id === employeeId);
    setEmployeeToEdit(employee); // Assuming you have a state variable to hold the employee to be edited
    setIsCreateEmployeeModalOpen(true); // Open the modal for editing
  };

  // Get CSRF Token
  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const togglePermissionModal = () => {
    setIsPermissionModalOpen(!isPermissionModalOpen);
  };

  const requestNotificationPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        // Handle further actions after permission is granted
      } else {
        console.log("Notification permission denied.");
      }
      togglePermissionModal(); // Close the modal after handling the permission request
    });
  };

  const renderPermissionModal = () => (
    <Modal isOpen={isPermissionModalOpen} toggle={togglePermissionModal}>
      <ModalHeader toggle={togglePermissionModal}>
        Enable Notifications
      </ModalHeader>
      <ModalBody>
        <p>
          To stay updated with real-time alerts, please allow push
          notifications.
        </p>
        <Button color="primary" onClick={requestNotificationPermission}>
          Allow Notifications
        </Button>
      </ModalBody>
    </Modal>
  );

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <div
                className="d-flex align-items-center mb-3"
                style={{ marginTop: "20px", marginLeft: "10px" }}
              >
                {/* Adjusted button to trigger modal */}
                <Button
                  className="custom-button me-2"
                  title="Create Store"
                  style={{ marginLeft: "10px" }}
                  onClick={toggleCreateStoreModal}
                >
                  <FontAwesomeIcon icon={faStore} />
                </Button>

                <Button
                  className="custom-button me-2"
                  title="Settings"
                  style={{ marginLeft: "10px" }}
                  onClick={togglePermissionModal}
                >
                  <FontAwesomeIcon icon={faCog} />
                </Button>

                <Button
                  className="custom-button me-2"
                  title="Create Supplier"
                  onClick={toggleCreateSupplierModal} // This toggles the visibility of the supplier modal
                >
                  <FontAwesomeIcon icon={faTruckMoving} />
                </Button>

                <Button
                  className="custom-button me-2"
                  title="Create Employee"
                  onClick={toggleCreateEmployeeModal} // This toggles the visibility of the employee modal
                >
                  <FontAwesomeIcon icon={faUsers} />
                </Button>
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="ms-auto"
                />
                {searchTerm && (
                  <button
                    className="clear-search-btn"
                    onClick={() => setSearchTerm("")}
                  >
                    X
                  </button>
                )}
              </div>
              {renderPermissionModal()}{" "}
              {/* Render the modal for requesting notification permission */}
              {/* Title for the Stores Table */}
              <Card className="shadow mb-5">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="section-header">
                    <tr>
                      <th colSpan="5">Stores</th>
                    </tr>
                  </thead>
                  <thead className="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStores.map((store) => (
                      <tr key={store.id}>
                        <td>{store.id}</td>
                        <td>{store.name}</td>
                        <td>{store.street_address}, {store.city},  {store.zip_code}, {store.state}
                        </td>
                        <td>{store.phone}</td>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleEditStore(store.id)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>{" "}
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDeleteStore(store.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
              {/* Table to display suppliers */}
              <Card className="shadow mb-5">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="section-header">
                    <tr>
                      <th colSpan="5">Suppliers</th>
                    </tr>
                  </thead>
                  <thead className="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSuppliers.map((supplier) => (
                      <tr key={supplier.id}>
                        <td>{supplier.id}</td>
                        <td>{supplier.name}</td>
                        <td>{supplier.street_address}, {supplier.city},  {supplier.zip_code}, {supplier.state}
                        </td>
                        <td>{supplier.phone}</td>
                        <td>{supplier.email}</td>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleEditSupplier(supplier.id)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>{" "}
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDeleteSupplier(supplier.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
              {/* Table to display employees */}
              <Card className="shadow mb-5">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="section-header">
                    <tr>
                      <th colSpan="5">Employees</th>
                    </tr>
                  </thead>
                  <thead className="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>{" "}
                      {/* Assuming employees have email instead of address */}
                      <th>Phone</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEmployees.map((employee) => (
                      <tr key={employee.id}>
                        <td>{employee.id}</td>
                        <td>
                          {employee.first_name} {employee.last_name}
                        </td>
                        <td>{employee.email}</td>{" "}
                        {/* Adjust according to your data model */}
                        <td>{employee.phone}</td>
                        <td>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleEditEmployee(employee.id)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => {
                              /* function to handle employee delete */
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </Row>
      </Container>
      <CreateStoreModal
        isOpen={isCreateStoreModalOpen}
        toggle={toggleCreateStoreModal}
        refreshStores={fetchStores}
        storeToEdit={storeToEdit}
      />
      <CreateSupplierModal
        isOpen={isCreateSupplierModalOpen}
        toggle={toggleCreateSupplierModal}
        refreshSuppliers={fetchSuppliers} // Make sure this function is implemented
        supplierToEdit={supplierToEdit}
      />
      <CreateEmployeeModal
        isOpen={isCreateEmployeeModalOpen}
        toggle={toggleCreateEmployeeModal}
        refreshUsers={fetchEmployees} // Make sure fetchEmployees is correctly defined and passed
        userToEdit={employeeToEdit}
      />
    </>
  );
};

export default SettingsComponent;
