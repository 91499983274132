import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; 
import defaultUserIcon from '../../assets/img/theme/user-icon-person-icon-client-symbol-login-head-sign-icon-design-vector.jpg'; // Adjust path as necessary

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

const AdminNavbar = (props) => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({}); // State to hold user profile data

  const handleLogout = async () => {
    try {
      const csrfResponse = await fetch("/api/get-csrf-token/", {
        method: "GET",
        credentials: "include", // Include cookies
      });

      const csrfData = await csrfResponse.json();
      const csrfToken = csrfData.csrfToken;

      const response = await fetch("/api/logout/", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
        },
      });

      if (response.ok) {
        navigate("/auth/login");
      } else {
        // Handle logout errorsi
      }
    } catch (error) {
      // Handle fetch or other errors
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
        try {
            const response = await axios.get("/users/api/your-profile-endpoint");
            console.log('User profile data received:', response.data);
            if (response.data && response.data.picture) {
                console.log('Picture URL:', response.data.picture);
            }
            setUserProfile(response.data);
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };

    fetchUserProfile();
}, []);

  

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          ></Link>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                  <img alt="Profile" src={userProfile.picture || defaultUserIcon} />

                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {userProfile.first_name} {userProfile.last_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">
                    {userProfile.first_name} {userProfile.last_name}
                  </h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;