// RequestNotificationPermission.js
import React from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify'; // Ensure toast is imported

const RequestNotificationPermission = ({ onClose }) => {
  const requestPermission = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          toast.success("Notification permission granted.");
        } else {
          console.log('Notification permission denied.');
          toast.error("Notification permission denied.");
        }
        onClose(); // Close the modal regardless of the user's choice
      });
    } else {
      console.log('This browser does not support notifications.');
      toast.error("This browser does not support notifications."); // Show error toast
    }
  };

  return (
    
    <Button color="primary" onClick={requestPermission} className="mt-3">
      Enable Notifications
    </Button>
  );
};

export default RequestNotificationPermission;
