import React, { useEffect } from 'react';
import '../../assets/css/LoadingSpinner.css'; // Import your CSS file

const LoadingSpinner = () => {
  // This effect will run once when the component mounts
  useEffect(() => {
    console.log('LoadingSpinner is now being displayed.');
    
    // Optional: If you want to log when the spinner is unmounted, return a cleanup function
    return () => console.log('LoadingSpinner has been removed.');
  }, []); // The empty array ensures this effect only runs on mount and unmount

  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
