import React from "react";
import { Modal, ModalBody, ModalHeader, Button, Card, CardBody, CardTitle, CardText } from "reactstrap";
import PropTypes from "prop-types";

const HelpResources = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Help Resources</ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <CardTitle tag="h5">Video Tutorials</CardTitle>
            <CardText>Watch our video tutorials to get started quickly.</CardText>
            <Button color="primary" href="https://youtube.com/@foodrecalltracker?si=srrd6_lCJMHllrKt" target="_blank">Watch Now</Button>
          </CardBody>
        </Card>
        <Card className="mt-3">
          <CardBody>
            <CardTitle tag="h5">Help Resources</CardTitle>
            <CardText>Explore our help resources for more detailed guides and FAQs.</CardText>
            <Button color="primary" href="https://blog.foodrecalltracker.com/help-resources/">Learn More</Button>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

HelpResources.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default HelpResources;
