import React, { useEffect } from 'react';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebaseConfig'; // Adjust the path as needed

const FirebaseMessagingSetup = () => {
  useEffect(() => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // Update this call to include your VAPID key
        getToken(messaging, { vapidKey: 'BEoKMR39IxUtfczMjK6fhlwo2K0SQXXRV57nq-XfVTH4WnAxMSHC7ZYKFDNbtxxNE9JIJGocU9Lqmg2ayec8h2E' })
          .then(currentToken => {
            if (currentToken) {
              localStorage.setItem('pendingFCMToken', currentToken);
              sendTokenToServer(currentToken);
              // Optionally, send the token to your server here
            } else {
              console.log('No FCM Token retrieved.');
            }
          })
          .catch(err => {
            console.error('Error retrieving FCM token:', err);
          });
      }
    });

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Display the notification for foreground messages
      if (Notification.permission === 'granted') {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon || '/Users/alejandroarancibia/Documents/BiteBlueprintBoiler/locos_por_el_remate/static/landing_page_foodrecall/img/foodrecall_logo.jpeg', // optional: path to an icon
        });
      }
    });
  }, []);

  return null; // This component doesn't render anything
};


function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  


  const sendTokenToServer = (token) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/core/api/save-fcm-token/`;
    const csrfToken = getCsrfToken(); // Ensure this is correctly fetching or retrieving the CSRF token
    const authToken = localStorage.getItem('authToken'); // Retrieve the auth token if necessary
  

    return fetch(url, { // Ensure to return this fetch promise
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
        'Authorization': `Bearer ${authToken}`, // Include this if you're using JWT authentication and it's necessary for the endpoint
      },
      body: JSON.stringify({ token: token }),
      credentials: 'include', // Ensure credentials are sent with the request for CSRF
    })
    .then(response => {
      console.log('Response status:', response.status);
      return response.json(); // Parse JSON response
    })
    .then(data => {
      console.log('Token sent to server:', data);
      return data; // Ensure to return data for further chaining if necessary
    })
    .catch((error) => {
      console.error('Error sending token to server:', error);
    });
  };
  



export default FirebaseMessagingSetup;
export { FirebaseMessagingSetup, sendTokenToServer };

