import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardHeader } from "reactstrap";
import Header from "../components/Headers/Header.js";
import OnboardingPopup from "./examples/OnboardingPopup"; // Adjust the import path
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
import Select from "react-select"; // Make sure to import Select from 'react-select'
import axios from "axios";
import "../assets/css/dashboard.css";


const Index = (props) => {
  const [inventoryLevels, setInventoryLevels] = useState([]);
  const [productionVolume, setProductionVolume] = useState([]);
  const [stores, setStores] = useState([]); // State to hold stores
  const [selectedStore, setSelectedStore] = useState(null); // State to hold the selected store
  const [productLevels, setProductLevels] = useState([]);
  const [supplyLevels, setSupplyLevels] = useState([]);
  const [recallData, setRecallData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("year");
  const [recallTypes, setRecallTypes] = useState([]);
  const [recallCounts, setRecallCounts] = useState([]);

  // Define an array of colors for the bars.
  const barColors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(199, 199, 199, 0.2)",
    "rgba(83, 102, 255, 0.2)",
    "rgba(40, 159, 64, 0.2)",
    "rgba(210, 99, 132, 0.2)",
    "rgba(255, 99, 255, 0.2)",
    "rgba(54, 235, 162, 0.2)",
    "rgba(255, 86, 206, 0.2)",
    "rgba(192, 75, 192, 0.2)",
    "rgba(102, 153, 255, 0.2)",
    "rgba(159, 255, 64, 0.2)",
    "rgba(199, 199, 99, 0.2)",
    "rgba(102, 83, 255, 0.2)",
    "rgba(159, 40, 64, 0.2)",
    "rgba(99, 210, 132, 0.2)"
  
  ];

  const borderColors = barColors.map((color) => color.replace(/0\.2\)$/, "1)")); // Replace the alpha value for border colors

  const intervalOptions = [
    { value: "year", label: "Last Year" },
    { value: "6months", label: "Last 6 Months" },
    { value: "month", label: "Last Month" },
    { value: "week", label: "Last Week" },
  ];



  function getCsrfToken() {
    const name = "csrftoken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // Fetch stores when component mounts
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/stores/`, {
        withCredentials: true, // Include credentials if necessary
        headers: {
          'X-CSRFToken': getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
        },
      })
      .then((response) => {
        // Assuming your API returns an array of stores
        const storeOptions = response.data.map((store) => ({
          value: store.id,
          label: store.name,
        }));
        setStores(storeOptions);
  
        // Set the first store as the selected store if the list is not empty
        if (storeOptions.length > 0) {
          const firstStore = storeOptions[0];
          setSelectedStore(firstStore);
        }
      })
      .catch((error) => {
        console.error("Error fetching stores:", error);
      });
  }, []);
  

  // Fetch production volume when component mounts (independent of store selection)
  useEffect(() => {
    const csrfToken = getCsrfToken(); // Retrieve CSRF token using your defined method
  
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/core/api/product-production-volume/`, {
        withCredentials: true, // Include credentials if necessary, such as cookies for session authentication
        headers: {
          'X-CSRFToken': csrfToken, // Set the CSRF token in the request header
        },
      })
      .then((response) => {
        setProductionVolume(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product production volume:", error);
      });
  }, []);
  

  // This effect should run every time the selectedStore changes
  const fetchInventoryLevels = async (storeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/core/api/inventory-levels/?store_id=${storeId}`,
        {
          withCredentials: true, // Include credentials if necessary
          headers: {
            'X-CSRFToken': getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
          },
        }
      );
      setInventoryLevels(response.data);
    } catch (error) {
      console.error("Error fetching inventory levels:", error);
    }
  };
  

  // Chart Data for Inventory Levels

  const inventoryLevelsData =
    inventoryLevels.length > 0
      ? {
          labels: inventoryLevels.map((item) =>
            item.ingredient_description.substring(0, 15)
          ),
          datasets: [
            {
              label: "Ingredient Inventory",
              data: inventoryLevels.map((item) => item.current_level),
              backgroundColor: barColors.slice(0, inventoryLevels.length), // Use a slice of the colors array
              borderColor: borderColors.slice(0, inventoryLevels.length), // Use a slice of the border colors array
              borderWidth: 1,
            },
          ],
        }
      : {};

  // Chart Data for Production Volume (independent from store selection)
  const productionVolumeData =
    productionVolume.length > 0
      ? {
          labels: productionVolume.map((item) => item.name),
          datasets: [
            {
              label: "Production Volume",
              data: productionVolume.map((item) => item.volume),
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)", // color for the first item
                "rgba(54, 162, 235, 0.2)", // color for the second item
                "rgba(255, 206, 86, 0.2)", // color for the third item
                "rgba(75, 192, 192, 0.2)", // color for the fourth item
                "rgba(153, 102, 255, 0.2)", // color for the fifth item
                "rgba(255, 159, 64, 0.2)", // color for the sixth item
                "rgba(199, 199, 199, 0.2)", // color for the seventh item
                "rgba(83, 102, 255, 0.2)", // color for the eighth item
                "rgba(40, 159, 64, 0.2)", // color for the ninth item
                "rgba(210, 99, 132, 0.2)", // color for the tenth item
                "rgba(255, 99, 255, 0.2)", // color for the eleventh item
                "rgba(54, 235, 162, 0.2)", // color for the twelfth item
                "rgba(255, 86, 206, 0.2)", // color for the thirteenth item
                "rgba(192, 75, 192, 0.2)", // color for the fourteenth item
                "rgba(102, 153, 255, 0.2)", // color for the fifteenth item
                "rgba(159, 255, 64, 0.2)", // color for the sixteenth item
                "rgba(199, 199, 99, 0.2)", // color for the seventeenth item
                "rgba(102, 83, 255, 0.2)", // color for the eighteenth item
                "rgba(159, 40, 64, 0.2)", // color for the nineteenth item
                "rgba(99, 210, 132, 0.2)" // color for the twentieth item
                ],
                borderColor: [
                "rgba(255, 99, 132, 1)", // border color for the first item
                "rgba(54, 162, 235, 1)", // border color for the second item
                "rgba(255, 206, 86, 1)", // border color for the third item
                "rgba(75, 192, 192, 1)", // border color for the fourth item
                "rgba(153, 102, 255, 1)", // border color for the fifth item
                "rgba(255, 159, 64, 1)", // border color for the sixth item
                "rgba(199, 199, 199, 1)", // border color for the seventh item
                "rgba(83, 102, 255, 1)", // border color for the eighth item
                "rgba(40, 159, 64, 1)", // border color for the ninth item
                "rgba(210, 99, 132, 1)", // border color for the tenth item
                "rgba(255, 99, 255, 1)", // border color for the eleventh item
                "rgba(54, 235, 162, 1)", // border color for the twelfth item
                "rgba(255, 86, 206, 1)", // border color for the thirteenth item
                "rgba(192, 75, 192, 1)", // border color for the fourteenth item
                "rgba(102, 153, 255, 1)", // border color for the fifteenth item
                "rgba(159, 255, 64, 1)", // border color for the sixteenth item
                "rgba(199, 199, 99, 1)", // border color for the seventeenth item
                "rgba(102, 83, 255, 1)", // border color for the eighteenth item
                "rgba(159, 40, 64, 1)", // border color for the nineteenth item
                "rgba(99, 210, 132, 1)" // border color for the twentieth item
                ],
              borderWidth: 1,
            },
          ],
        }
      : {};

      const fetchProductLevels = async (storeId) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/core/api/product-levels/?store_id=${storeId}`,
            {
              withCredentials: true, // Include credentials if necessary
              headers: {
                'X-CSRFToken': getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
              },
            }
          );
          // Assuming response.data is an array of {product_name: string, current_level: number}
          setProductLevels(response.data);
        } catch (error) {
          console.error("Error fetching product levels:", error);
        }
      };
      

      const fetchSupplyLevels = async (storeId) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/core/api/supply-levels/?store_id=${storeId}`,
            {
              withCredentials: true, // Include credentials if necessary
              headers: {
                'X-CSRFToken': getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
              },
            }
          );
          // Assuming response.data is an array of {supply_name: string, current_level: number}
          setSupplyLevels(response.data);
        } catch (error) {
          console.error("Error fetching supply levels:", error);
        }
      };
      

  useEffect(() => {
    if (selectedStore) {
      fetchInventoryLevels(selectedStore.value);
      fetchProductLevels(selectedStore.value);
      fetchSupplyLevels(selectedStore.value);
    }
  }, [selectedStore]);

  const productLevelsData =
    productLevels.length > 0
      ? {
          labels: productLevels.map((item) =>
            item.product_name.substring(0, 15)
          ),
          datasets: [
            {
              label: "Product Inventory",
              data: productLevels.map((item) => item.current_level),
              backgroundColor: barColors.slice(0, productLevels.length),
              borderColor: borderColors.slice(0, productLevels.length),
              borderWidth: 1,
            },
          ],
        }
      : {};

  const supplyLevelsData =
    supplyLevels.length > 0
      ? {
          labels: supplyLevels.map((item) => item.supply_name.substring(0, 15)),
          datasets: [
            {
              label: "Supply Inventory",
              data: supplyLevels.map((item) => item.current_level),
              backgroundColor: barColors.slice(0, supplyLevels.length),
              borderColor: borderColors.slice(0, supplyLevels.length),
              borderWidth: 1,
            },
          ],
        }
      : {};

      useEffect(() => {
        const fetchRecallData = async () => {
          try {
            // Fetching recall counts over time
            const recallCountsResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/core/api/recall-data/?interval=${selectedInterval}`,
              {
                withCredentials: true, // Include credentials if necessary
                headers: {
                  'X-CSRFToken': getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
                },
              }
            );
            setRecallData(recallCountsResponse.data);
      
            // Fetching recall counts by type
            const recallTypesResponse = await axios.get(
              `${process.env.REACT_APP_API_BASE_URL}/core/api/recall-types/`,
              {
                withCredentials: true, // Include credentials if necessary
                headers: {
                  'X-CSRFToken': getCsrfToken(), // Assuming you have a function getCsrfToken to get the CSRF token
                },
              }
            );
            const recallTypeCounts = recallTypesResponse.data.reduce(
              (acc, recall) => {
                acc[recall.recall_type] = (acc[recall.recall_type] || 0) + 1;
                return acc;
              },
              {}
            );
      
            setRecallTypes(Object.keys(recallTypeCounts));
            setRecallCounts(Object.values(recallTypeCounts));
          } catch (error) {
            console.error("Error fetching recall data:", error);
          }
        };
      
        fetchRecallData();
      }, [selectedInterval]);
      

  const recallChartData = {
    labels: recallData.map((data) => data.month.substring(0, 7)), // Assuming 'month' is in 'YYYY-MM-DD' format
    datasets: [
      {
        label: "Recalls",
        data: recallData.map((data) => data.total),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const recallTypeChartData = {
    labels: recallTypes,
    datasets: [
      {
        label: "Recall Types",
        data: recallCounts,
        backgroundColor: [
          // Define your colors here
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          // Add more as needed
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <>
      <OnboardingPopup />
      <Header />
      <Container className="dashboard-container mt--7" fluid>
        {/* First Row for Inventory and Production Volume Charts */}
        <Row className="dashboard-row">
          <Col lg="6">
            <Card className="dashboard-card">
              {/* Store selection Dropdown */}
              <div className="select-container">
                <Select
                  options={stores}
                  onChange={(selectedOption) => setSelectedStore(selectedOption)}
                  value={selectedStore}
                  placeholder="Select a Store"
                />
              </div>
              <CardHeader className="dashboard-card-header">20 Lowest Ingredient Stock Levels</CardHeader>
              <div className="chart-container">
                <Bar
                  data={inventoryLevelsData}
                  key={selectedStore ? selectedStore.value : "all-stores"}
                />
              </div>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="dashboard-card">
              <div className="select-container"></div> {/* Placeholder for dropdown, to maintain equal height */}
              <CardHeader className="dashboard-card-header">20 Lowest Product Stock Levels</CardHeader>
              <div className="chart-container">
                <Bar data={productLevelsData} />
              </div>
            </Card>
          </Col>
        </Row>
        {/* Second Row for Supply Inventory Levels and Production Volume */}
        <Row className="dashboard-row">
          <Col lg="6">
            <Card className="dashboard-card">
              <div className="select-container"></div> {/* Maintains equal height */}
              <CardHeader className="dashboard-card-header">Supply Inventory Levels</CardHeader>
              <div className="chart-container">
                <Bar data={supplyLevelsData} />
              </div>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="dashboard-card">
              <div className="select-container"></div> {/* Maintains equal height */}
              <CardHeader className="dashboard-card-header">Product Production Volume</CardHeader>
              <div className="chart-container">
                <Pie data={productionVolumeData} />
              </div>
            </Card>
          </Col>
        </Row>
        {/* Third Row for Recall Data Line Chart and Recall Types Distribution */}
        <Row className="dashboard-row">
          <Col lg="6">
            <Card className="dashboard-card">
              <div className="select-container">
                {/* Interval selection dropdown */}
                <Select
                  className="mb-4"
                  options={intervalOptions}
                  onChange={(option) => setSelectedInterval(option.value)}
                  value={intervalOptions.find((option) => option.value === selectedInterval)}
                  placeholder="Select Interval"
                />
              </div>
              <CardHeader className="dashboard-card-header">Recalls Over Time</CardHeader>
              <div className="chart-container">
                <Line data={recallChartData} />
              </div>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="dashboard-card">
              <div className="select-container"></div> {/* Maintains equal height */}
              <CardHeader className="dashboard-card-header">Recall Types Distribution</CardHeader>
              <div className="chart-container">
                <Doughnut data={recallTypeChartData} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
  
  
};

export default Index;
